import React from 'react';
import types from 'prop-types';

const Header = ({ description, title }) => (
  <>
    <div className="associated-components-title">{title}</div>
    <div className="associated-components-description">{description}</div>
  </>
);

Header.propTypes = {
  description: types.string.isRequired,
  title: types.string.isRequired,
};

export default Header;
