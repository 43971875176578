import * as React from 'react';

import Confirm from 'components/confirm';
import PreviousPageLink from './previous_page_link';
import notify, { Color } from 'utils/notify';
import Input from 'components/input';
import TextArea from 'components/textarea';
import Tooltip from 'components/tooltip';
import { SsoConfig } from './types';
import './styles.scss';

const MODAL_PREFIX = 'modal-confirmation-';
const DELETE_MODAL = 'delete-provider';
const DELETE_MODAL_TRIGGER = MODAL_PREFIX + DELETE_MODAL;
const SAVE_MODAL = 'save-provider';
const SAVE_MODAL_TRIGGER = MODAL_PREFIX + SAVE_MODAL;

export interface SsoConfigProps {
  ssoConfig: SsoConfig;
  prevPagePath: string;
}

const SsoConfigForm = ({ ssoConfig, prevPagePath }: SsoConfigProps) => {
  /** STATE VARIABLES */
  const [ssoTarget, setSsoTarget] = React.useState(
    ssoConfig.provider.sso_target_url_text || '',
  );
  const [certificate, setCertificate] = React.useState(
    ssoConfig.provider.certificate || '',
  );
  const [emailNodeName, setEmailNodeName] = React.useState(
    ssoConfig.provider.email_node_name,
  );
  const [ssoEnabled, setSsoEnabled] = React.useState(
    ssoConfig.owner_has_restriction,
  );
  const [ssoPath, setSsoPath] = React.useState(ssoConfig.path);

  /** UTILITY FUNCTIONS */
  const updateSso = async () => {
    const method = ssoEnabled ? 'PUT' : 'POST';

    try {
      const data = await $.ajax({
        url: ssoPath,
        method: method,
        dataType: 'json',
        data: {
          sso_identity_provider: formatProvider(),
          use_presenter: true,
        },
      });
      setSsoPath(data.path);
      notify('SSO settings updated!', {
        color: Color.SUCCESS,
      });
      setSsoEnabled(true);
    } catch (e) {
      const error_message = JSON.parse(e.responseText).error_messages[0];
      const error = error_message
        ? `Settings failed to update: ${error_message.toLowerCase()}, please try again.`
        : 'Settings failed to update, please try again.';

      notify(error, {
        color: Color.ERROR,
      });
    }
  };

  const deleteSso = async () => {
    try {
      await $.ajax({
        type: 'DELETE',
        url: ssoPath,
        dataType: 'JSON',
        data: {
          use_presenter: true,
        },
      });
      notify('SSO settings deleted.', {
        color: Color.SUCCESS,
      });
      setSsoTarget('');
      setCertificate('');
      setSsoEnabled(false);
      setSsoPath('/sso-identity-providers');
    } catch (e) {
      notify('Sorry, it looks like deleting your SSO configuration failed', {
        color: Color.ERROR,
      });
    }
  };

  const triggerModal = (modalId: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    $('#' + modalId).modal(); // this is needed because our modals are a JQuery plugin (sad face)
  };

  const formatProvider = () => {
    let newSsoProvider = Object.assign({}, ssoConfig.provider);
    newSsoProvider.sso_target_url_text = ssoTarget;
    newSsoProvider.certificate = certificate;
    newSsoProvider.email_node_name = emailNodeName;

    // only include the fields that are modifiable (otherwise our request will get rejected)
    Object.keys(newSsoProvider).forEach((key) => {
      if (!ssoConfig.sso_modifiable_fields.includes(key))
        delete newSsoProvider[key];
    });

    return newSsoProvider;
  };

  /** RENDER FUNCTIONS */
  const renderEmailNodeField = () => {
    if (ssoConfig.audience_specific_page) {
      return (
        <Input
          type="text"
          inputClass="full-width email-node-name"
          label="Page access user email node"
          name="email_node_name"
          placeholder="Page access user email node"
          value={emailNodeName}
          onChange={(e) => setEmailNodeName(e.target.value)}
          helpBlockText="This is the attribute we'll use to set an email address for your page access users. We suggest you use 'email'"
        />
      );
    }
  };

  const renderFormActions = () => {
    if (ssoEnabled) {
      return (
        <>
          <a
            onClick={(e) => triggerModal(DELETE_MODAL_TRIGGER, e)}
            className="btn-delete-config"
          >
            Delete
          </a>
          <button
            onClick={(e) => triggerModal(SAVE_MODAL_TRIGGER, e)}
            className="cpt-button style-primary btn-save-config"
          >
            Update
          </button>
        </>
      );
    } else {
      return (
        <button
          onClick={(e) => triggerModal(SAVE_MODAL_TRIGGER, e)}
          className="cpt-button style-primary btn-save-config"
        >
          Save
        </button>
      );
    }
  };

  /** FINAL RESULT */
  return (
    <div className="page-authentication">
      <PreviousPageLink path={prevPagePath} />

      <h3>Using SAML</h3>
      <div className="heading">
        <div className="title">Single sign-on</div>
        <div className="description">
          <p>
            Use this form to require page viewers to authenticate with SAML
            before viewing your status page.
          </p>

          <p>
            If you want to set up authentication for team members to log in to
            the admin portal via SAML, use{' '}
            <a href={ssoConfig.organization_team_member_path}>this form</a>{' '}
            instead.
          </p>
        </div>
      </div>

      <div className="configure-step step-1">
        <div className="title">
          Step 1: Add the Statuspage application in your identity provider
        </div>
        <div className="content">
          <div className="field-container">
            <div className="label">
              ACS URL / Consumer URL
              <Tooltip
                title="This is the URL is where your Identity Provider will send SAML assertions, and will be required in your Identity Provider's admin when setting up a Statuspage app."
                className="tooltip-base"
                theme="tooltipster-shadow"
              >
                ?
              </Tooltip>
            </div>
            {ssoConfig.sso_saml_consume_url}
          </div>
          <div className="field-container">
            <div className="label">
              Entity / Audience URL
              <Tooltip
                title="This is the value you'll need to enter in the 'Entity ID' field in your Identity Provider's configuration. This may also be referred to as 'Audience Restriction' or 'Audience URI'."
                className="tooltip-base"
                theme="tooltipster-shadow"
              >
                ?
              </Tooltip>
            </div>
            {ssoConfig.entity_id}
          </div>
        </div>
        <div className="subsection subtext">
          You can also view the entire{' '}
          <a href={ssoConfig.metadata_path} target="_blank">
            service provider metadata XML file for this{' '}
            {ssoConfig.provider.owner_type}
          </a>
        </div>
      </div>

      <div className="configure-step step-2">
        <div className="title">
          Step 2: Paste your SSO target URL and certificate returned by your
          identity provider
        </div>
        <div className="content">
          <Input
            type="text"
            placeholder="SSO target URL"
            value={ssoTarget}
            onChange={(e) => setSsoTarget(e.target.value)}
            inputClass="full-width sso-target-url"
            label="SSO target URL"
            helpBlockText="ACS URL used to log in to your SSO provider."
          />
          {renderEmailNodeField()}
          <TextArea
            textareaClass="full-width certificate-field"
            label="Certificate"
            name="certificate"
            placeholder="Paste in x.509 encoded certificate exactly as it's given by your identity provider, including the header and footer line."
            value={certificate}
            onChange={(e) => setCertificate(e.target.value)}
          />
        </div>
      </div>

      <div className="footer">
        <div className="form-actions-container">{renderFormActions()}</div>
      </div>

      <Confirm
        id={SAVE_MODAL}
        title="Hey! Just so you know..."
        message="By saving this record, you're making changes to your authentication flow, and the way your users can access our site. Just in case something is wrong, we'll still allow you to login with your username and password until we see a successful SAML authentication request. After that, you'll only be able to authenticate via SAML until you change your record again."
        onConfirm={updateSso}
        confirm_text="Got it, save the record."
      ></Confirm>

      <Confirm
        id={DELETE_MODAL}
        title="Hey! Just so you know..."
        message="Warning! This will cause your SSO configuration to be completely removed. This will force your users to login using Statuspage credentials only. This action is permanent and cannot be undone."
        onConfirm={deleteSso}
        confirm_text="Permanently delete"
      ></Confirm>
    </div>
  );
};

export default SsoConfigForm;
