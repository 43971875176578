import React from 'react';
import ReactDOM from 'react-dom';
import UpdateBillingSubscriptionModal from 'components/manage/admin/billing_subscriptions/update_billing_subscription_modal';
import Confirm from 'components/confirm';
import Textarea from 'components/textarea';

var subscriptionToCancel = null;

class OrganizationPages extends React.Component {
  /**
   * @params props
   * @params props.pages
   * @params props.coupons
   * @params props.types
   * @params props.plans
   */
  constructor(props) {
    super(props);
    this.modalID = 'update-billing-subscription-modal';
    this.state = {};
  }

  componentDidMount() {
    $('.update-page-subscription').on(
      'click',
      this.openSubscriptionModal.bind(this),
    );
    $('.cancel').on('click', event => {
      var type, id;
      if (event.target.dataset.pageId) {
        type = 'page';
        id = event.target.dataset.pageId;
      } else if (event.target.dataset.organizationId) {
        type = 'organization';
        id = event.target.dataset.organizationId;
      }
      subscriptionToCancel = {
        id: id,
        type: type,
      };
      this.cancel();
    });
    $(ReactDOM.findDOMNode(this))
      .find('#btn-confirm-cancel-subscription')
      .on('click', event => {
        event.preventDefault();
        event.stopPropagation();
        this.onCancel()
          .then(() => {
            $(ReactDOM.findDOMNode(this))
              .find('#modal-confirmation-cancel-subscription')
              .modal('hide');
            window.location.reload();
          })
          .catch(error => {
            this.setState({ cancelError: error });
          });
      });
  }

  cancelSubscription(id, type, reason) {
    if (!id) alert('#cancelSubscription - You must provide a id to cancel.');
    if (!reason)
      alert('#cancelSubscription - You must provide a id to cancel.');
    if (type !== 'page' && type !== 'organization')
      alert(
        '#cancelSubscription - You must provide a valid type.  {page|organization}',
      );
    window.location = Routes[
      type + '_billing_admin_cancel_billing_subscription_path'
    ](id, { reason: reason });
  }

  componentWillUnmount() {
    $('.update-page-subscription').off(
      'click',
      this.openSubscriptionModal.bind(this),
    );
  }

  openSubscriptionModal(event) {
    let pageId;
    if (!!$(event.target).data().pageId) {
      pageId = $(event.target).data().pageId;
    } else {
      pageId = $(event.target)
        .parent('*[data-page-id]')
        .data().pageId;
    }
    this.setState({ page: null }, () => {
      this.setState({ page: this.props.pages[pageId] }, () => {
        $(`.modal#${this.modalID}`).modal();
      });
    });
  }

  onCancel() {
    return new Promise((resolve, reject) => {
      let reason = $(ReactDOM.findDOMNode(this.refs.reason))
        .find('textarea')
        .eq(0)
        .val()
        .trim();
      if (!reason) {
        reject('You must provide a reason for cancelling');
      } else if (!subscriptionToCancel) {
        reject('There was a problem cancelling your subscription');
      } else {
        $.ajax({
          type: 'DELETE',
          url: Routes[
            `${subscriptionToCancel.type}_billing_admin_cancel_billing_subscription_path`
          ](subscriptionToCancel.id, { format: 'json' }),
          data: { reason: reason },
          success: resolve,
          error: errors => {
            reject(JSON.parse(errors.responseText).errors[0]);
          },
        });
      }
    });
  }

  cancel() {
    $(ReactDOM.findDOMNode(this))
      .find('#modal-confirmation-cancel-subscription')
      .modal();
  }

  cancelError() {
    if (this.state.cancelError) {
      return (
        <span className="error" style={{ color: 'indianred' }}>
          {this.state.cancelError}
        </span>
      );
    }
  }

  subscriptionModal() {
    if (this.state.page) {
      return (
        <UpdateBillingSubscriptionModal
          id={this.modalID}
          page={this.state.page}
          plans={this.props.plans}
          coupons={this.props.coupons}
          types={this.props.types}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.subscriptionModal()}
        <Confirm
          id="cancel-subscription"
          title="Cancel subscription"
          onConfirm={this.onCancel.bind(this)}
          confirm_text="Yes, cancel it"
        >
          <div style={{ padding: '1rem 2rem' }}>
            <Textarea name="reason" ref="reason" label="Are you sure?  Why?" />
            {this.cancelError()}
          </div>
        </Confirm>
      </div>
    );
  }
}

export default OrganizationPages;
