import moment from 'moment';
import Notifications from 'lib/manage/notifications';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import SubscriberAction from './subscriber_action';

function tryToCropChannelName(name) {
  if (!name) {
    return name;
  }

  const startIndex = Math.max(1, name.length - 29);
  return `#${name.substr(startIndex)}`;
}

/**
 * This component represents a single row in the notifications subscriber list.
 */
export default class NotificationsSubscriberRow extends React.Component {
  static propTypes = {
    // The expected subscriber shape from the api.
    subscriber: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      quarantined_at: PropTypes.string,
      purge_at: PropTypes.string,
      mode: PropTypes.oneOf(['email', 'sms', 'slack', 'webhook', 'teams'])
        .isRequired,
      email: PropTypes.string,
      endpoint: PropTypes.string,
      phone_number: PropTypes.string,
    }).isRequired,
    state: PropTypes.oneOf(['active', 'unconfirmed', 'quarantined']).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      ),
    ]),
    href: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    href: null,
  };

  render() {
    const childrenWithBoundCallbacks = React.Children.map(
      this.props.children,
      (child) => {
        if (child && child.type === SubscriberAction) {
          // This onClick is the one for links that *do not* rely on the current selection.
          return React.cloneElement(child, {
            onClick: (e) =>
              child.props.onClick(
                e,
                [this.props.subscriber.id],
                child.props.subscriberState,
              ),
          });
        }
        return child;
      },
    );

    const quarantinedAtAdg3DateFormat = `${moment(
      this.props.subscriber.quarantined_at,
    ).format('DD MMM YYYY')} /
    ${moment(this.props.subscriber.purge_at).format('DD MMM YYYY')}`;

    const quarantineColumn =
      this.props.state === 'quarantined' ? (
        <div
          className={`subscriber-content-column quarantined-at ${this.props.state}`}
          role="gridcell"
        >
          {quarantinedAtAdg3DateFormat}
        </div>
      ) : null;

    const createdAtDateFormat = moment(this.props.subscriber.created_at).format(
      'DD MMM YYYY',
    );

    const workspaceNameColumn = (
      <div
        className={`subscriber-content-column workspace-name ${this.props.state}`}
        role="gridcell"
      >
        {this.props.subscriber['workspace_name']}
      </div>
    );

    const channelNameColumn = (
      <div
        className={`subscriber-content-column channel-name ${this.props.state}`}
        role="gridcell"
      >
        {tryToCropChannelName(this.props.subscriber['obfuscated_channel_name'])}
      </div>
    );

    const subscriberTitle = (
      <div
        className={`subscriber-content-column subscriber-title ${this.props.state}`}
        role="gridcell"
      >
        {
          this.props.subscriber[
            Notifications.SUBSCRIBER_DISPLAY_FIELD_BY_TYPE[
              this.props.subscriber.mode
            ]
          ]
        }
      </div>
    );

    const subscriberIdColumns =
      this.props.subscriber.mode === 'slack' ? (
        <>
          {workspaceNameColumn}
          {channelNameColumn}
        </>
      ) : (
        subscriberTitle
      );

    const createdAtColumn = (
      <div
        className={classnames(
          'subscriber-content-column',
          'created-at',
          this.props.state,
        )}
        role="gridcell"
      >
        {createdAtDateFormat}
      </div>
    );

    const actionsColumn = (
      <div
        className={classnames(
          'subscriber-content-column',
          'actions',
          this.props.state,
        )}
        role="gridcell"
      >
        {childrenWithBoundCallbacks}
      </div>
    );

    return (
      <div className="subscriber-content-columns">
        {subscriberIdColumns}
        {createdAtColumn}
        {quarantineColumn}
        {actionsColumn}
      </div>
    );
  }
}
