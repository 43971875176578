import React from 'react';
import types from 'prop-types';

import Label from 'components/label';
import ItemSelector from 'components/items_selector/items_selector';

import Header from './Header';

const PageAccessGroups = ({
  data: { items, preselectedItemIds, hiddenFieldName, hiddenFieldId },
  hasSeparator,
  isVisible,
  onToggle: handleToggleGroup,
}) => (
  <div className={`associated-groups${isVisible ? '' : ' hidden'}`}>
    <Header
      description="Choose what groups to associate this user with. Uncheck all group selections to associate a user with individual components and metrics instead."
      title="Groups"
    />
    <Label text="Associated groups" className="associated-components-label" />
    <ItemSelector
      className={`inline ${hasSeparator ? '' : 'no-separator'}`}
      items={items}
      preselectedItemIds={preselectedItemIds}
      hiddenFieldName={hiddenFieldName}
      onToggle={handleToggleGroup}
      hiddenFieldId={hiddenFieldId}
    />
  </div>
);

PageAccessGroups.propTypes = {
  data: types.shape({
    hiddenFieldName: types.string,
    hiddenFieldId: types.string,
    items: types.array,
    display_name: types.string,
    preselectedItemIds: types.arrayOf(types.string),
  }).isRequired,
  hasSeparator: types.bool.isRequired,
  isVisible: types.bool.isRequired,
  onToggle: types.func.isRequired,
};

export default PageAccessGroups;
