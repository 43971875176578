import React from 'react';
import Input from './input';
import SelectInput from './select_input';

class InputDropdownToggle extends React.Component {
  /*
   * A combination Dropdown/Input field that allows the user to either select an existing value, or create a new one.
   * On selection of 'Create a new (template, component, etc)', the field will toggle to an input value.
   *
   * @param {object} props
   * @param {object} props.dropdownName - name tied to the Dropdown
   * @param {object} props.inputName - name tied to the Input
   * @param {object} props.label - label for the input & dropdown
   * @param {object} props.helpBlockText - helpblock text for the input & dropdown
   * @param {object} props.options - options for the dropdown box
   * @param {object} props.defaultValue - defaultValue for the text area
   * @param {object} props.toggleElement - the value in the dropdown that triggers the switch to input
   * @param {object} props.cancelText - Text and value for link that cancels new group creating
   * @param {object} props.startWithInput - boolean deciding whether to display the Input field from the start
   * @param {object} props.inputStartValue - string value to pre-populate the input field with
   */

  constructor(props) {
    super(props);
    this.id = `input-dropdown-toggle-${Math.round(Math.random() * 100000)}`;
    this.state = { showDropdown: !this.props.startWithInput };
  }

  _handleGroupChange(event) {
    if (event.target.value === this.props.toggleElement) {
      this.setState({ showDropdown: false });
    } else if (event.target.id === `${this.id}-cancel-link`) {
      this.setState({ showDropdown: true });
    }
  }

  _determineFormElement() {
    if (this.state.showDropdown) {
      return (
        <SelectInput
          name={this.props.dropdownName}
          label={this.props.label}
          helpBlockText={this.props.helpBlockText}
          options={this.props.options}
          defaultValue={this.props.defaultValue}
          onChange={this._handleGroupChange.bind(this)}
          selectClass={this.props.selectClass}
        />
      );
    } else {
      return (
        <span>
          <Input
            type="text"
            inputClass="full-width"
            label={this.props.label}
            name={this.props.inputName}
            placeholder={this.props.placeholder}
            selectClass={this.props.selectClass}
            defaultValue={this.props.inputStartValue}
          />
          <span className="help-block">
            <a
              href="#"
              id={`${this.id}-cancel-link`}
              onClick={this._handleGroupChange.bind(this)}
            >
              {this.props.cancelText}
            </a>
          </span>
        </span>
      );
    }
  }

  render() {
    return <div className="control-group">{this._determineFormElement()}</div>;
  }
}

export default InputDropdownToggle;
