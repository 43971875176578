import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import ItemsSelector from 'components/items_selector/items_selector';
import Confirm from 'components/confirm';
import { MODAL_PREFIX } from './constants.js';

const UNSUBSCRIBE_MODAL = 'unsubscribe-user';
const UNSUBSCRIBE_MODAL_TRIGGER = MODAL_PREFIX + UNSUBSCRIBE_MODAL;

class ComponentToggler extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this))
      .parents('form')
      .submit(e => {
        if (this._allUntoggled()) {
          this._triggerModal(UNSUBSCRIBE_MODAL_TRIGGER, e);
        }
      });
  }

  _allUntoggled() {
    return _.every($('.grouped-item'), item => {
      return !$(item).hasClass('active');
    });
  }

  _triggerModal(modalId, e) {
    e.preventDefault();
    e.stopPropagation();
    $(ReactDOM.findDOMNode(this))
      .find('#' + modalId)
      .modal();
  }

  _submit() {
    const $form = $(ReactDOM.findDOMNode(this)).parents('form');
    $form.unbind();
    $form.submit();
  }

  render() {
    return (
      <div>
        <ItemsSelector {...this.props} />
        <Confirm
          id={UNSUBSCRIBE_MODAL}
          title="Hey! Just so you know..."
          message="This will unsubscribe this user from all updates. Are you sure you want to do that?"
          onConfirm={this._submit.bind(this)}
          confirm_text="Unsubscribe user"
        />
      </div>
    );
  }
}

export default ComponentToggler;
