import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TabPanels from './tab_panels';
import TabHeaders from './tab_headers';

// TabGroup: A tab widget matching views with tabs.
//
// TabGroup is designed to use React declarative style in a more idiomatic way than TabNavigationBar,
// our other tab widget, and may eventually replace it.
//
// Usage:
//
//   <TabGroup default="first">
//     <TabHeaders>
//       <TabHeader name="first">First Tab</TabHeader>
//       <TabHeader name="second">Second Tab</TabHeader>
//       <TabHeader name="third">Third Tab</TabHeader>
//     </TabHeaders>
//     <TabPanels>
//       <TabPanel for="first"><div>I am the first tab content.</div></TabPanel>
//       <TabPanel for="second"><div>I am the second tab content.</div></TabPanel>
//       <TabPanel for="third"><div>I am the third tab content.</div></TabPanel>
//     </TabPanels>
//   </TabGroup>

export default class TabGroup extends React.Component {
  static propTypes = {
    // Index of the active TabHeader.
    default: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.oneOf([TabHeaders, TabPanels]) }),
    ).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    onChange: _ => {},
  };

  state = {
    activeName: this.props.default,
  };

  render() {
    const children = React.Children.map(this.props.children, child => {
      if (child.type === TabPanels) {
        return React.cloneElement(child, {
          activeName: this.state.activeName,
        });
      }
      if (child.type === TabHeaders) {
        return React.cloneElement(child, {
          activeName: this.state.activeName,
          onActivate: activeName => {
            this.setState({ activeName });
            this.props.onChange(activeName);
          },
        });
      }
      return child;
    });

    return (
      <div className={classnames('tab-group', this.props.className)}>
        {children}
      </div>
    );
  }
}
