import * as React from 'react';

enum SubscriberState {
  ACTIVE = 'active',
  QUARANTINED = 'quarantined',
  UNCONFIRMED = 'unconfirmed',
}

interface Props {
  className: string;
  children: React.ReactChildren;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    subscriberCode: string | undefined,
    subscriberState: SubscriberState,
  ) => void;
  subscriberState: SubscriberState;
}

export default class SubscriberAction extends React.Component<Props> {
  static defaultProps = {
    className: '',
    children: null,
    onClick: () => {},
    subscriberState: 'active',
  };

  render() {
    return (
      <button
        className={`subscriber-action link-like foobar ${this.props.className}`}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          this.props.onClick(e, undefined, this.props.subscriberState)
        }
      >
        {this.props.children}
      </button>
    );
  }
}
