import * as React from 'react';
import { observer } from 'mobx-react';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { isValidColor } from './utils';

interface ColorInputProps {
  colorConfigItem: string;
  inputClassName: string;
  onChange: (inputColor: string) => void;
}

interface ColorInputState {
  validColor: boolean;
}

export default class ColorInput extends React.Component<
  ColorInputProps,
  ColorInputState
> {
  constructor(props: ColorInputProps) {
    super(props);
    this.state = { validColor: true };
  }

  render() {
    return (
      <div className="color-input-container">
        <input
          type="text"
          className={this.props.inputClassName}
          onChange={(event: any) =>
            this.props.onChange(event.target.value.toUpperCase())
          }
          value={this.props.colorConfigItem.replace(/#/g, '')}
          onBlur={(event: any) =>
            this.setState({
              validColor: isValidColor(this.props.colorConfigItem),
            })
          }
          maxLength={6}
        />
        {this.maybeRenderErrorMessage()}
      </div>
    );
  }

  maybeRenderErrorMessage() {
    if (!this.state.validColor) {
      return (
        <div className="invalid-color">
          <ErrorIcon label="error-icon" size="small" />
          <p className="error-text">Enter a valid hex code</p>
        </div>
      );
    }
  }
}
