import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ICON_CLASSES = {
  info: 'fa-info-circle',
  warning: 'fa-warning',
  error: 'fa-exclamation-circle',
  success: 'fa-check-circle',
};

// Displays a header and body in a box.
// The box is formatted according to the type prop.
export default function CptAlertBox(props) {
  return (
    <div
      className={classnames(
        'alert-box',
        'cpt-notification',
        props.type,
        'in-page',
      )}
    >
      <div className="header">
        <span
          className={classnames('fa', ICON_CLASSES[props.type])}
          style={{ marginRight: '4px' }}
        />
        {props.header}
      </div>
      <div className="body">{props.body}</div>
    </div>
  );
}

CptAlertBox.propTypes = {
  type: PropTypes.oneOf(['info', 'error', 'warning', 'success']).isRequired, // Dictating the display type
  // The larger, heading message
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  // The larger, heading message
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
