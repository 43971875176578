import React from 'react';

/**
 * A simple component that will render a label that can include a Statuspage style tooltip with a help icon.
 *
 **/
class Label extends React.Component {
  componentDidMount() {
    $(this.refs.tooltip).tooltip();
  }

  componentWillUnmount() {
    $(this.refs.tooltip).tooltip('destroy');
  }

  _tooltip() {
    if (this.props.tooltipText) {
      return (
        <a
          href="#"
          ref="tooltip"
          className="tooltip-base"
          data-js-hook="tooltip"
          data-original-title={this.props.tooltipText}
        >
          ?
        </a>
      );
    }
  }

  render() {
    return (
      <label className={this.props.className}>
        {this.props.text}
        {this._tooltip()}
      </label>
    );
  }
}

export default Label;
