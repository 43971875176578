import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Pill extends Component {
  static propTypes = {
    // `value` declares the value identifying this pill in the pill group.
    value: PropTypes.string.isRequired,

    // `active` declares whether or not the pill is clickable.
    active: PropTypes.bool,

    // On click, calls this callback with this component's `value` as the only arguemnt.
    onClick: PropTypes.func,

    // The label/content of the pill group
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      ),
    ]).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    active: false,
    onClick: () => {},
    className: '',
  };

  render() {
    return (
      <button
        className={classnames(
          'pill',
          this.props.active ? 'active' : 'inactive',
          this.props.className,
        )}
        onClick={() => this.props.onClick(this.props.value)}
      >
        {this.props.children}
      </button>
    );
  }
}
