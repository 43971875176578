import * as React from 'react';

import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';

interface PreviousPageLinkProps {
  path: string;
}

const PreviousPageLink = ({ path }: PreviousPageLinkProps) => {
  return (
    <a className="previous-page-link" href={path}>
      <span className="chevron-container">
        <ChevronLeftIcon label="Return to authentication" />
      </span>
      <span className="text">Authentication</span>
    </a>
  );
};

export default PreviousPageLink;
