import * as React from 'react';
import { Subscriber } from './types';
import PageColors from 'page_colors';
import { Page } from 'resources/types';
import './styles.scss';
const pageColors = new PageColors(window.pageColorData);

const Header = ({ children }: { children: React.ReactNode }) => (
  <span style={{ color: pageColors.font.hex }}>
    <strong>{children}</strong>
  </span>
);

const unsubscribe = (page: Page, subscriber: Subscriber): void => {
  $('#modal-confirmation-confirmationModalId')
    .modal()
    .on('shown.bs.modal', function() {
      $('#modal-confirmation-confirmationModalId-cross').focus();
      $('#modal-confirmation-confirmationModalId').on('keydown', function(e) {
        if (
          $('#modal-confirmation-confirmationModalId').is(':visible') &&
          e.which == 9
        ) {
          // if modal is open and tab key is pressed
          const focusableElements =
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
          const modal = $(this);
          const { activeElement } = document;
          if (activeElement) {
            const focusedElement = $(activeElement);
            const focusable = modal.find(focusableElements).filter(':visible');
            const firstFocusable = focusable.first();
            const lastFocusable = focusable.last();
            if (e.shiftKey) {
              if (focusedElement.is(firstFocusable)) {
                lastFocusable.focus();
                e.preventDefault();
              }
            } else if (focusedElement.is(lastFocusable)) {
              firstFocusable.focus();
              e.preventDefault();
            }
          }
        }
      });
    });
  $('#modal-confirmation-confirmationModalId-cross').on('click', function() {
    $('.unsubscribe-button').focus();
  });
};

interface HeadingProps {
  page: Page;
  subscriber: Subscriber;
}

const Heading = ({ page, subscriber }: HeadingProps) => {
  const mode = Subscriber.displayMode(subscriber);
  const identifier = Subscriber.identifier(subscriber);

  return (
    <div className="subscriber-heading">
      <Header>Subscriber</Header>
      <div className="border" style={{ borderColor: pageColors.border.hex }}>
        <h2 className="mode" style={{ color: pageColors.font.hex }}>
          {mode}
        </h2>
        <h3 className="identifier" style={{ color: pageColors.font.hex }}>
          {identifier}
        </h3>

        <button
          className="unsubscribe-button"
          data-testid="unsubscribe-button"
          style={{ color: pageColors.red.hex }}
          onClick={() => unsubscribe(page, subscriber)}
        >
          Unsubscribe from updates
        </button>
      </div>
    </div>
  );
};

export default Heading;
