import React from 'react';
import inflection from 'inflection';
import _ from 'lodash';

import SelectInput from '../../../select_input';

class TypeSelector extends React.Component {
  types() {
    let options = Object.keys(this.props.types).map(type => {
      return {
        name: inflection.titleize(
          inflection.underscore(
            type.replace('BillingSubscription', 'Subscription'),
          ),
        ),

        value: type,
      };
    });

    return _.sortBy(options, 'name');
  }

  render() {
    return (
      <SelectInput
        name="billing_subscription[type]"
        options={this.types()}
        label="Type"
        id="type_id"
        defaultValue={this.props.type}
        onChange={this.props.onChange.bind(this)}
        helpBlockText={this.props.types[this.props.type].helpBlockText}
        modal={true}
      />
    );
  }
}

export default TypeSelector;
