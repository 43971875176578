/**
 * (legacy JavaScript version, see also get-meta-value.ts)
 * Obtains a text content value from a <meta> DOM tag. We use these DOM tags to
 * store runtime constants so that we can decouple webpack build environments
 * from their execution environment. This strategy is in contrast to something
 * like the Webpack `DefinePlugin` which substitutes literals for variable
 * expressions during the build itself.
 */
const getMetaValue = (name) => {
  const metaNode = document.querySelector(`meta[name="${name}"]`);

  if (metaNode == null) {
    return null;
  }

  return metaNode.getAttribute('content');
};

const env = getMetaValue('runtime-environment') || 'development';

export const RUNTIME_ENVIRONMENT = {
  development: env === 'development',
  ddev: env.slice(0, 4) === 'ddev',
  staging: env === 'staging',
  production: env === 'production',
  test: env === 'test',
  toString: env,
};

export const MODAL_PREFIX = 'modal-confirmation-';
export default { MODAL_PREFIX };
