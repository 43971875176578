import React from 'react';

import FormNoMutations from 'components/formNoMutations';
import Checkbox from 'components/checkbox';
import Input from './../../input';
import InputDropdownToggle from './../../input_dropdown_toggle';
import SelectInput from './../../select_input';
import TextArea from './../../textarea';
import ItemsSelector from 'components/items_selector/items_selector';
import { Checkbox as CheckboxADG3 } from '@atlaskit/checkbox';

class IncidentTemplateForm extends React.Component {
  static defaultProps = {
    shouldTweet: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldTweet: this.props.should_tweet,
      shouldSendNotifications: this.props.should_send_notifications,
    };
  }

  _showGroupDropdown() {
    if (this.props.allow_groups) {
      return (
        <InputDropdownToggle
          dropdownName="incident_template[group_id]"
          inputName="group_name"
          label="Template group"
          helpBlockText="The group that this template belongs to."
          placeholder="New template group name"
          toggleElement="create"
          cancelText="Cancel new group creation"
          options={this.props.group_values}
          defaultValue={this.props.default_group}
          selectClass="full-width"
          startWithInput={this.props.startWithInput}
          inputStartValue={this.props.inputStartValue}
        />
      );
    } else {
      return (
        <input
          type="hidden"
          id="nil_group_selector"
          name="incident_template[group_id]"
          value="no_group"
        />
      );
    }
  }

  _showComponentSelector() {
    if (this.props.components.length > 0) {
      return (
        <div className="component-subscriptions control-group">
          <label>Components affected</label>
          <ItemsSelector
            items={this.props.components}
            className={this.props.item_selector_class_name}
            preselectedItemIds={this.props.selected_components}
            hiddenFieldName="incident_template[component_ids]"
          />
        </div>
      );
    }
  }

  _showSendNotificationsCheckbox() {
    return (
      <div className="send-notifications-update control-group">
        <label>Send notifications</label>
        <Checkbox
          name="incident_template[should_send_notifications]"
          label="Checks the 'send notifications' checkbox when using this template"
          defaultChecked={this.props.should_send_notifications}
          onChange={this._handleSendNotificationsCheckboxChange}
        />
        <input
          type="hidden"
          id="should_send_notifications"
          name="incident_template[should_send_notifications]"
          value={!!this.state.shouldSendNotifications}
        />
      </div>
    );
  }

  _showSendNotificationsCheckboxADG3() {
    return (
      <div className="send-notifications-update control-group">
        <label className="send-notifications-title">Send notifications</label>
        <div className="send-notifications-checkbox">
          <CheckboxADG3
            name="incident_template[should_send_notifications]"
            label={
              <span className="label">
                Checks the 'send notifications' checkbox when using this
                template
              </span>
            }
            isChecked={this.state.shouldSendNotifications}
            onChange={this._handleSendNotificationsCheckboxChange}
          />
        </div>
        <input
          type="hidden"
          id="should_send_notifications"
          name="incident_template[should_send_notifications]"
          value={!!this.state.shouldSendNotifications}
        />
      </div>
    );
  }

  _showTwitterCheckbox() {
    // Checkboxes are shitty, and either return 'on' or nil.
    // Utilize a hidden input to send either true or false to the controller.
    if (this.props.allow_twitter && this.props.isTwitterAuthorized) {
      return (
        <div className="twitter-update control-group">
          <label>Tweet update</label>
          <Checkbox
            name="incident_template[should_tweet]"
            label="Check the 'Post to Twitter' checkbox when using this template"
            defaultChecked={this.props.should_tweet}
            onChange={this._handleTwitterCheckboxChange}
          />
          <input
            type="hidden"
            id="should_tweet"
            name="incident_template[should_tweet]"
            value={!!this.state.shouldTweet}
          />
        </div>
      );
    } else {
      return (
        <input
          type="hidden"
          id="should_tweet"
          name="incident_template[should_tweet]"
          value="false"
        />
      );
    }
  }

  _showTwitterCheckboxADG3() {
    if (this.props.allow_twitter && this.props.isTwitterAuthorized) {
      return (
        <div className="send-twitter-update control-group">
          <div className="twitter-update-checkbox">
            <CheckboxADG3
              name="incident_template[should_tweet]"
              label={<span className="label">Tweet update</span>}
              isChecked={this.state.shouldTweet}
              onChange={this._handleTwitterCheckboxChange}
            />
          </div>
          <input
            type="hidden"
            id="should_tweet"
            name="incident_template[should_tweet]"
            value={!!this.state.shouldTweet}
          />
        </div>
      );
    } else {
      return (
        <input
          type="hidden"
          id="should_tweet"
          name="incident_template[should_tweet]"
          value="false"
        />
      );
    }
  }

  _handleTwitterCheckboxChange = (event) => {
    this.setState({ shouldTweet: event.target.checked });
  };

  _handleSendNotificationsCheckboxChange = (event) => {
    this.setState({ shouldSendNotifications: event.target.checked });
  };

  render() {
    return (
      <div className="incident-template-form">
        <FormNoMutations
          for="IncidentTemplate"
          method={this.props.method}
          action={this.props.path}
        >
          {this._showGroupDropdown()}
          <Input
            type="text"
            inputClass="full-width"
            label="Template name"
            name="incident_template[name]"
            placeholder="Template name"
            defaultValue={this.props.template ? this.props.template.name : null}
            helpBlockText="This is used internally so you can identify which template you're using."
          />

          <SelectInput
            name="incident_template[update_status]"
            label="Incident status"
            options={this.props.status_values}
            helpBlockText="The status that will be applied to the incident or scheduled maintenance."
            defaultValue={
              this.props.template ? this.props.template.update_status : null
            }
            selectClass="full-width"
            groups={['Realtime incidents', 'Scheduled maintenances']}
          />

          <Input
            type="text"
            inputClass="full-width"
            label="Title"
            name="incident_template[title]"
            placeholder="Title"
            defaultValue={
              this.props.template ? this.props.template.title : null
            }
            helpBlockText="The title given to the incident or scheduled maintenance. This is only applied when creating an incident or scheduled maintenance."
          />

          <div className="span12 text-area-container control-group">
            <TextArea
              inputClass="full-width"
              label="Message body"
              name="incident_template[body]"
              placeholder="Message body"
              defaultValue={
                this.props.template ? this.props.template.body : null
              }
              textareaStyle={{ height: 160 }}
            />
          </div>
          {this._showComponentSelector()}
          <>
            {this._showSendNotificationsCheckboxADG3()}
            {this._showTwitterCheckboxADG3()}
          </>
          <div className="form-actions">
            <button
              type="submit"
              className="cpt-button style-primary size-small"
            >
              {`${this.props.button_text}`}
            </button>
          </div>
        </FormNoMutations>
      </div>
    );
  }
}

export default IncidentTemplateForm;
