import * as React from 'react';
import './styles.scss';
import cx from 'classnames';
import Input from 'components/input';
import notify, { Color } from 'utils/notify';

export interface PageAuthenticationProps {
  sso: ConfigOptions;
  google: ConfigOptions;
  teamOnly: TeamOnly;
  ipAllowlist: IpAllowlist;
  shouldHideGoogleAuth: boolean;
}

interface ConfigOptions {
  path: string;
  enabled: boolean;
}

interface TeamOnly {
  show: boolean;
  enabled: boolean;
  path: string;
}

interface IpAllowlist {
  show: boolean;
  enabled: boolean;
  ips: string;
  path: string;
}

const PageAuthentication = ({
  sso,
  google,
  teamOnly,
  ipAllowlist,
  shouldHideGoogleAuth,
}: PageAuthenticationProps) => {
  /** STATE VARIABLES */
  const [teamOnlyEnabled, setTeamOnlyEnabled] = React.useState(
    teamOnly.enabled,
  );
  const [ips, setIps] = React.useState(ipAllowlist.ips);
  const [ipAllowlistingEnabled, setIpAllowlistingEnabled] = React.useState(
    ipAllowlist.enabled,
  );

  /** UTILITY FUNCTIONS */
  const isSsoDisabled = () => {
    return (
      (google.enabled || teamOnlyEnabled || ipAllowlistingEnabled) &&
      !sso.enabled
    );
  };

  const isGoogleDisabled = () => {
    return (
      (sso.enabled || teamOnlyEnabled || ipAllowlistingEnabled) &&
      !google.enabled
    );
  };

  const isTeamOnlyDisabled = () => {
    return (
      (sso.enabled || google.enabled || ipAllowlistingEnabled) &&
      !teamOnlyEnabled
    );
  };

  const isIpAllowlistingDisabled = () => {
    return (
      (sso.enabled || google.enabled || teamOnlyEnabled) &&
      !ipAllowlistingEnabled
    );
  };

  const disableMessage = () => {
    let enabledOption = '';
    if (sso.enabled) enabledOption = 'SAML';
    else if (google.enabled) enabledOption = 'Google Authentication';
    else if (teamOnlyEnabled) enabledOption = 'team only access';
    else if (ipAllowlistingEnabled) enabledOption = 'IP allowlisting';

    return enabledOption ? `Disable ${enabledOption} to enable` : '';
  };

  const saveIpAllowlist = async () => {
    try {
      await $.ajax({
        url: ipAllowlist.path,
        method: 'PATCH',
        dataType: 'json',
        data: {
          page: {
            ip_filters_list: ips,
          },
        },
      });

      // if any IPs were present in the string, it's now enabled
      setIpAllowlistingEnabled(ips.length > 0);
      notify('IP allowlisting updated!', {
        color: Color.SUCCESS,
      });
    } catch (e) {
      notify('IP allowlisting failed to update, please try again.', {
        color: Color.ERROR,
      });
    }
  };

  const disableIpAllowlist = async () => {
    try {
      await $.ajax({
        url: ipAllowlist.path,
        method: 'PATCH',
        dataType: 'json',
        data: {
          page: {
            ip_filters_list: '',
          },
        },
      });

      setIpAllowlistingEnabled(false);
      setIps('');
      notify('IP allowlisting disabled.', {
        color: Color.SUCCESS,
      });
    } catch (e) {
      notify('IP allowlisting failed to be disabled, please try again.', {
        color: Color.ERROR,
      });
    }
  };

  const updateTeamOnly = async (isChecked: boolean) => {
    try {
      await $.ajax({
        url: teamOnly.path,
        method: 'PATCH',
        dataType: 'json',
        data: {
          page: {
            viewers_must_be_team_members: isChecked,
          },
        },
      });

      setTeamOnlyEnabled(isChecked);
      notify(`Team only access successfully set to ${isChecked.toString()}.`, {
        color: Color.SUCCESS,
      });
    } catch (e) {
      notify('Team only access settings failed to save, please try again.', {
        color: Color.ERROR,
      });
    }
  };

  /** RENDER FUNCTIONS */
  const renderSsoButton = () => {
    return renderConfigureButton(sso, isSsoDisabled(), 'configure-sso-btn');
  };

  const renderGoogleButton = () => {
    return renderConfigureButton(
      google,
      isGoogleDisabled(),
      'configure-google-btn',
    );
  };

  const renderConfigureButton = (
    options: ConfigOptions,
    disabled: boolean,
    id: string,
  ) => {
    const text = options.enabled ? 'Update' : 'Configure';
    const disabledClass = disabled ? 'disabled' : '';
    const classes = cx(
      'cpt-button style-outline color-grey style-secondary size-small',
      disabledClass,
    );
    // For accessibility reasons, make this a not a link that goes nowhere,
    // but just text styled as a "disabled button"
    if (disabled) {
      return (
        <p className={classes} id={id}>
          {text}
        </p>
      );
    }

    return (
      <a href={options.path} className={classes} id={id}>
        {text}
      </a>
    );
  };

  const maybeRenderLegacyOptions = () => {
    return <div className="legacy-options">{maybeRenderTeamOnly()}</div>;
  };

  const renderIpAllowlisting = () => {
    return (
      <div className="legacy-option-container ip-allowlisting">
        <div className="heading">
          <div className="title">IP allowlisting</div>
        </div>
        <Input
          type="text"
          label="Restrict IPs"
          placeholder="10.20.0.0/16,10.20.30.40"
          value={ips}
          onChange={(e) => setIps(e.target.value)}
          inputClass="full-width"
          id="ip-filters"
          helpBlockText="Comma-separated list of IP addresses allowed to access your page. Masks allowed."
        />
        <div className="footer two-items">
          <div className="disable-message">
            {isIpAllowlistingDisabled() ? disableMessage() : ''}
          </div>
          <div className="form-actions-container">
            {maybeRenderIpDisableButton()}

            <button
              onClick={saveIpAllowlist}
              className={`cpt-button style-primary ${
                isIpAllowlistingDisabled() ? 'disabled' : null
              }`}
            >
              {ipAllowlistingEnabled ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const maybeRenderIpDisableButton = () => {
    if (ipAllowlistingEnabled) {
      return (
        <a onClick={disableIpAllowlist} className="btn-delete-config">
          Disable
        </a>
      );
    }
  };

  const maybeRenderTeamOnly = () => {
    if (teamOnly.show) {
      return (
        <div className="legacy-option-container team-only">
          <div className="heading">
            <div className="title">Team only access</div>
          </div>
          <label className="checkbox">
            <input
              type="checkbox"
              onChange={(e) => updateTeamOnly(e.target.checked)}
              defaultChecked={teamOnlyEnabled}
              disabled={isTeamOnlyDisabled()}
              id="team-only-access"
            />
            Only let me and my team members view our page
          </label>
          <div className="subtext">
            This will keep your page fully private and won’t let anybody except
            account holders on your team view the page.
          </div>
          <div className="footer">
            <div className="disable-message">
              {isTeamOnlyDisabled() ? disableMessage() : ''}
            </div>
          </div>
        </div>
      );
    }
  };

  /** FINAL RESULT */
  return (
    <div className="page-authentication">
      <h3>Authentication</h3>

      {maybeRenderLegacyOptions()}

      <div className="heading">
        <div className="description">
          <strong>Private pages</strong> are viewable by authenticated users.
          Set up your SAML provider (OKTA, PingIdentity, Bitum, OneLogin) or
          Google Auth to require page viewers to log in to see your status page.
          {ipAllowlist.show ? (
            <span>
              Alternatively, you can use IP allowlisting to specify which IP
              addresses page viewers must use to see your page.{' '}
            </span>
          ) : (
            <span>
              Alternatively, those on Growth plans and up can use IP
              allowlisting to specify which IP addresses page viewers must use
              to see your page.{' '}
            </span>
          )}
        </div>
        <div className="title">Single sign-on</div>
      </div>

      <div className="config-options">
        <div className="config-row">
          <div className="description">
            <div className="main">SAML</div>
            {isSsoDisabled() ? (
              <div className="subtext">{disableMessage()}</div>
            ) : null}
          </div>
          <div className="actions">{renderSsoButton()}</div>
        </div>
        {shouldHideGoogleAuth ? null : (
          <div className="config-row">
            <div className="description">
              <div className="main">Google auth</div>
              {isGoogleDisabled() ? (
                <div className="subtext">{disableMessage()}</div>
              ) : null}
            </div>
            <div className="actions">{renderGoogleButton()}</div>
          </div>
        )}
      </div>
      {ipAllowlist.show && renderIpAllowlisting()}
    </div>
  );
};

export default PageAuthentication;
