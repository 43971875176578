import React from 'react';
import types from 'prop-types';
import { Checkbox } from '@atlaskit/checkbox';

const Subscribe = ({ isVisible }) => (
  <div
    className={`control-group access-user-notification${
      isVisible ? '' : ' hidden'
    }`}
    data-js-hook="subscription-ui"
  >
    <div className="controls">
      <label htmlFor="checkbox-subscribe-to-component">
        Subscribe to notifications
      </label>
      <Checkbox
        label={
          <span id="page_access_user_subscribe_to_components">
            Subscribe this user to status updates for the selected groups or
            components/metrics
          </span>
        }
        name="page_access_user[subscribe_to_components]"
        id="checkbox-subscribe-to-component"
        className="atlaskit-checkbox-component"
        value="1"
      />
      <div className="help-block">
        User will not receive a confirmation email for the subscription.
      </div>
    </div>
    <br />
  </div>
);

Subscribe.propTypes = {
  data: types.shape({
    include: types.bool,
  }).isRequired,
  isVisible: types.bool.isRequired,
};

export default Subscribe;
