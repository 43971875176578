import styled from 'styled-components';

const StyledWrapper = styled.div`
  padding: 32px 0 20px;
`;

const ProgressTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const ProgressMessage = styled.div`
  color: #172b4d;
  font-weight: 600;
`;

const ProgressPercentComplete = styled.div`
  color: #6b778c;
`;

const ProgressCompleteWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const ProgressCompleteMainContent = styled.div`
  margin-right: 11px;
  padding-bottom: 8px;
  flex: 1;
`;

const ProgressCompleteCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #42526e;
`;

const MessageWithProgressBar = styled.div`
  margin-bottom: 16px;
  margin-top: -13px;
`;

const SectionMessageContents = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

export {
  StyledWrapper,
  ProgressTextWrapper,
  ProgressMessage,
  ProgressPercentComplete,
  ProgressCompleteWrapper,
  ProgressCompleteMainContent,
  ProgressCompleteCheckContainer,
  MessageWithProgressBar,
  SectionMessageContents,
};
