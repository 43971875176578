import * as React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';

import StatusUpdate from './status_update';
import PositionSetup from './position_setup';
import ColorSetup from './colors_setup';
import StatusEmbedStore from './store';
import './styles.scss';
import { StatusEmbedProps } from './types';
import CodeModal from './code_modal';
import notify, { Color } from 'utils/notify';

declare global {
  interface Window {
    analyticsClient: any;
  }
}

// Root component for configuring a Status Embed instance for a page.
@observer
export default class StatusEmbed extends React.Component<StatusEmbedProps, {}> {
  store: StatusEmbedStore;

  // Initialize the store
  constructor(props: StatusEmbedProps) {
    super(props);
    this.store = new StatusEmbedStore(props);
  }

  triggerModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    $('#embed-code-modal').modal(); // this is needed because our modals are a JQuery plugin (sad face)
    this.store.copyCode();

    window.analyticsClient.then((analyticsClient: any) => {
      analyticsClient.sendUIEvent(JSON.parse(this.props.analyticsPayload));
    });
  };

  onUpdate = async (e: any) => {
    const success = await this.store.performUpdate();
    if (success) {
      notify('Status embed settings successfully updated.', {
        color: Color.SUCCESS,
      });
    } else {
      notify('Status embed settings failed to update.', { color: Color.ERROR });
    }
  };

  render() {
    return (
      <div className="status-embed">
        <p className="description">
          Customize your status embed and copy/paste the autogenerated code
          snippet into your website.
        </p>
        <StatusUpdate store={this.store} />
        <PositionSetup
          position={this.store.positionDraft}
          onChange={value => (this.store.positionDraft = value)}
        />
        <ColorSetup store={this.store} />
        {this.renderFormControls()}
        <CodeModal store={this.store} />
      </div>
    );
  }

  renderFormControls() {
    const updateBtnClasses = ['cpt-button', 'style-primary', 'update-btn'];
    if (!this.store.readyToUpdate) updateBtnClasses.push('disabled'); // make btn disabled if we're not in an okay state to update

    return (
      <div className="embed-form-controls">
        {this.renderCopyCodeBtn()}
        <button className={cx(updateBtnClasses)} onClick={this.onUpdate}>
          {this.store.onlineStatus ? 'Update' : 'Save'}
        </button>
      </div>
    );
  }

  renderCopyCodeBtn() {
    return (
      <button
        className="cpt-button style-subtle copy-code-btn"
        onClick={this.triggerModal}
      >
        Copy code
      </button>
    );
  }
}
