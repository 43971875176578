import React, { ReactElement } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { SectionMessageAppearance } from './types';

import { SectionMessageContents } from './styles';

interface SectionMessageContainerProps {
  appearance: SectionMessageAppearance;
  message: ReactElement | string;
  link_text?: string;
  link?: string;
  onClick?: Function;
}

export default function SectionMessageContainer({
  appearance,
  message,
  link_text,
  link,
  onClick,
}: SectionMessageContainerProps) {
  function maybeRenderLink() {
    if (link) {
      return (
        <p>
          <a
            href={link}
            target="_blank"
            onClick={onClick ? () => onClick() : undefined}
          >
            {link_text}
          </a>
        </p>
      );
    }
  }

  return (
    <SectionMessage appearance={appearance}>
      <SectionMessageContents>
        <p>{message}</p>
        {maybeRenderLink()}
      </SectionMessageContents>
    </SectionMessage>
  );
}
