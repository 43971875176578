import Confirm from 'components/confirm';

import { MODAL_PREFIX } from '../../constants.js';
import React from 'react';
import ReactDOM from 'react-dom';
import Form from 'components/form';
import Input from './../../input';
import TextArea from './../../textarea';

const DELETE_MODAL = 'delete-provider';
const DELETE_MODAL_TRIGGER = MODAL_PREFIX + DELETE_MODAL;

const SAVE_MODAL = 'save-provider';
const SAVE_MODAL_TRIGGER = MODAL_PREFIX + SAVE_MODAL;

class SingleSignOnForm extends React.Component {
  componentDidMount() {
    HRB.utils.djshook('tooltip').tooltip();
  }

  _entityId() {
    const { entity_uuid, domain } = this.props;
    return `https://${entity_uuid}.${domain}/`;
  }

  _emailNodeField() {
    const { audience_specific_page, provider } = this.props;

    if (audience_specific_page) {
      return (
        <div className="span12">
          <Input
            type="text"
            inputClass="full-width"
            label="Page access user email node"
            name="email_node_name"
            placeholder="Page access user email node"
            defaultValue={provider && provider.email_node_name}
            helpBlockText="This is the attribute we'll use to set an email address for your page access users. We suggest you use 'email'"
          />
        </div>
      );
    }
  }

  _handleDelete() {
    const { path } = this.props;

    $.ajax({
      type: 'DELETE',
      url: path,
      dataType: 'JSON',
      success: function(data, textStatus, xhr) {
        window.location.reload(true);
      },
      error: function(xhr, textStatus, errorThrown) {
        HRB.utils.notify(
          'Sorry, it looks like deleting your SSO configuration failed',
          { cssClass: 'error' },
        );
      },
    });
  }

  _deleteButton() {
    const { provider } = this.props;
    if (provider) {
      return (
        <a
          href="#"
          method="DELETE"
          onClick={this._triggerModal.bind(this, DELETE_MODAL_TRIGGER)}
          className="small"
        >
          Remove SSO restriction
        </a>
      );
    }
  }

  _triggerModal(modalId, e) {
    e.preventDefault();
    e.stopPropagation();
    $(ReactDOM.findDOMNode(this))
      .find('#' + modalId)
      .modal();
  }

  _submitForm() {
    $(ReactDOM.findDOMNode(this))
      .find('#sso-idp-form')
      .submit();
  }

  render() {
    const {
      sso_saml_consume_url,
      metadata_path,
      ownerType,
      ownerHasRestriction,
      path,
      entity_uuid,
      owner,
      provider,
      domain,
    } = this.props;
    return (
      <div>
        <h5 className="step-header">
          Step 1: Add the Statuspage application in your identity provider
        </h5>
        <table className="cpt-tabular-table">
          <thead>
            <tr>
              <th>
                ACS URL / Consumer URL&nbsp;
                <span
                  className="tooltip-base"
                  data-js-hook="tooltip"
                  data-original-title="This URL is where your Identity Provider will send SAML assertions, and will be required in your Identity Provider's admin when setting up a Statuspage app."
                >
                  ?
                </span>
              </th>
              <th>
                EntityID / Audience URI&nbsp;
                <span
                  className="tooltip-base"
                  data-js-hook="tooltip"
                  data-original-title="This is the value you'll need to enter in the 'Entity ID' field in your Identity Provider's configuration. This may also be referred to as 'Audience Restriction' or 'Audience URI'."
                >
                  ?
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="consumeURL"> {sso_saml_consume_url} </td>
              <td className="entityID">
                {this._entityId(entity_uuid, domain)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="align-center" style={{ marginTop: '8px' }}>
          <small>
            You can also view the entire&nbsp;
            <a href={metadata_path} target="_blank">
              service provider metadata XML file for this {ownerType}
            </a>
          </small>
        </div>

        <h5 className="step-header">
          Step 2: Paste in the SSO target URL and certificate returned by your
          IDP
        </h5>
        <Form
          for="SsoIdentityProvider"
          id="sso-idp-form"
          onSubmit={this._triggerModal.bind(this, SAVE_MODAL_TRIGGER)}
          method={provider ? 'PUT' : 'POST'}
          action={path}
        >
          <input type="hidden" name="entity_uuid" value={entity_uuid} />
          <input type="hidden" name="owner_type" value={ownerType} />
          <input type="hidden" name="owner_id" value={owner.id} />

          <div className="row">
            <div className="span12">
              <Input
                type="text"
                inputClass="full-width"
                label="SSO target URL"
                name="sso_target_url_text"
                placeholder="SSO target URL"
                defaultValue={provider && provider.sso_target_url_text}
                helpBlockText="ACS URL used to log in to your SSO provider."
              />
            </div>
            {this._emailNodeField()}
            <div className="span12 text-area-container">
              <TextArea
                inputClass="full-width"
                label="Certificate"
                name="certificate"
                placeholder="Paste in x.509 encoded certificate exactly as it's given by your identity provider, including the header and footer line."
                defaultValue={provider && provider.certificate}
                textareaStyle={{ height: 460 }}
              />
            </div>
          </div>
          <div className="form-actions action-buttons-aligner sso-configuration-action">
            <button
              type="submit"
              className="cpt-button style-primary size-small"
            >
              {ownerHasRestriction ? 'Update' : 'Save'}
              &nbsp;SSO configuration
            </button>
            <span className="remove-link">{this._deleteButton()}</span>
          </div>
        </Form>

        <Confirm
          id={SAVE_MODAL}
          title="Hey! Just so you know..."
          message="By saving this record, you're making changes to your authentication flow, and the way your users can access our site. Just in case something is wrong, we'll still allow you to login with your username and password until we see a successful SAML authentication request. After that, you'll only be able to authenticate via SAML until you change your record again."
          onConfirm={this._submitForm.bind(this)}
          confirm_text="Got it, save the record."
        />

        <Confirm
          id={DELETE_MODAL}
          title="Hey! Just so you know..."
          message="Warning! This will cause your SSO configuration to be completely removed. This will force your users to login using Statuspage credentials only. This action is permanent and cannot be undone."
          onConfirm={this._handleDelete.bind(this)}
          confirm_text="Permanently delete"
          cancel_text="Don't Delete"
        />
      </div>
    );
  }
}

export default SingleSignOnForm;
