import React from 'react';

import Notifications from 'lib/manage/notifications';

/**
 *
 * This component is used to conditionally render a message if the notification type is disabled.
 * There are a few different display options, and it decides which one to used based on the
 * passed in props.
 */
export default function DisabledFeatureMessage({
  enabledForPlan,
  anyExistingSubscribers,
  enabled,
  type,
  pageCode,
  allowablePlans,
}) {
  if (enabled) {
    return null;
  }

  const typeTitle = Notifications.SUBSCRIBER_TITLE_DISPLAY_TYPE_BY_TYPE[type];

  if (!enabledForPlan && !anyExistingSubscribers) {
    // the plan doesn't allow for this feature, and no subscribers exist
    const disableReason =
      type === 'sms'
        ? 'SMS subscribers receive status updates to their mobile device'
        : 'Webhook subscribers receive status updates to a URL they specify';

    let allowedPlanText;
    if (allowablePlans.length >= 3) {
      // preface the last plan with "or " and separate the rest by commas
      allowedPlanText = allowablePlans
        .slice(0, allowablePlans.length - 1)
        .join(', ');
      allowedPlanText += `, or ${allowablePlans[allowablePlans.length - 1]}`;
    } else {
      allowedPlanText = allowablePlans.join(' or ');
    }

    return (
      <div className="feature-disabled-card">
        <div className="header">{typeTitle} notifications</div>
        <div className="body">
          {disableReason}. Upgrade to the {allowedPlanText} plan to enable this
          feature.
        </div>
        <div className="card-actions">
          <a
            href={`/pages/${pageCode}/update-plan`}
            className="cpt-button style-primary"
          >
            Upgrade now
          </a>
        </div>
      </div>
    );
  } else if (!enabledForPlan) {
    // the plan doesn't allow for this feature, but subscribers do exist
    return (
      <div className="disable-notification-type-warning">
        <div className="header">
          Subscribers previously added won’t receive {typeTitle} notifications
          until you upgrade your plan.
        </div>
        <div className="body">
          <a href={`/pages/${pageCode}/update-plan`}>Upgrade now</a>
        </div>
      </div>
    );
  } else {
    // the feature is allowed for the plan, but the user has manually disabled the feature
    const settingsModalLink = (
      <a
        href="#modal-settings"
        data-toggle="modal"
        onClick={() =>
          analytics.track('Notifications Page - Settings Modal Clicked', {
            source: 'Notifications Page',
          })
        }
      >
        Reactivate now
      </a>
    );

    return (
      <div className="disable-notification-type-warning">
        <div className="header">
          {typeTitle} notifications are disabled. Please reactivate
          notifications.
        </div>
        <div className="body">{settingsModalLink}</div>
      </div>
    );
  }
}
