import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CptAlertBox from 'components/cpt_alert_box';
import CheckedList, { SelectionState } from 'components/checked_list';
import Notifications from 'lib/manage/notifications';
import PaginationControl from 'components/pagination_control';
import Pill from 'components/pill';
import PillGroup from 'components/pill_group';
import {
  SortableListHeader,
  SortableListHeaders,
} from 'components/sortable_list';
import ConfirmModal from 'components/confirm';

import { inflect } from 'inflection';
import EmptySpaceImg from '@static/images/lipstick/empty_spaces/capturing_users.svg';
import { Histogram } from './types';
import NotificationsSubscriberRow from './notifications_subscriber_row';
import SubscriberAction from './subscriber_action';
import SubscriberQuery from './subscriber_query';

/**
 * @constant {Array} The list of subscribers states of which the front-end is aware.
 */
const SUBSCRIBER_STATES = ['active', 'quarantined', 'unconfirmed'];

/**
 *  The interface to browse and search lists of email, sms, slack, teams and web subscribers.
 */
export default class NotificationsSubscriberTypeManager extends Component {
  static propTypes = {
    pageCode: PropTypes.string.isRequired, // The code of the page whose subscribers to list.
    limit: PropTypes.number.isRequired, // The number of subscribers to display per-page.
    type: PropTypes.oneOf(['email', 'sms', 'slack', 'webhook', 'teams'])
      .isRequired,
    allTimeHistogram: PropTypes.shape({
      email: Histogram,
      sms: Histogram,
      slack: Histogram,
      webhook: Histogram,
      teams: Histogram,
    }).isRequired,
    thisMonthHistogram: PropTypes.shape({
      email: Histogram,
      sms: Histogram,
      slack: Histogram,
      webhook: Histogram,
      teams: Histogram,
    }).isRequired,
    touch: PropTypes.func, // The function to call to indicate that the server should be re-queried.
    updatedAt: PropTypes.string.isRequired,
    enabledType: PropTypes.bool,
  };

  state = {
    subscriberState: 'active',
    sortField: 'primary',
    sortDirection: 'asc',
    total: 0,
    page: 0,
    selection: SelectionState.clear(),
    confirmationModal: null,
  };

  componentDidUpdate(_, oldState) {
    if (
      this.state.confirmationModal &&
      oldState.confirmationModal !== this.state.confirmationModal
    ) {
      $(`#modal-confirmation-${this.state.confirmationModal.props.id}`).modal();
    }
  }

  _handleClearSelection = (shouldTrack) => {
    if (shouldTrack) {
      analytics.track('Notifications Page - Clear Selection', {
        source: 'Notifications Page',
        state: this.state.subscriberState,
        type: this.props.type,
      });
    }
    this.setState({ selection: SelectionState.clear() });
  };

  _handleSelectAll = () => {
    analytics.track('Notifications Page - Select All', {
      source: 'Notifications Page',
      state: this.state.subscriberState,
      type: this.props.type,
    });
    this.setState({ selection: SelectionState.selectAll() });
  };

  _removeSubscriber = (e, subscriberCode, state) => {
    e.preventDefault();

    let subscriberCodes = this.state.selection.toJSON();
    if (Array.isArray(subscriberCode)) {
      subscriberCodes = subscriberCode;
    }

    const message = `Are you sure you want to remove ${
      subscriberCodes.length === 1 ? 'this subscriber?' : 'these subscribers?'
    }`;
    const params = {
      subscribers: subscriberCodes,
      type: this.props.type,
      state,
    };

    const confirmationModal = (
      <ConfirmModal
        id={`remove-subscriber-${this.state.subscriberState}`}
        confirm_text="Confirm removal"
        message={message}
        title="Confirm remove subscriber"
        confirmClass="style-status-critical"
        onConfirm={() => {
          analytics.track('Notifications Page - Subscriber Unsubscribed', {
            source: 'Notifications Page',
            state: this.state.subscriberState,
            type: this.props.type,
            count: subscriberCodes === 'all' ? -1 : subscriberCodes.length,
          });
          Subscriber.unsubscribeSubscribers(this.props.pageCode, params).then(
            () => {
              HRB.utils.notify('Unsubscribe succeeded.');
              this._handleClearSelection(false);
              this.props.touch();
              this.setState({ confirmationModal: null });
            },
            (error) => {
              HRB.utils.notify(error, { cssClass: 'error' });
            },
          );
        }}
        onCancel={() => this.setState({ confirmationModal: null })}
      />
    );

    this.setState({
      confirmationModal,
    });
  };

  _reactivateSubscriber = (e, subscriberCode) => {
    e.preventDefault();

    let subscriberCodes = this.state.selection.toJSON();
    if (Array.isArray(subscriberCode)) {
      subscriberCodes = subscriberCode;
    }

    const message = `Are you sure you want to reactivate ${
      subscriberCodes.length === 1 ? 'this subscriber?' : 'these subscribers?'
    }`;

    const confirmationModal = (
      <ConfirmModal
        id={`reactivate-subscriber-${this.state.subscriberState}`}
        confirm_text="Confirm reactivate"
        message={message}
        title="Confirm reactivate subscriber"
        onConfirm={() => {
          analytics.track('Notifications Page - Subscriber Reactivated', {
            source: 'Notifications Page',
            state: this.state.subscriberState,
            type: this.props.type,
            count: subscriberCodes === 'all' ? -1 : subscriberCodes.length,
          });
          Subscriber.reactivateSubscribers(this.props.pageCode, {
            subscribers: subscriberCodes,
          }).then(
            () => {
              HRB.utils.notify('Reactivate succeeded.');
              this._handleClearSelection(false);
              this.props.touch();
              this.setState({ confirmationModal: null });
            },
            () => {
              HRB.utils.notify('Reactivate failed.', { cssClass: 'error' });
            },
          );
        }}
        onCancel={() => this.setState({ confirmationModal: null })}
      />
    );

    this.setState({
      confirmationModal,
    });
  };

  _resendConfirmationSubscriber = (e, subscriberCode) => {
    e.preventDefault();

    let subscriberCodes = this.state.selection.toJSON();
    if (Array.isArray(subscriberCode)) {
      subscriberCodes = subscriberCode;
    }

    const message = `Are you sure you want to resend a confirmation message to ${
      subscriberCodes.length === 1 ? 'this subscriber?' : 'these subscribers?'
    }`;

    const successMessage = `The confirmation email was successfully sent to the ${inflect(
      'subscriber',
      subscriberCodes.length,
    )}`;

    const confirmationModal = (
      <ConfirmModal
        id={`resend-confirmation-subscriber-${this.state.subscriberState}`}
        confirm_text="Confirm resend"
        message={message}
        title="Confirm resend confirmation"
        onConfirm={() => {
          Subscriber.resendConfirmationSubscribers(
            this.props.pageCode,
            subscriberCodes,
          ).then(
            () => {
              analytics.track(
                'Notifications Page - Subscriber Confirmation Resent',
                {
                  source: 'Notifications Page',
                  state: this.state.subscriberState, // Should always be 'unconfirmed'.
                  type: this.props.type, // Should always be 'email'.
                  count:
                    subscriberCodes === 'all' ? -1 : subscriberCodes.length,
                },
              );
              HRB.utils.notify(successMessage);
              this._handleClearSelection(false);
              this.setState({ confirmationModal: null });
            },
            () => {
              HRB.utils.notify('Resend confirmation failed.', {
                cssClass: 'error',
              });
            },
          );
        }}
        onCancel={() => this.setState({ confirmationModal: null })}
      />
    );

    this.setState({
      confirmationModal,
    });
  };

  _changedSelectedState = (state) => {
    analytics.track('Notifications Page - Change Subscriber State Filter', {
      source: 'Notifications Page',
      state,
      type: this.props.type,
    });
    this.setState({
      subscriberState: state,
      page: 0,
      selection: SelectionState.clear(),
    });
  };

  _renderEmptySpaceForActiveSubscribers = () => (
    <div>
      <img
        src={EmptySpaceImg}
        alt={`No active ${
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ]
        } subscribers`}
      />
      <h5>
        No active{' '}
        {
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ]
        }{' '}
        subscribers
      </h5>
      <p>
        {Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
          this.props.type
        ] === 'webhook'
          ? 'Webhook subscriptions will appear here.'
          : Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
              this.props.type
            ] === 'teams'
          ? 'Microsoft Teams subscriptions will appear here.'
          : `Subscribers who have confirmed their ${
              Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
                this.props.type
              ]
            } subscription will appear here.`}
      </p>
    </div>
  );

  _renderEmptySpaceForQuarantinedSubscribers = () => (
    <div>
      <h5>
        No quarantined{' '}
        {
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ]
        }{' '}
        subscribers
      </h5>
      <p>
        {`Subscribers who provide an invalid
        ${
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ] === 'email'
            ? 'email address'
            : ''
        }
        ${
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ] === 'SMS'
            ? 'phone number'
            : ''
        }
        ${
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ] === 'webhook'
            ? 'webhook URL'
            : ''
        }
         will appear here.`}
      </p>
    </div>
  );

  _renderEmptySpaceForUnconfirmedSubscribers = () => (
    <div>
      <h5>
        No unconfirmed{' '}
        {
          Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
            this.props.type
          ]
        }{' '}
        subscribers
      </h5>
      <p>
        Subscribers who have not yet confirmed their{' '}
        {Notifications.SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE[
          this.props.type
        ] === 'SMS'
          ? 'phone number'
          : 'email'}{' '}
        subscription will appear here.
      </p>
    </div>
  );

  _emptyState = () =>
    this.props.enabledType ? (
      <div className="adg3-empty-space adg3-empty-space__notification">
        {this.state.subscriberState === 'active'
          ? this._renderEmptySpaceForActiveSubscribers()
          : null}
        {this.state.subscriberState === 'quarantined'
          ? this._renderEmptySpaceForQuarantinedSubscribers()
          : null}
        {this.state.subscriberState === 'unconfirmed'
          ? this._renderEmptySpaceForUnconfirmedSubscribers()
          : null}
      </div>
    ) : null;

  _getEmptyState = (otherStatesMessage, emptySubscriberType, loading) => {
    if (loading) {
      return null;
    }
    const subscriberTypeQualifier = emptySubscriberType
      ? ''
      : this.state.subscriberState;
    return this._emptyState();
  };

  renderTotalSubscibers = (
    allTimeTotal,
    pluralizedSubscribers,
    addedThisMonth,
  ) => {
    return (
      <div className="total-and-recent-subscriber-count">
        {allTimeTotal} total, {addedThisMonth} added this month.
      </div>
    );
  };

  render() {
    // Compute the relevant histogram data.
    const allTimeTotal =
      this.props.allTimeHistogram[this.props.type] &&
      this.props.allTimeHistogram[this.props.type].total;
    const addedThisMonth =
      this.props.thisMonthHistogram[this.props.type] &&
      this.props.thisMonthHistogram[this.props.type].total;

    // Fetch the total for pagination purposes for this subscriber state.
    const paginationTotal =
      this.props.allTimeHistogram[this.props.type] &&
      this.props.allTimeHistogram[this.props.type][this.state.subscriberState];

    const emptySubscriberType = allTimeTotal === 0;
    const emptySubscriberTypeInState = paginationTotal === 0;

    // The total label in the upper left of the tab pane.
    const pluralizedSubscribers = inflect('subscriber', allTimeTotal);

    let subscriberCountLabel = (
      <div className="total-and-recent-subscriber-count" />
    );
    if (allTimeTotal !== undefined && addedThisMonth !== undefined) {
      subscriberCountLabel = this.renderTotalSubscibers(
        allTimeTotal,
        pluralizedSubscribers,
        addedThisMonth,
      );
    }
    // Here are the state pills at the top right of the tab pane.
    // Not all types have all states; we should generate them dynamically.
    let activePillCountString = this.props.allTimeHistogram[this.props.type]
      ? `(${this.props.allTimeHistogram[this.props.type].active})`
      : '';
    let unconfirmedPillCountString = this.props.allTimeHistogram[
      this.props.type
    ]
      ? `(${this.props.allTimeHistogram[this.props.type].unconfirmed})`
      : '';
    let quarantinedPillCountString = this.props.allTimeHistogram[
      this.props.type
    ]
      ? `(${this.props.allTimeHistogram[this.props.type].quarantined})`
      : '';

    activePillCountString = activePillCountString.replace(/[\(\)]/gi, '');
    unconfirmedPillCountString = unconfirmedPillCountString.replace(
      /[\(\)]/gi,
      '',
    );
    quarantinedPillCountString = quarantinedPillCountString.replace(
      /[\(\)]/gi,
      '',
    );

    const quarantinedFilterState =
      this.props.type === 'email' ||
      this.props.type === 'sms' ||
      this.props.type === 'webhook' ? (
        <Pill className="red notification-status" value="quarantined">
          Quarantined{' '}
          <span className="notifications-amount">
            {quarantinedPillCountString}
          </span>
        </Pill>
      ) : null;

    const unconfirmedFilterState =
      this.props.type === 'email' || this.props.type === 'sms' ? (
        <Pill className="red notification-status" value="unconfirmed">
          Unconfirmed{' '}
          <span className="notifications-amount">
            {unconfirmedPillCountString}
          </span>
        </Pill>
      ) : null;
    // Column headers.
    // Quarantine is only shown in the "quarantine" state.
    const quarantineHeader =
      this.state.subscriberState === 'quarantined' ? (
        <SortableListHeader
          className="subscriber-content-column quarantined-at quarantined"
          field="quarantined_at"
          key="quarantined_at"
          tooltip="When a subscriber has not been reactivated in 90 days of quarantine, they will be automatically unsubscribed."
        >
          Date quarantined
          <span className="tooltip-base">?</span>
        </SortableListHeader>
      ) : null;

    // Links to other subscriber states that are not empty.
    const otherStatesLinks = emptySubscriberTypeInState
      ? SUBSCRIBER_STATES.filter(
          (ss) =>
            ss !== this.state.subscriberState &&
            this.props.allTimeHistogram[this.props.type] &&
            this.props.allTimeHistogram[this.props.type][ss],
        ).map((ss) => {
          return (
            <button
              type="button"
              className="other-subscriber-states-btn link-like"
              key={ss}
              onClick={() => this._changedSelectedState(ss)}
            >
              {ss}
            </button>
          );
        })
      : [];
    const otherStatesMessage = otherStatesLinks.length ? (
      <span>
        Check {otherStatesLinks.reduce((prev, curr) => [prev, ' or ', curr])}{' '}
        subscribers.
      </span>
    ) : null;

    // These are actions suitable for the header.
    const selectedCount = this.state.selection.allSelected
      ? paginationTotal
      : this.state.selection.selectedSet.size;
    const resendConfirmationTextStyle = 'Resend confirmation';
    const actions = [
      <SubscriberAction
        className="remove"
        key="remove"
        onClick={this._removeSubscriber}
        subscriberState={this.state.subscriberState}
      >
        Remove
      </SubscriberAction>,
    ];
    if (this.state.subscriberState === 'quarantined') {
      actions.push(
        <SubscriberAction
          className="reactivate"
          key="reactivate"
          onClick={this._reactivateSubscriber}
          subscriberState={this.state.subscriberState}
        >
          Reactivate
        </SubscriberAction>,
      );
    }
    // TODO: (SIP-588) must be able to resend confirmation to unconfirmed sms subscribers
    if (
      this.state.subscriberState === 'unconfirmed' &&
      this.props.type === 'email'
    ) {
      actions.push(
        <SubscriberAction
          className="resend-confirmation"
          key="resend-confirmation"
          onClick={this._resendConfirmationSubscriber}
          subscriberState={this.state.subscriberState}
        >
          {resendConfirmationTextStyle}
        </SubscriberAction>,
      );
    }
    return (
      <div
        className={`notifications-subscriber-type-manager ${this.props.type}`}
      >
        {this.state.confirmationModal}
        {/* The controls and labels above the list. */}
        <div className="pane-heading">
          {subscriberCountLabel}
          <PillGroup
            className="pull-right colored-filters"
            onChange={this._changedSelectedState}
            value={this.state.subscriberState}
          >
            <Pill className="green notification-status" value="active">
              Active{' '}
              <span className="notifications-amount">
                {activePillCountString}
              </span>
            </Pill>
            {quarantinedFilterState}
            {unconfirmedFilterState}
          </PillGroup>
        </div>
        {/* This ensures only the list is re-rendered when fetching page of subscribers. */}
        {/* It also ensures we only do this when necessary, as props change. */}
        <SubscriberQuery
          pageCode={this.props.pageCode}
          limit={this.props.limit}
          page={this.state.page}
          sortField={this.state.sortField}
          sortDirection={this.state.sortDirection}
          subscriberType={this.props.type}
          subscriberState={this.state.subscriberState}
          updatedAt={this.props.updatedAt}
        >
          {(subscribers, loading, error) => {
            const emptyListState =
              subscribers.length !== 0
                ? null
                : this._getEmptyState(
                    otherStatesMessage,
                    emptySubscriberType,
                    loading,
                  );

            const errorState = error ? (
              <CptAlertBox
                type="error"
                header="An error occurred while trying to fulfill your request. Please refresh the page and try again in a few minutes."
                body={error}
              />
            ) : null;

            // Construct the list contents.
            const adg3SubscriberRows = () => {
              return loading
                ? []
                : subscribers.map((subscriber) => (
                    <NotificationsSubscriberRow
                      subscriber={subscriber}
                      pageCode={this.props.pageCode}
                      key={subscriber.id}
                      state={this.state.subscriberState}
                      href={`/pages/${this.props.pageCode}/subscribers/${subscriber.id}`}
                    >
                      {this.state.selection.isClear() ? actions : null}
                    </NotificationsSubscriberRow>
                  ));
            };

            const subscriberIdentificationHeader =
              this.props.type === 'slack' ? (
                <>
                  <SortableListHeader className="subscriber-content-column workspace-name unsortable">
                    <span>Workspace</span>
                  </SortableListHeader>
                  <SortableListHeader className="subscriber-content-column channel-name unsortable">
                    <span>Channel subscribed</span>
                  </SortableListHeader>
                </>
              ) : (
                <SortableListHeader
                  className="subscriber-content-column subscriber-title"
                  field="primary"
                  key="primary"
                >
                  <span>Subscribers</span>
                </SortableListHeader>
              );

            const dateAddedHeader = (
              <SortableListHeader
                className={classnames(
                  'subscriber-content-column',
                  'created-at',
                  this.state.subscriberState,
                  this.props.type === 'slack' ? 'unsortable' : null,
                )}
                field="created_at"
                key="created_at"
              >
                <span>Date added</span>
              </SortableListHeader>
            );

            const actionsHeader = (
              <SortableListHeader
                className={classnames(
                  'subscriber-content-column',
                  'actions',
                  this.state.subscriberState,
                  this.state.selection.isClear() ? null : 'actions',
                )}
                key="actions"
              >
                {this.state.selection.isClear() ? 'Actions' : actions}
              </SortableListHeader>
            );

            // And the headers.
            let headers = null;
            // If nothing is selected, the table header displays as column headers.
            if (this.state.selection.isClear()) {
              headers = [
                subscriberIdentificationHeader,
                dateAddedHeader,
                quarantineHeader,
                actionsHeader,
              ];
            } else {
              // If something is selected, the table header displays information and actions available
              // for the selected subscribers.
              //
              // 'All Are Selected' may be encoded in two different ways:
              // 1) by the symbolic selection state "all"
              // 2) by a list of <page size> selected ids when <page size> === paginationTotal.
              // allChecked is true for both of these cases, otherwise false.
              const allChecked = selectedCount === paginationTotal;
              const clearSelectionText = '(Select none)';
              const selectAllHeader = allChecked ? (
                <div className="select-all-prompt" key="select-all-prompt">
                  All {selectedCount} subscribers are selected.{' '}
                  <button
                    className="link-like selected-subscribers-action"
                    onClick={() => this._handleClearSelection(true)}
                  >
                    {clearSelectionText}
                  </button>
                </div>
              ) : (
                <div className="select-all-prompt" key="select-all-prompt">
                  <span> {selectedCount} selected. </span>
                  <button
                    className="link-like selected-subscribers-action"
                    onClick={this._handleSelectAll}
                  >
                    (Select all)
                  </button>
                </div>
              );
              headers = [
                selectAllHeader,
                <div className="actions" key="bulk-actions">
                  {actions}
                </div>,
              ];
            }

            // Render the list itself, specifying selection state, headers, and content.
            return (
              <div>
                <CheckedList
                  selection={this.state.selection}
                  onSelect={(selection) => {
                    analytics.track(
                      'Notifications Page - Selected Subscribers',
                      {
                        source: 'Notifications Page',
                        count: selection.getCount(),
                        state: this.state.subscriberState,
                        type: this.props.type,
                      },
                    );
                    this.setState({ selection });
                  }}
                  total={paginationTotal || 0}
                  limit={this.props.limit}
                  loading={loading}
                  error={errorState}
                  empty={emptyListState}
                  enabledType={this.props.enabledType}
                  headers={
                    <SortableListHeaders
                      field={this.state.sortField}
                      direction={this.state.sortDirection}
                      onClick={(sortField, sortDirection) => {
                        analytics.track(
                          'Notifications Page - User Clicked Header',
                          {
                            source: 'Notifications Page',
                            state: this.state.subscriberState,
                            type: this.props.type,
                            column: sortField,
                            direction: sortDirection,
                          },
                        );
                        this.setState({
                          sortField,
                          sortDirection,
                          page: 0,
                          selection: SelectionState.clear(),
                        });
                      }}
                    >
                      {headers}
                    </SortableListHeaders>
                  }
                >
                  {adg3SubscriberRows()}
                </CheckedList>
              </div>
            );
          }}
        </SubscriberQuery>

        {/* Finally, render the pagination control iff there are multiple pages. */}

        {paginationTotal === undefined ||
        paginationTotal <= this.props.limit ? null : (
          <PaginationControl
            page={this.state.page + 1}
            limit={this.props.limit}
            total={paginationTotal}
            onChange={(page) => {
              analytics.track('Notifications Page - User Navigated To Page', {
                source: 'Notifications Page',
                state: this.state.subscriberState,
                type: this.props.type,
                page_number: page,
              });
              this.setState({ page: page - 1 });
            }}
          />
        )}
      </div>
    );
  }
}
