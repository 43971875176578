import React from 'react';
import PropTypes from 'prop-types';
import Subscriber from 'resources/subscriber';

class ResendConfirmationLink extends React.Component {
  _success() {
    HRB.utils.notify(`Sent confirmation link to: ${this.props.email}.`);
  }

  _failure() {
    HRB.utils.notify('Unable to send confirmation link. Try again soon.');
  }

  _postRoute() {
    return `/subscriptions/${this.props.subscriberCode}/resend_confirmation`;
  }

  _resendSubscriberConfirmation() {
    if (this.props.destination === 'manage') {
      Subscriber.resendConfirmation(
        this.props.pageCode,
        this.props.subscriberCode,
      ).then(this._success.bind(this), this._failure);
    } else {
      $.post(this._postRoute())
        .done(this._success.bind(this))
        .fail(this._failure);
    }
    return false;
  }

  render() {
    return (
      <a
        href="#"
        onClick={() => this._resendSubscriberConfirmation()}
        ref="anchor"
      >
        {this.props.text}
      </a>
    );
  }
}

ResendConfirmationLink.propTypes = {
  pageCode: PropTypes.string.isRequired, // Page of the subscriber
  subscriberCode: PropTypes.string.isRequired, // The subscriber code itself
  text: PropTypes.string.isRequired, // The text to display with the link
  email: PropTypes.string.isRequired, // The email to display in the success prompt
  destination: PropTypes.oneOf(['status', 'manage']).isRequired, // There are two API endpoints to simplify calling from admin and subscriber contexts.
};

export default ResendConfirmationLink;
