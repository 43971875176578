import * as React from 'react';
import { StatusEmbedChildProps } from './types';
import { observer } from 'mobx-react';
import CopyIcon from '@atlaskit/icon/glyph/copy';

@observer
export default class CodeModal extends React.Component<
  StatusEmbedChildProps,
  {}
> {
  script = `<script src="${this.props.store.statusUrl}/embed/script.js"></script>`;

  store = this.props.store;

  closeModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    $('#embed-code-modal').modal(); // this is needed because our modals are a JQuery plugin (sad face)
  };

  render() {
    return (
      <div className="modal hide fade embed-code-modal" id="embed-code-modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="title">
              <span>Here's your status embed code</span>
              <span id="copy-icon">
                <a onClick={this.store.copyCode}>
                  <CopyIcon label="Copy status embed code" />
                </a>
              </span>
              <span id="code-copied" className="faded">
                Code copied
              </span>
            </div>
          </div>
          <div className="modal-body">
            Paste this code snippet as the last item in the page body. Test that
            your status embed is deployed by running the command{' '}
            <span className="code">statusEmbedTest()</span> from the dev tools
            console in your browser.
            <div className="embed-code-block" id="embed-code-snippet">
              {this.script}
            </div>
          </div>
          <div className="modal-footer">
            <a
              className="confirm-btn cpt-button style-primary close hide"
              data-dismiss="modal"
            >
              OK
            </a>
          </div>
        </div>
      </div>
    );
  }
}
