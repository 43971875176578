import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loading from 'components/loading';
import Bloodhound from 'typeahead.js/dist/bloodhound';
import 'typeahead.js/dist/typeahead.jquery';

// This value is not arbitrary. The goal here is to prevent any text box query
// with a length less than 3 from getting sent to the server. This is because we
// have an ngram setting on all of our Elasticsearch indices (specified in
// `app/indexes/base_index.rb`) that stipulates the same minimum "gram" length.
//
// Essentially queries <= length 3 will be guaranteed never to produce results
// so it doesn't make sense to actually submit them.
//
// More information can be found here:
//   https://www.elastic.co/guide/en/elasticsearch/reference/5.4/analysis-ngram-tokenizer.html
//
const MIN_SEARCH_LENGTH = 3;

class ESSearchField extends React.Component {
  /**
   * @param props See PropTypes for optional and required properties.
   */
  constructor(props) {
    super(props);
    this.searchSource = this._createSearchSource(props.queryUrl);
    this.state = {
      error: false,
      inputActive: false,
      menuOpen: false,
      commandKeyPressed: false,
      showDeleteButton: false,
    };
  }

  // Create search source based on url
  _createSearchSource(url) {
    return new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url,
        wildcard: '%QUERY',
        transport: this._transport,
      },
    });
  }

  _transport = (options, onSuccess, onError) => {
    const done = (data, textStatus, request) => {
      if (this.state.error) {
        this.setState({ error: false }, onSuccess.bind(this, data));
      } else {
        onSuccess(data);
      }
    };

    const fail = (request, textStatus, errorThrown) => {
      this.setState({ error: true }, onError.bind(this, errorThrown));
    };

    this._request(options, done, fail);
  };

  _request(options, done, fail) {
    this.props
      .loadResults({ url: options.url })
      .then(done)
      .catch(fail);
  }

  _getTypeahead() {
    return $(ReactDOM.findDOMNode(this)).find('#es-search-field');
  }

  _clearField = () => {
    const $typeahead = this._getTypeahead();
    $typeahead.typeahead('val', '');
    this.setState({ showDeleteButton: false });
  };

  // HTML string for a loading screen
  _loadingScreen = () => ReactDOMServer.renderToString(<Loading loading />);

  _noResultOrError = () =>
    this.state.error ? this._onError() : this._noResults();

  // HTML string for when no results are found
  _noResults = () => ReactDOMServer.renderToString(<NoResults />);

  // HTML string for errors
  _onError = () => ReactDOMServer.renderToString(<ErrorState />);

  // Typeahead "open" event is not reliable
  // Check the num characters someone has typed in and
  // add "open" class when min search length has been met
  _checkInputField = (event) => {
    const showDeleteButton = event.target.value.length > 0;
    const menuOpen = event.target.value.length >= MIN_SEARCH_LENGTH;
    this.setState({ showDeleteButton, menuOpen });
  };

  componentDidMount() {
    const $typeahead = this._getTypeahead();

    $typeahead
      .typeahead(
        {
          minLength: MIN_SEARCH_LENGTH,
          highlight: false,
          classNames: {
            hint: 'es-hint',
            highlight: 'es-highlight',
            menu: 'es-menu',
            cursor: 'es-cursor',
          },
        },
        {
          // HACK bug in typeahead causes limit rendering issues, PR opened but maintainer unresponsive
          limit: 1000,

          name: this.props.pluralizedResultName,
          source: this.searchSource,
          display: (suggestion) => {
            return $typeahead.typeahead('val');
          },
          templates: {
            suggestion: this.props.template,
            pending: this._loadingScreen(),
            notFound: this._noResultOrError,
          },
        },
      )
      .on('typeahead:select', (event, suggestion) => {
        const url = this.props.mapResultToUiUrl(suggestion);
        if (this.state.commandKeyPressed) {
          window.open(url, '_blank');
          $('.es-menu').show();
        } else {
          $('input').trigger('blur');
          if (window.ContainerAPI) {
            window.ContainerAPI.then((api) => {
              api.navigate(url.pathname, () => {
                window.location = url;
              });
            });
          } else {
            window.location = url;
          }
        }
      })

      // Style typeahead when active
      .on('typeahead:active', () => {
        this.setState({ inputActive: true });
      })
      .on('typeahead:idle', () => {
        this.setState({ inputActive: false });
      })
      .on('typeahead:close', () => {
        if (this.state.commandKeyPressed) {
          $('.es-menu').show();
        }
        this.setState({ menuOpen: false });
      });

    // Forward slash search focus
    $(window).keyup((event) => {
      if (this._getCharCode(event) === 191 && event.target.type !== 'text') {
        $('#es-search-field').focus();
      }
    });
  }

  _captureKeyDown = (event) => {
    if (this._isCommandClick(event)) {
      this.setState({ commandKeyPressed: true });
    }
  };

  _captureKeyUp = (event) => {
    if (this._isCommandClick(event)) {
      this.setState({ commandKeyPressed: false });
    }
  };

  _isCommandClick(event) {
    const code = this._getCharCode(event);
    return code === 91 || code === 17;
  }

  _loseFocus = (event) => {
    $('.es-menu').hide();
    $('.no-results-area').hide();
    this.setState({ menuOpen: false });
  };

  // Cross browser supported key code getter
  _getCharCode = (e) => (typeof e.which === 'number' ? e.which : e.keyCode);

  render() {
    const { inputActive, menuOpen, showDeleteButton } = this.state;
    const activeClass = inputActive ? 'active' : '';
    const openClass = menuOpen ? 'open' : '';

    const magClass = cx('mag-glass fa fa-search', activeClass);
    const inputClass = cx(
      'typeahead form-control full-width',
      activeClass,
      openClass,
    );
    const searchClass = cx('search-bar', {
      'search-bar-with-query': showDeleteButton,
      active: inputActive,
    });

    const iconControl = showDeleteButton ? (
      <div className={'search-icon-position'} onClick={this._clearField}>
        <i className="fa fa-times" />
      </div>
    ) : (
      <div className={'search-icon-position'}>
        <i className={magClass} />
      </div>
    );

    return (
      <div
        className={searchClass}
        style={{ position: 'relative' }}
        onKeyDown={this._captureKeyDown}
        onKeyUp={this._captureKeyUp}
        onBlur={this._loseFocus}
      >
        <input
          type="text"
          ref="input"
          id="es-search-field"
          className={inputClass}
          onKeyUp={this._checkInputField}
          placeholder="Search"
        />
        {iconControl}
      </div>
    );
  }
}

ESSearchField.propTypes = {
  // The URL that will be hit to load search results. "%QUERY" will be replaced with the URL-escaped query string.
  // E.g. "/v1/pages/73nfvx10lnbd/subscribers.json?q=%QUERY&limit=10"
  queryUrl: PropTypes.string.isRequired,

  // Pluralized lowercased name of the thing being searched for, used to apply CSS class for styling. E.g. "subscribers"
  pluralizedResultName: PropTypes.string.isRequired,

  // Takes in an object of arguments and returns a promise which resolves to search results. Required options are:
  // * .url: specifies the URL which this function should hit to load search results.
  loadResults: PropTypes.func.isRequired,

  // Takes in a search result and returns the URL for the UI view of that result
  mapResultToUiUrl: PropTypes.func.isRequired,

  // Takes in a search result and returns the HTML string for how a search result should be rendered
  template: PropTypes.func.isRequired,
};

function ErrorState() {
  return (
    <div className="no-results-area">
      <p>👹</p>
      <p className="error-text">
        There was an error completing your search. Please try again later.
      </p>
    </div>
  );
}

function NoResults() {
  return (
    <div className="no-results-area">
      <p>
        No results found. Try keeping your search phrase more generic to get
        more results.
      </p>
    </div>
  );
}

export default ESSearchField;
