import Base from 'resources/base';
import request from 'lib/request';

export default class PageAccess extends Base {
  constructor(attributes) {
    super(attributes);
  }

  static searchRoute(options) {
    if (!options.page_id) throw new Error('You must provide a page_id');
    return Routes.audience_specific_search_page_path(
      { id: options.page_id },
      { domain: null, subdomain: null },
    );
  }

  static search(queryOptions) {
    let url;
    if (queryOptions.url) {
      url = queryOptions.url;
    } else {
      url = PageAccess.searchRoute({ page_id: queryOptions.page_id });
    }

    return request
      .get({
        url: url,
        data: { q: queryOptions.query },
      })
      .then(response => {
        const results = response;
        return results.map(result => {
          let pa = new PageAccess(result);
          return pa;
        });
      });
  }

  isUser() {
    return !!this.email;
  }
}
