import * as React from 'react';
import { observer } from 'mobx-react';
import { StatusEmbedChildProps } from './types';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';

@observer
export default class StatusUpdate extends React.Component<
  StatusEmbedChildProps,
  {}
> {
  store = this.props.store;

  inOnlineState = () => {
    return this.store.onlineStatus && !this.store.trialPage;
  };

  render() {
    return this.renderStatusMessage();
  }

  renderStatusMessage() {
    return (
      <div
        className={`status-message ${this.inOnlineState() ? '' : 'offline'}`}
      >
        <span
          className={`icon-container ${this.inOnlineState() ? '' : 'offline'}`}
        >
          {this.renderIcon()}
        </span>
        <span className="message-container">{this.renderText()}</span>
      </div>
    );
  }

  renderIcon() {
    if (this.inOnlineState()) {
      return <CheckCircleIcon label="check" />;
    }

    return <InfoIcon label="info" />;
  }

  renderText() {
    if (this.store.trialPage) {
      return (
        <p>
          It looks like you don't have a paid subscription yet. You can still
          test your status embed by running the test command from the dev tools
          console. Once you activate your subscription and copy/paste the code
          snippet into your website, users will start seeing the status embed
          updates live.
        </p>
      );
    } else if (this.store.onlineStatus) {
      return (
        <p>
          Your status embed is currently online. Make changes on this page and
          click <b>Update</b> to update your code automatically - no need to
          manually copy/paste again.
        </p>
      );
    } else {
      return (
        <p>
          Your status embed is not online. Copy/paste the code snippet into your
          web page to finish deploying.
        </p>
      );
    }
  }
}
