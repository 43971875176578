import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import ControlGroup from './control_group';
import { label } from './input_commons';

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = 'TextArea';
  }

  render() {
    return (
      <ControlGroup
        className={this.props.className}
        style={this.props.style}
        label={this.props.label}
        helpBlockText={this.props.helpBlockText}
      >
        <textarea
          className={this.props.textareaClass}
          name={this.props.name}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          onChange={this.props.onChange}
          required={this.props.required}
          placeholder={this.props.placeholder}
          style={_.merge({ width: '100%' }, this.props.textareaStyle)}
        />
      </ControlGroup>
    );
  }
}
TextArea.defaultProps = {
  required: false,
  placeholder: null,
};

export default TextArea;
