import React from 'react';
import ProgressBar, { SuccessProgressBar } from '@atlaskit/progress-bar';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';

import {
  ProgressTextWrapper,
  ProgressMessage,
  ProgressPercentComplete,
  ProgressCompleteWrapper,
  ProgressCompleteMainContent,
  ProgressCompleteCheckContainer,
} from './styles';

interface AudienceCsvProgressBarProps {
  message: string;
  proportionComplete: number;
  isIndeterminate?: boolean;
}

export function AudienceCsvProgressBar({
  message,
  proportionComplete,
  isIndeterminate,
}: AudienceCsvProgressBarProps) {
  if (isIndeterminate) {
    return (
      <>
        <ProgressTextWrapper>
          <ProgressMessage>{message}</ProgressMessage>
        </ProgressTextWrapper>
        <ProgressBar isIndeterminate={isIndeterminate} />
      </>
    );
  } else {
    const displayPercent = Math.round(proportionComplete * 100);
    if (displayPercent < 100) {
      return (
        <>
          <ProgressTextWrapper>
            <ProgressMessage>{message}</ProgressMessage>
            <ProgressPercentComplete>{displayPercent}%</ProgressPercentComplete>
          </ProgressTextWrapper>
          <ProgressBar value={proportionComplete} />
        </>
      );
    } else {
      return (
        <ProgressCompleteWrapper>
          <ProgressCompleteMainContent>
            <ProgressTextWrapper>
              <ProgressMessage>{message}</ProgressMessage>
              <ProgressPercentComplete>Complete</ProgressPercentComplete>
            </ProgressTextWrapper>
            <SuccessProgressBar value={1} />
          </ProgressCompleteMainContent>
          <ProgressCompleteCheckContainer>
            <CheckCircleIcon label="check" />
          </ProgressCompleteCheckContainer>
        </ProgressCompleteWrapper>
      );
    }
  }
}
