import * as React from 'react';
import './styles.scss';

export interface PriceExplanationProps {
  invisible: boolean;
}

export default function PriceExplanation(props: PriceExplanationProps) {
  return (
    <div className={props.invisible ? 'invisible' : 'price-explanation-group'}>
      <div className="description">
        <h4>Volume-based pricing</h4>
        <p>
          Audience-specific pages use pricing tiers that have different amounts
          of page users, page groups, team members, and metrics.
        </p>

        <p>
          The price scales incrementally with the pricing tier selected as you
          increase your user needs.
        </p>
        <table className="pricing-table">
          <tbody>
            <tr>
              <th>Pricing tier</th>
              <th>Monthly price increase per tier</th>
            </tr>
            <tr>
              <td>1 (base price)</td>
              <td>$300</td>
            </tr>
            <tr>
              <td>2-10</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>11-30</td>
              <td>$100</td>
            </tr>
            <tr>
              <td>31+</td>
              <td>$50</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
