import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { StyledWrapper } from './styles';
import { Status, ImportStatus } from './types';
import AudienceImportStatusContainer from './import_status_container';
import ExportStatusContainer from './export_status_container';
import SectionMessageContainer from './section_message_container';
import { ImportUsersForm } from '../../../components/manage/audience/import_users_form';
import { Urls } from '../types';

export interface AudienceCsvStatusContainerProps {
  status: Status | null;
  setStatus: Dispatch<SetStateAction<Status | null>>;
  failedAction: string | null;
  setFailedAction: Dispatch<SetStateAction<string | null>>;
  urls: Urls;
  trialPage: boolean;
}

// The current id we're using for polling. If it's undefined, that should mean
// we aren't currently polling
let pollingIntervalId: number | undefined;
const pollingIntervalLengthMs = 3000;

export default function AudienceCsvStatusContainer({
  status,
  setStatus,
  failedAction,
  setFailedAction,
  urls,
  trialPage,
}: AudienceCsvStatusContainerProps) {
  const [pollingError, setPollingError] = useState(false);

  // Anything that needs to happen when `status` changes
  useEffect(() => {
    if (status == null || pollingError || !status.poll_for_updates) {
      // Stop polling
      clearInterval(pollingIntervalId);
      pollingIntervalId = undefined;
      return;
    }

    pollingIntervalId = window.setInterval(
      pollForUpdates,
      pollingIntervalLengthMs,
    );

    return function() {
      clearInterval(pollingIntervalId);
    };
  }, [status]);

  function pollForUpdates() {
    if (status == null) return;

    $.ajax({
      method: 'POST',
      url: status.urls.status,
      success: function(data: Status) {
        if (
          data.csv_display_mode === 'import' &&
          data.import_status &&
          ['complete', 'import_error'].includes(data.import_status)
        ) {
          // refresh the static lists below
          window.location.reload();
        } else {
          setStatus(data);
        }
      },
      error: function() {
        setPollingError(true);
      },
    });
  }

  function PollingErrorMesage() {
    let pollingErrorMessage = `Your ${
      status!.csv_display_mode
    } is taking longer than expected, but it’s still in progress. Return to this page later to see when your ${
      status!.csv_display_mode
    } is complete.`;
    return (
      <SectionMessageContainer
        appearance="warning"
        message={pollingErrorMessage}
      />
    );
  }

  function renderErrorContent() {
    if (pollingError) {
      return <PollingErrorMesage />;
    } else if (failedAction) {
      const message =
        failedAction === 'import'
          ? 'There was an error processing your import. Re-upload your file and try to import again.'
          : 'There was an error processing your export. Please try again later.';

      return <SectionMessageContainer appearance="error" message={message} />;
    }
  }

  function renderStatusContent() {
    const content = renderErrorContent();
    if (content) {
      return <StyledWrapper>{content}</StyledWrapper>;
    }
    if (status == null) return;

    if (status.csv_display_mode == 'import') {
      return (
        <StyledWrapper id="audience-import-status">
          <AudienceImportStatusContainer
            status={status as ImportStatus}
            downloadTemplateUrl={urls.downloadTemplate}
          />
        </StyledWrapper>
      );
    } else if (status.csv_display_mode == 'export') {
      return (
        <StyledWrapper id="audience-export-status">
          <ExportStatusContainer
            status={status}
            hideCsvStatusUrl={urls.hideCsvStatus}
          />
        </StyledWrapper>
      );
    }
  }

  return (
    <>
      {renderStatusContent()}
      <ImportUsersForm
        processImportUrl={urls.processImport}
        downloadTemplateUrl={urls.downloadTemplate}
        setImportStatus={setStatus}
        setImportFailure={() => setFailedAction('import')}
        trialPage={trialPage}
      />
    </>
  );
}
