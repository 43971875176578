import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Pagination from '@atlaskit/pagination';

export default class PaginationControl extends Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      pages: Array.from(Array(Math.ceil(props.total / props.limit)).keys()).map(
        i => ++i,
      ),
    };
  }

  handleChange = (e, newPage) => {
    this.props.onChange(newPage);
  };

  render() {
    return (
      <div className="pagination-container">
        <Pagination
          defaultSelectedIndex={0}
          max={7}
          pages={this.state.pages}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
