import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ESSearchField from 'components/manage/es_search_field';
import styles from './styles.scss';

import Subscriber from 'resources/subscriber';

class SubscribersSearchField extends React.Component {
  // Get HTML string for how a typeahead item should be rendered
  _template(suggestion) {
    const view = <SubscriberTypeaheadView suggestion={suggestion} />;
    return ReactDOMServer.renderToString(view);
  }

  render() {
    const searchRoute = ApiRoutes.page_subscribers_url({
      pageCode: this.props.page.id,
    });
    const mapResultToUiRoute = suggestion =>
      new URL(
        Routes.page_subscriber_url(this.props.page, suggestion.id, {
          domain: null,
          subdomain: null,
        }),
      );
    return (
      <div className={`subscriber-search-field-wrapper ${styles.subscriberSearchFieldWrapper}`}>
        <ESSearchField
          queryUrl={`${searchRoute}?limit=10&state=all&q=%QUERY`}
          pluralizedResultName={'subscribers'}
          loadResults={Subscriber.search}
          mapResultToUiUrl={mapResultToUiRoute}
          template={this._template.bind(this)}
        />
      </div>
    );
  }
}

class SubscriberTypeaheadView extends React.Component {
  _determineType(suggestion) {
    if (suggestion.hasOwnProperty('endpoint')) {
      return {
        iconClass: 'fa-share',
        matchValue: suggestion.endpoint,
      };
    } else if (suggestion.hasOwnProperty('email')) {
      return {
        iconClass: 'fa-envelope',
        matchValue: suggestion.email,
      };
    } else if (suggestion.hasOwnProperty('phone_number')) {
      return {
        iconClass: 'fa-phone',
        matchValue: suggestion.display_phone_number,
      };
    }
  }

  render() {
    const suggestion = this.props.suggestion;
    const subscriberData = this._determineType(suggestion);

    let itemClasses = 'item search-result es-results';
    if (suggestion.quarantined_at) {
      itemClasses = itemClasses + ' es-result-quarantined-subscriber';
    }

    return (
      <div className={itemClasses}>
        <div className="icon-container">
          <i className={`fa ${subscriberData.iconClass}`} />
        </div>
        <div className="result-text-container">{subscriberData.matchValue}</div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default SubscribersSearchField;
