import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TriStateCheckbox extends Component {
  componentDidMount() {
    this._updateDOM();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this._updateDOM();
    }
  }

  _updateDOM() {
    switch (this.props.mode) {
      case 'indeterminate':
        this._checkbox.indeterminate = true;
        this._checkbox.checked = false;
        break;
      case 'checked':
        this._checkbox.indeterminate = false;
        this._checkbox.checked = true;
        break;
      case 'unchecked':
      case 'disabled':
      default:
        this._checkbox.indeterminate = false;
        this._checkbox.checked = false;
        break;
    }
  }

  render() {
    return (
      <input
        className={this.props.className}
        type="checkbox"
        ref={checkbox => {
          this._checkbox = checkbox;
        }}
        onChange={this.props.onChange}
        disabled={this.props.mode === 'disabled'}
      />
    );
  }
}

TriStateCheckbox.defaultProps = {
  onChange: () => {},
  className: '',
};

TriStateCheckbox.propTypes = {
  mode: PropTypes.oneOf(['checked', 'unchecked', 'indeterminate', 'disabled'])
    .isRequired,
  onChange: PropTypes.func, // A callback when the user clicks the checkbox.
  className: PropTypes.string, // The className is passed on to the wrapping element.
};
