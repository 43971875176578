import * as React from 'react';
import { Page } from 'resources/types';
import { Subscriber } from './types';
import Confirm from 'components/confirm';
import notify, { Method } from 'utils/notify';

interface ConfirmationModalProps {
  page: Page;
  subscriber: Subscriber;
}

const UnsubscribeConfirmationModal = ({
  page,
  subscriber,
}: ConfirmationModalProps) => (
  <Confirm
    id="confirmationModalId"
    title="Unsubscribe"
    onConfirm={() => onConfirmUnsubscribe(page, subscriber)}
    confirm_text="Unsubscribe from updates"
    confirmClass="style-warning"
  >
    <div style={{ padding: '1em' }}>
      Are you sure you want to unsubscribe from all <strong>{page.name}</strong>{' '}
      status updates?
    </div>
  </Confirm>
);

const onConfirmUnsubscribe = (page: Page, subscriber: Subscriber): void => {
  $.ajax({
    url: `/subscriptions/${subscriber.id}.json`,
    type: 'DELETE',
    dataType: 'json',

    complete: (response) => {
      notify('You have been successfully unsubscribed.', {
        method: Method.DEFERRED,
      });
      window.location.href = window.location.origin;
    },
    error: (error) => {
      notify(
        'You could not be unsubscribed. Contact support if this problem persists.',
        { method: Method.SHOW },
      );
      console.error('Failed to unsubscribe subscriber.', error);
    },
  });
};

export default UnsubscribeConfirmationModal;
