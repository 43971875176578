import * as React from 'react';
import './styles.scss';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import Lozenge from '@atlaskit/lozenge';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import SelectInput from '../../components/select_input';
import PriceExplanation from './price-explanation';
import Tooltip from '../../components/tooltip';
import UpdatePaymentMethod from '../../lib/update_payment_method';
import notify, { Color, Method } from 'utils/notify';
import Spinner from '@atlaskit/spinner';
import cx from 'classnames';

let selectStyles = {
  control: (base: any) => ({
    ...base,
    height: '40px',
    'min-height': '40px',
    width: '69px',
    'min-width': '69px',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
    height: '40px',
    'min-height': '40px',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px',
    height: '40px',
    'min-height': '40px',
  }),
  input: (base: any) => ({}),
  singleValue: (base: any) => ({}),
};

const chevronDisabledColor = '#B3BAC5';
const chevronActiveColor = '#42526E';

export interface ActivateAudienceSpecificProps {
  initialPackNumber: number;
  minPackNumber: number;
  hardLimitPackNumber?: number;
  constants: any;
  publicStripeKey: string;
  email: string;
  planId: string;
  formAction: string;
  authenticityToken: string;
  mode: 'activate' | 'update_plan';
  existing_stripe: boolean;
  billingPageUrl: string;
  formSubmitAnalyticsPayload: string;
  showRecommend: boolean;
  isStripe3dsEnabled: boolean;
}

interface ActivateAudienceSpecificState {
  packNumber: number;
  showExplanation: boolean;
  price: string;
  pageUserCount: string;
  pageGroupCount: string;
  teamMemberCount: string;
  metricCount: string;
  chevronRightActive: boolean;
  chevronLeftActive: boolean;
  updatePaymentMethod: any;
  stripeCardToken: string;
  loading: boolean;
}

export default class ActivateAudienceSpecific extends React.Component<
  ActivateAudienceSpecificProps,
  ActivateAudienceSpecificState
> {
  constructor(props: ActivateAudienceSpecificProps) {
    super(props);
    this.state = {
      ...this.calculatePackAllocations(this.props.initialPackNumber),
      ...this.getChevronState(this.props.initialPackNumber),
      showExplanation: false,
      updatePaymentMethod: new UpdatePaymentMethod({
        publicStripeKey: this.props.publicStripeKey,
      }),
      stripeCardToken: '',
      loading: false,
    };
    selectStyles.singleValue = (base: any) => ({
      ...base,
      margin: '-2px 6px 0px',
      padding: 0,
    });

    selectStyles.input = (base: any) => ({
      ...base,
      margin: 0,
      padding: '0px 0px 5px',
      position: 'absolute',
    });

    this.state.updatePaymentMethod.onSuccess = (token: any) => {
      this.setState({
        stripeCardToken: token.id,
      });
      this.activatePage();
    };
  }

  activatePage = async () => {
    const data = {
      authenticity_token: this.props.authenticityToken,
      page: {
        audience_specific_pack_number: this.state.packNumber,
        plan_id: this.props.planId,
        stripe_card_token: this.state.stripeCardToken,
      },
    };

    notify('Please wait while we activate your page...', {
      color: Color.INFO,
      autoHide: false,
    });
    this.setState({ loading: true });

    try {
      const resp = await $.ajax({
        type: 'PUT',
        url: this.props.formAction,
        data: data,
        dataType: 'json',
      });

      if (window.ContainerAPI) {
        window.ContainerAPI.then((api: any) => {
          api.messageChannel.dispatch({ type: '@statuspage/refetchNav' });
        });
      }
      notify(
        'Awesome! Thanks for signing up! Your audience-specific page is now active.',
        {
          color: Color.SUCCESS,
          method: Method.DEFERRED,
        },
      );
      window.location.href = resp.redirect;
    } catch (e) {
      notify('We could not activate your page. Please try again.', {
        color: Color.ERROR,
        method: Method.DEFERRED,
      });
      window.location.reload;
    }
  };

  toggleShowExplanation = () => {
    this.setState({
      showExplanation: !this.state.showExplanation,
    });
  };

  tiers = () => {
    let tiers = new Array();
    for (let i = 1; i < 100; i++) {
      let n = i.toString();
      tiers.push({ name: n, value: n });
    }
    return tiers;
  };

  calculatePackAllocations = (packNumber: number) => {
    let price = this.props.constants.priceIncrement_1;
    if (packNumber > 10) {
      price += 9 * this.props.constants.priceIncrement_2_10;
      if (packNumber > 30) {
        price +=
          20 * this.props.constants.priceIncrement_11_30 +
          (packNumber - 30) * this.props.constants.priceIncrement_31_99;
      } else {
        price += (packNumber - 10) * this.props.constants.priceIncrement_11_30;
      }
    } else {
      price += (packNumber - 1) * this.props.constants.priceIncrement_2_10;
    }
    return {
      packNumber: packNumber,
      price: price.toLocaleString(),
      pageUserCount: (
        packNumber * this.props.constants.pausPerPack
      ).toLocaleString(),
      pageGroupCount: (
        packNumber * this.props.constants.groupsPerPack
      ).toLocaleString(),
      teamMemberCount: (
        packNumber * this.props.constants.teamMembersPerPack
      ).toLocaleString(),
      metricCount: (
        packNumber * this.props.constants.metricsPerPack
      ).toLocaleString(),
    };
  };

  onSelectPackNumber = (selection: any) => {
    let packNumber = +selection.target.value;
    this.setNewPackNumber(packNumber);
  };

  setNewPackNumber = (packNumber: number) => {
    if (packNumber >= 1 && packNumber <= 99) {
      this.setState({
        ...this.getChevronState(packNumber),
        ...this.calculatePackAllocations(packNumber),
      });
    }
  };

  getChevronState = (packNumber: number) => {
    return {
      chevronLeftActive: packNumber != 1,
      chevronRightActive: packNumber != 99,
    };
  };

  onPlanSelection = () => {
    // send the analytics event tracking that we clicked the button
    window.analyticsClient.then((analyticsClient: any) => {
      analyticsClient.sendUIEvent(
        JSON.parse(this.props.formSubmitAnalyticsPayload),
      );
    });

    if (!this.props.existing_stripe && !this.props.isStripe3dsEnabled) {
      this.openPaymentModal();
    } else if (this.props.mode === 'update_plan') {
      // only actually perform the update if we've changed the pack number
      this.state.packNumber != this.props.initialPackNumber &&
        this.updatePlan();
    } else {
      this.updatePlan();
    }
  };

  updatePlan = async () => {
    if (
      confirm(
        'Are you sure you wish to continue?\r\n\r\nChanges will take place immediately, and your card will be charged or refunded on a prorated basis.',
      )
    ) {
      this.setState({ loading: true });

      try {
        const response = await $.ajax({
          type: 'PUT',
          url: this.props.formAction,
          data: {
            page: {
              plan_id: this.props.planId,
              audience_specific_pack_number: this.state.packNumber,
            },
          },
        });

        if (window.ContainerAPI) {
          window.ContainerAPI.then((api: any) => {
            api.messageChannel.dispatch({ type: '@statuspage/refetchNav' });
          });
        }

        if (this.props.isStripe3dsEnabled) {
          window.parent.location.href = response.redirect;
        } else {
          notify(
            'Awesome! Your new plan is now in effect, and changes will be reflected immediately.',
            {
              color: Color.SUCCESS,
              method: Method.DEFERRED,
            },
          );
          window.parent.location.href = this.props.billingPageUrl;
        }
      } catch (e) {
        notify('Your plan failed to update. Please try again.', {
          color: Color.ERROR,
          method: Method.DEFERRED,
        });
        window.location.reload;
      }
    }
  };

  openPaymentModal = () => {
    this.state.updatePaymentMethod.open({
      title: `Audience-specific $${this.state.price}/mo`,
      email: this.props.email,
      allowRememberMe: false,
      zipCode: true,
      buttonText: 'Confirm plan',
    });
  };

  // if the current displayed pack numebr is the one that's active for the page
  isCurrentPackNumberSelected() {
    return (
      this.props.mode === 'update_plan' &&
      this.props.initialPackNumber === this.state.packNumber
    );
  }

  isCurrentPackNumberOverLimit() {
    return (
      this.props.hardLimitPackNumber != null &&
      !this.isCurrentPackNumberSelected() &&
      this.state.packNumber < this.props.hardLimitPackNumber
    );
  }

  isCurrentPackNumberDisabled() {
    return (
      this.isCurrentPackNumberOverLimit() &&
      this.state.packNumber < this.props.initialPackNumber
    );
  }

  render() {
    return this.state.loading
      ? this.renderLoading()
      : this.renderAudienceSpecificPricing();
  }

  renderLoading() {
    return (
      <div className="loading-spinner">
        <Spinner size="large" />
      </div>
    );
  }

  renderDescription() {
    const baseText =
      'Select a tier with the appropriate allocations of users, groups, team members, and metrics.';

    if (this.props.mode === 'update_plan') {
      return (
        <>
          You're currently on{' '}
          <strong>pricing tier {this.props.initialPackNumber}</strong> for your
          audience-specific page. {baseText}
        </>
      );
    } else {
      return baseText;
    }
  }

  renderWarning() {
    if (
      !this.isCurrentPackNumberOverLimit() &&
      !this.isCurrentPackNumberSelected() &&
      this.state.packNumber < this.props.minPackNumber
    ) {
      return (
        <div className="warning-container">
          <div className="icon">
            <WarningIcon label="" size="medium" primaryColor="#FF8B00" />
          </div>
          <div className="message">
            Your configurations are more than what is included in this plan.
            Choose a higher tier.
          </div>
        </div>
      );
    }
  }

  renderRecommendLozenge() {
    const recommended = this.props.minPackNumber === this.state.packNumber;
    if (this.props.showRecommend) {
      return (
        <div className={`lozenge ${recommended ? '' : 'invisible'}`}>
          <Lozenge appearance="inprogress">Recommended</Lozenge>
        </div>
      );
    }
  }

  renderDisabledTooltip() {
    if (!this.isCurrentPackNumberOverLimit()) {
      return;
    }

    const message = this.isCurrentPackNumberDisabled()
      ? 'You have more team members than this plan allows. Account owners – remove the necessary amount of team members from the organization (under Users) to select this plan.'
      : 'You have more team members than this tier includes. You can still choose this tier, but you will need to contact support to add any more team members.';
    const icon = this.isCurrentPackNumberDisabled()
      ? 'fa-warning'
      : 'fa-info-circle';

    // Obviously it's not ideal to have to define this HTML as a string. However, it ended
    // up being simpler than adding a lot of complexity to the functionality in Tooltip to support
    // possibly a string, and possibly using HTML code. Also as we move to the SPA more our Tooltipster
    // usage should diminish, so not a huge need to add functioanlity to it.
    const tooltipContent = `
      <div class="tooltip-container">
        <div class="icon-container">
          <span class="fa ${icon}"></span>
        </div>
        <div class="content">
          ${message}
        </div>
      </div>
    `;

    return (
      <Tooltip
        title={tooltipContent}
        placement="right"
        maxWidth={350}
        theme={['tooltipster-shadow', 'tooltipster-with_icon']}
        contentAsHtml={true}
        className="disable-warning-icon"
        disableOnTouchScreen={false}
      >
        <span className={`fa ${icon}`}></span>
      </Tooltip>
    );
  }

  renderAudienceSpecificPricing() {
    return (
      <div className="audience-specific-pricing">
        <h3 className="page-header">
          {this.props.mode === 'update_plan'
            ? 'Update your plan'
            : 'Activate your audience-specific page'}
        </h3>
        <div className="description">{this.renderDescription()}</div>
        <div className="activate-audience-specific">
          <div className="pricing-tier-row">
            <span className="pricing-tier-label">
              {this.renderDisabledTooltip()} Pricing tier
            </span>
            <span className="select-container">
              <SelectInput
                name="tier"
                options={this.tiers()}
                id="tier_id"
                defaultValue={this.state.packNumber.toString()}
                onChange={this.onSelectPackNumber}
                isSearchable={true}
                styles={selectStyles}
              />
            </span>
          </div>
          {this.renderWarning()}
          <div className="plan-detail-selector">
            <div
              className="arrow-container"
              style={{
                cursor: this.state.chevronLeftActive ? 'pointer' : 'default',
              }}
              onClick={() => this.setNewPackNumber(this.state.packNumber - 1)}
            >
              <i
                className="fa fa-angle-left arrow"
                style={{
                  color: this.state.chevronLeftActive
                    ? chevronActiveColor
                    : chevronDisabledColor,
                }}
              ></i>
            </div>
            <div
              className={cx(
                'plan-detail',
                this.props.showRecommend ? 'with-lozenge' : '',
              )}
            >
              {this.renderRecommendLozenge()}
              <div
                className={cx(
                  'header',
                  this.props.showRecommend ? 'with-lozenge' : '',
                )}
              >
                <div className="price">
                  <span className="sup">$</span>
                  <span className="amount">{this.state.price}</span>
                  <span className="sub">/MO</span>
                </div>
              </div>
              <ul className={this.props.showRecommend ? 'with-lozenge' : ''}>
                <li className="table-row bigger">
                  <strong>{this.state.pageUserCount}</strong> users
                  <span className="more-info">
                    <Tooltip
                      title="Page viewers who authenticate to view your page and subscribe to notifications"
                      placement="right"
                      className="info-tooltip"
                      theme="tooltipster-shadow"
                    >
                      <EditorPanelIcon
                        label="{this.state.pageUserCount} users"
                        size="medium"
                      />
                    </Tooltip>
                  </span>
                </li>
                <li className="table-row bigger">
                  <strong>{this.state.pageGroupCount}</strong> groups
                  <span className="more-info">
                    <Tooltip
                      title="Groups of users that have the same permissions when viewing your status page"
                      placement="right"
                      className="info-tooltip"
                      theme="tooltipster-shadow"
                    >
                      <EditorPanelIcon
                        label="{this.state.pageGroupCount} groups"
                        size="medium"
                      />
                    </Tooltip>
                  </span>
                </li>
                <li className="table-row smaller">
                  <strong>{this.state.teamMemberCount}</strong> team members
                  <span className="more-info">
                    <Tooltip
                      title="People with permissions to enter incidents and manage your team’s page"
                      placement="right"
                      theme="tooltipster-shadow"
                      className="info-tooltip"
                    >
                      <EditorPanelIcon
                        label="{this.state.teamMemberCount} team members"
                        size="medium"
                      />
                    </Tooltip>
                  </span>
                </li>
                <li className="table-row smaller">
                  <strong>{this.state.metricCount}</strong> metrics
                  <span className="more-info">
                    <Tooltip
                      title="Display metrics on your page to give insight into performance"
                      placement="right"
                      theme="tooltipster-shadow"
                      className="info-tooltip"
                    >
                      <EditorPanelIcon
                        label="{this.state.metricCount} metrics"
                        size="medium"
                      />
                    </Tooltip>
                  </span>
                </li>
              </ul>
              <div className="button-container">
                <button
                  className={`cpt-button ${
                    this.isCurrentPackNumberDisabled() ||
                    this.isCurrentPackNumberSelected()
                      ? 'style-disabled'
                      : 'style-primary'
                  } select-plan-button`}
                  onClick={this.onPlanSelection}
                  disabled={
                    this.isCurrentPackNumberDisabled() ||
                    this.isCurrentPackNumberSelected()
                  }
                >
                  Select
                </button>
              </div>
            </div>
            <div
              className="arrow-container"
              style={{
                cursor: this.state.chevronRightActive ? 'pointer' : 'default',
              }}
              onClick={() => this.setNewPackNumber(this.state.packNumber + 1)}
            >
              <i
                className="fa fa-angle-right arrow"
                style={{
                  color: this.state.chevronRightActive
                    ? chevronActiveColor
                    : chevronDisabledColor,
                }}
              ></i>
            </div>
          </div>
          <div
            className="price-explanation"
            onClick={this.toggleShowExplanation}
          >
            How is the price calculated?{' '}
            <span className="chevron">
              {this.state.showExplanation ? (
                <ChevronUpIcon
                  label="Collapse price explanation"
                  size="large"
                />
              ) : (
                <ChevronDownIcon label="Show price explanation" size="large" />
              )}
            </span>
          </div>
          <PriceExplanation invisible={!this.state.showExplanation} />
        </div>
      </div>
    );
  }
}
