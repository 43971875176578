import { Page } from 'resources/types';

export class Subscriber {
  mode: string;
  email?: string;
  endpoint?: string;
  display_phone_number?: string;
  id?: string;
  channel_name: string;
  workspace_name: string;

  constructor(
    mode: string,
    email: string = '',
    display_phone_number: string = '',
    endpoint: string = '',
    id: string = '',
    obfuscated_channel_name: string = '',
    workspace_name: string = '',
  ) {
    this.mode = mode;
    this.email = email;
    this.display_phone_number = display_phone_number;
    this.endpoint = endpoint;
    this.id = id;
    this.channel_name = obfuscated_channel_name;
    this.workspace_name = workspace_name;
  }

  static displayMode(subscriber: Subscriber): string | undefined {
    switch (subscriber.mode) {
      case 'email':
        return 'Email';
      case 'sms':
        return 'SMS';
      case 'webhook':
        return 'Webhook';
      case 'slack':
        return 'Slack channel';
      case 'teams':
        return 'MS team';
    }
  }

  static identifier(subscriber: Subscriber): string | undefined {
    switch (subscriber.mode) {
      case 'email':
        return subscriber.email;
      case 'sms':
        return subscriber.display_phone_number;
      case 'webhook':
        return subscriber.endpoint;
      case 'teams':
        return subscriber.endpoint;
      case 'slack':
        const channel_name = subscriber.channel_name.startsWith('#')
          ? subscriber.channel_name
          : `#${subscriber.channel_name}`;
        return channel_name;
    }
  }
}

export interface NewSubscriptionProps {
  page: Page;
  subscriber: Subscriber;
  components: any[];
  formAction: string;
}

export interface EditSubscriptionProps {
  page: Page;
  subscriber: Subscriber;
  components: any[];
  selectedComponentIds: string[];
  allowComponentSubscribers: boolean;
}

export interface ComponentFormProps {
  page: Page;
  components: any[];
  selectedComponentIds: string[];
  allowComponentSubscribers: boolean;
  subscriber: Subscriber;
  formAction: string;
  formMethod: string;
  allowUnsubscribe: boolean;
}

export interface SubscriptionState {
  globalToggleName: string;
  blockSave: boolean;
  initialSubscribeShow: boolean;
}

declare global {
  interface Window {
    Routes: any;
    analyticsClient: any; // this is needed so TS doesn't whine about analyticsClient
    pageColorData: any;
  }
}
