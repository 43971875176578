import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TabHeader from './tab_header';

// An ordered group of clickable headers, one of which is active.
export default class TabHeaders extends React.Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.oneOf([TabHeader]) }),
    ).isRequired,
    activeName: PropTypes.string,
    className: PropTypes.string,
    onActivate: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    activeName: null,
    onActivate: () => {},
  };

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClosingMenu);
    window.addEventListener('resize', this.handleClosingMenu);
  }

  hideMenu = () => {
    this.setState(() => ({ isOpen: false }));
  };

  toogleMenu = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleClosingMenu = ev => {
    if (!ev.target.classList || !ev.target.classList.contains('tab-headers')) {
      this.hideMenu();
    }
  };

  handleClick = ev => {
    ev.stopPropagation();
    ev.preventDefault();
    this.toogleMenu();
    return;
  };

  render() {
    const { children, activeName, className, onActivate } = this.props;
    const { isOpen } = this.state;

    const clonedChildren = React.Children.map(children, child =>
      React.cloneElement(child, {
        activeName,
        onClick: () => onActivate(child.props.name),
      }),
    );
    const activeLabel = clonedChildren.find(
      child => child.props.name === activeName,
    ).props.label;

    return (
      <div
        className={cn('tab-headers', className, { open: isOpen })}
        data-content={activeLabel}
        onClick={this.handleClick}
      >
        {clonedChildren}
      </div>
    );
  }
}
