import React, { Component } from 'react';

class SsoMetrics extends Component {
  _failureRreasons() {
    if (this.props.presenter.has_failures) {
      return (
        <span>
          <h6>Reason for last Failure:</h6>
          <span>{this.props.presenter.reason_for_last_failure}</span>
        </span>
      );
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="span12">
            <h6>
              Total unique visitors:{' '}
              {this.props.presenter.total_unique_visitors}
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="span12">
            <h6>Admin visitors: {this.props.presenter.admin_visitors_count}</h6>
            <p>{this.props.presenter.admin_visitors}</p>
          </div>
        </div>

        <div className="row">
          <div className="span12">
            <h6>Page visitors: {this.props.presenter.page_visitors_count}</h6>
            <p>{this.props.presenter.page_visitors}</p>
          </div>
        </div>

        <div className="row">
          <div className="span12">
            <h6>Issuing sources</h6>
            <p>{this.props.presenter.issuing_sources}</p>
          </div>
        </div>

        <div className="row">
          <div className="span12">
            <h6>
              Successful auth attempts: {this.props.presenter.success_count}
            </h6>
            <h6>Failed auth attempts: {this.props.presenter.fail_count}</h6>
            {this._failureRreasons()}
          </div>
        </div>
      </div>
    );
  }
}

export default SsoMetrics;
