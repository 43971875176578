import * as React from 'react';
import { RadioGroup as AKRadioGroup } from '@atlaskit/radio';
import './styles.scss';
import styled from 'styled-components';

const PreviewStatusEmbedPosition = require('./position_preview_status_embed.svg');
const PreviewAppPosition = require('./position_preview_app.svg');

const positionOptions = [
  {
    name: 'bottomLeft',
    value: 'bottomLeft',
    label: 'Bottom left',
  },
  {
    name: 'bottomRight',
    value: 'bottomRight',
    label: 'Bottom right',
  },
  {
    name: 'topRight',
    value: 'topRight',
    label: 'Top right',
  },
];

const AKRadioGroupStyle = styled.div`
  margin-top: 20px;
  color: #172b4d;

  label {
    cursor: pointer;
  }

  input {
    width: inherit;
    height: inherit;
    margin: 0;
  }
`;

interface Props {
  position: string;
  onChange: (value: string) => void;
}

export default function PositionSetup({ position, onChange }: Props) {
  const positionClassName = position
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase();

  function renderRadioGroup() {
    return (
      <AKRadioGroupStyle>
        <AKRadioGroup
          onChange={event => onChange(event.target.value)}
          value={position}
          options={positionOptions}
        />
      </AKRadioGroupStyle>
    );
  }

  return (
    <div className="position-setup config-display-columns">
      <div className="position-config config-column">
        <h1>Positioning</h1>
        {renderRadioGroup()}
      </div>
      <div className="position-preview display-column">
        <div className="preview-app-position img-container">
          <img src={PreviewAppPosition} />
          <div
            className={`preview-status-embed-position ${positionClassName} img-container`}
          >
            <img src={PreviewStatusEmbedPosition} />
          </div>
        </div>
        <p className="app-preview-note">
          On mobile devices, the default widget will always appear on the bottom
          of the screen.
        </p>
      </div>
    </div>
  );
}
