import React from 'react';

/**
 * @constant {Object} The map of fields to display indexed by the type of subscriber.
 */
const SUBSCRIBER_DISPLAY_FIELD_BY_TYPE = {
  sms: 'display_phone_number',
  email: 'email',
  slack: 'workspace_name',
  teams: 'endpoint',
  webhook: 'endpoint',
};

/**
 * @constant {Array} The list of subscribers types of which the front-end is aware.
 */
const NOTIFICATION_TYPES = ['email', 'sms', 'slack', 'teams', 'webhook'];

/**
 * @constant {Object} The map of title-cased display strings indexed by the type of subscriber.
 */
const SUBSCRIBER_TITLE_DISPLAY_TYPE_BY_TYPE = {
  email: 'Email',
  sms: 'SMS',
  slack: 'Slack',
  teams: 'Microsoft Teams',
  webhook: 'Webhook',
};

/**
 * @constant {Object} The map of sentence-cased display strings indexed by the type of subscriber.
 */
const SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE = {
  email: 'email',
  sms: 'SMS',
  slack: 'slack',
  teams: 'teams',
  webhook: 'webhook',
};

/**
 * @constant {Object} The map of messages to display for an empty list of subscribers indexed by the type.
 */
const QUARANTINE_EMPTY_LIST_MESSAGE_BY_TYPE = {
  email: (
    <span>
      If we have any reason to believe an email address cannot be contacted,
      that subscriber will be put into a quarantine and can be found here.
    </span>
  ),
  sms: (
    <span>
      If we have any reason to believe a phone number cannot be reached by SMS,
      that subscriber will be put into a quarantine and can be found here.
    </span>
  ),
  webhook: (
    <span>
      If we have any reason to believe an endpoint is not reachable or is not
      successfully processing your webhook, the subscriber will be put into a
      quarantine and can be found here.
    </span>
  ),
};

/**
 * Formats a subscriber type and a histogram of counts by type into a styled span.
 *
 * @example
 * // returns <span>Email <span class="subtle">(101)</span></span>
 * renderSubscriberTypeAndCount("email", "active", {"email":{"active":101,"unconfirmed":100,"quarantined":99,"total":300}});
 * @example
 * // returns <span>Webhook</span>
 * renderSubscriberTypeAndCount("webhook");
 * @param   {string} type The type of the subscriber to render.
 * @param   {Object} [counts] The histogram of counts by subscriber type.
 * @returns {ReactElement} The subscriber type and count rendered to displayable HTML.
 */

function renderSubscriberCount(type, state, counts) {
  if (
    counts &&
    counts[type] !== undefined &&
    counts[type][state] !== undefined
  ) {
    return <span className="subtle"> ({counts[type][state]})</span>;
  }
  return null;
}

const Notifications = {
  NOTIFICATION_TYPES,
  QUARANTINE_EMPTY_LIST_MESSAGE_BY_TYPE,
  SUBSCRIBER_DISPLAY_FIELD_BY_TYPE,
  SUBSCRIBER_TITLE_DISPLAY_TYPE_BY_TYPE,
  SUBSCRIBER_SENTENCE_DISPLAY_TYPE_BY_TYPE,
  renderSubscriberCount,
};

export default Notifications;
