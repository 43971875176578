'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import inflection from 'inflection';
import Confirm from 'components/confirm';
import Textarea from '../../../../components/textarea';
import UpdateBillingSubscriptionModal from '../billing_subscriptions/update_billing_subscription_modal';

class Subscription extends React.Component {
  /**
   * General subscription management and updating for pages
   * @params props
   * @params props.page
   * @params props.coupons
   * @params props.plans
   * @params props.types
   */
  constructor(props) {
    super(props);
    this.state = { cancelError: null };
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this))
      .find('#btn-confirm-cancel-subscription')
      .on('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.onCancel()
          .then(() => {
            $(ReactDOM.findDOMNode(this))
              .find('#modal-confirmation-cancel-subscription')
              .modal('hide');
            window.location.reload();
          })
          .catch((error) => {
            this.setState({ cancelError: error });
          });
      });
  }

  cancel() {
    $(ReactDOM.findDOMNode(this))
      .find('#modal-confirmation-cancel-subscription')
      .modal();
  }

  update() {
    $(ReactDOM.findDOMNode(this))
      .find(`#sub-${this.props.page.billing_subscription.id}`)
      .modal();
  }

  onCancel() {
    const cancelRoute = Routes.page_billing_admin_cancel_billing_subscription_path(
      this.props.page.id,
      { format: 'json' },
    );
    return new Promise((resolve, reject) => {
      let reason = $('textarea[name="reason"]').val().trim();
      if (!reason) {
        reject('You must provide a reason for cancelling');
      } else {
        $.ajax({
          type: 'DELETE',
          url: cancelRoute,
          data: { reason: reason },
          success: resolve,
          error: (errors) => {
            reject(JSON.parse(errors.responseText).errors[0]);
          },
        });
      }
    });
  }

  cancelError() {
    if (this.state.cancelError) {
      return (
        <span className="error" style={{ color: 'indianred' }}>
          {this.state.cancelError}
        </span>
      );
    }
  }

  cancelElement() {
    if (
      this.props.cancelable &&
      !this.props.page.billing_subscription.canceled
    ) {
      return (
        <a href="#" onClick={this.cancel.bind(this)}>
          <small>cancel</small>
        </a>
      );
    } else {
      return <span style={{ color: '#EFEFEF', cursor: 'default' }}>☺</span>;
    }
  }

  canceled() {
    if (this.props.page.billing_subscription.canceled) {
      return <small> (canceled)</small>;
    }
  }

  quantity() {
    if (this.props.page.billing_subscription.quantity > 1) {
      return <span> x {this.props.page.billing_subscription.quantity}</span>;
    }
  }

  stripe() {
    if (
      this.props.page.billing_subscription.type === 'StripeBillingSubscription'
    ) {
      return (
        <div style={{ color: '#97A1AE', fontSize: '0.7em' }}>
          {this.props.page.billing_subscription.stripe_subscription_id}
        </div>
      );
    }
  }

  render() {
    let subscription = this.props.page.billing_subscription;
    const coupon = _.find(this.props.coupons, { id: subscription.coupon });
    return (
      <div className="section row overview">
        <div className="header span3">Subscription</div>
        <div className="span9">
          <div className="row">
            <div className="span2">
              <h6>Type</h6>
              {subscription.type.replace('BillingSubscription', '')}
              {this.stripe()}
              {this.canceled()}
            </div>
            <div className="span2">
              <h6>Plan</h6>
              {inflection.titleize(subscription.plan_name)} {this.quantity()}
            </div>
            <div className="span2">
              <h6>Discount</h6>
              {inflection.titleize(coupon ? coupon.display : 'None')}
            </div>
            <div className="span2">
              <h6>Note</h6>
              {inflection.titleize(subscription.note || 'None')}
            </div>
            <div className="span2">
              <h6>
                <a href="#" onClick={this.update.bind(this)}>
                  Update <i className="fa fa-subway" />
                </a>
              </h6>
              {this.cancelElement()}
            </div>
          </div>
        </div>

        <UpdateBillingSubscriptionModal
          types={this.props.types}
          plans={this.props.plans}
          coupons={this.props.coupons}
          page={this.props.page}
          id={`sub-${this.props.page.billing_subscription.id}`}
        />

        <Confirm
          id="cancel-subscription"
          title="Cancel subscription"
          onConfirm={this.onCancel.bind(this)}
          confirm_text="Yes, cancel it"
        >
          <div className="modal-body">
            <Textarea name="reason" label="Are you sure?  Why?" />
            {this.cancelError()}
          </div>
        </Confirm>
      </div>
    );
  }
}

export default Subscription;
