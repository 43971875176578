import React from 'react';
import cx from 'classnames';

class UnsubscribeButton extends React.Component {
  _unsubscribe() {
    $.ajax({
      type: 'DELETE',
      url: Routes.page_subscriber_url(this.props.page, this.props.subscriber),
    })
      .done((msg) => {
        HRB.utils.notify('Subscriber successfully unsubscribed.', {
          method: 'deferred',
        });

        const url = new URL(
          Routes.notifications_page_url(this.props.page, {
            domain: null,
            subdomain: null,
          }),
        );

        if (window.ContainerAPI) {
          window.ContainerAPI.then((api) => {
            api.navigate(url.pathname, () => {
              window.location = url;
            });
          });
        } else {
          window.location = url;
        }
      })
      .fail(() => {
        HRB.utils.basicFailAndReload('unsubscribing a subscriber');
      });
  }

  render() {
    return (
      <a
        className="cpt-button style-primary size-small pull-right"
        onClick={this._unsubscribe.bind(this)}
      >
        Unsubscribe
      </a>
    );
  }
}

export default UnsubscribeButton;
