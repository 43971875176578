import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TabGroup from 'components/tab_group';
import TabHeader from 'components/tab_header';
import TabHeaders from 'components/tab_headers';
import TabPanel from 'components/tab_panel';
import TabPanels from 'components/tab_panels';
import Notifications from 'lib/manage/notifications';

import SubscriberCounts from './subscriber_counts';
import NotificationsSubscriberTypeManager from './notifications_subscriber_type_manager';
import DisabledFeatureMessage from './disabled_feature_message';

/**
 *  The interface to browse and search lists of email, sms, and web subscribers.
 */
export default class NotificationsSubscriberManager extends Component {
  static propTypes = {
    pageCode: PropTypes.string.isRequired, // The code of the page whose subscribers to list.
    limit: PropTypes.number.isRequired,
    enabledNotificationTypes: PropTypes.shape({
      email: PropTypes.bool.isRequired,
      sms: PropTypes.bool.isRequired,
      webhook: PropTypes.bool.isRequired,
      slack: PropTypes.bool.isRequired,
      teams: PropTypes.bool.isRequired,
    }).isRequired,
    requiresAdvancedNotifications: PropTypes.shape({
      email: PropTypes.bool.isRequired,
      sms: PropTypes.bool.isRequired,
      webhook: PropTypes.bool.isRequired,
    }).isRequired,
    allowablePlans: PropTypes.array.isRequired,
    externalCountId: PropTypes.string,
    isSlackEnabled: PropTypes.bool.isRequired,
    isMsTeamsEnabled: PropTypes.bool.isRequired,
  };

  state = {
    updatedAt: moment().format(),
  };

  _touch = () => {
    this.setState({ updatedAt: moment().format() });
  };

  handleChangeTabGroup = (deliveryType) =>
    analytics.track('Notifications Page - Clicked Tab', {
      deliveryType,
      source: 'Notifications Page',
    });

  render() {
    const beginningOfPastMonthTimestamp = moment()
      .subtract(1, 'month')
      .startOf('day')
      .format();
    return (
      // These components wrap network requests, allowing us to leverage the prop system to only make network requests when the parameters change
      // The SubscriberCounts component will make a network request based on the passed in props, and return the result stored in the `allTimeHistogram` variable
      <SubscriberCounts
        pageCode={this.props.pageCode}
        updatedAt={this.state.updatedAt}
        externalCountId={this.props.externalCountId}
      >
        {(allTimeHistogram) => (
          // Note that in spite of this being nested, its network request is independent of the results of the outer SubscriberCounts call.
          <SubscriberCounts
            pageCode={this.props.pageCode}
            since={beginningOfPastMonthTimestamp}
            updatedAt={this.state.updatedAt}
          >
            {(thisMonthHistogram) => (
              <TabGroup default="email" onChange={this.handleChangeTabGroup}>
                <TabHeaders>
                  {Notifications.NOTIFICATION_TYPES.map((type) => {
                    if (type === 'slack' && !this.props.isSlackEnabled)
                      return null;

                    if (type === 'teams' && !this.props.isMsTeamsEnabled)
                      return null;

                    const label = `${Notifications.SUBSCRIBER_TITLE_DISPLAY_TYPE_BY_TYPE[type]}`;
                    return (
                      <TabHeader
                        key={type}
                        name={type}
                        label={label}
                        className={classnames(
                          this.props.enabledNotificationTypes[type]
                            ? null
                            : 'disabled',
                        )}
                      >
                        {label}
                      </TabHeader>
                    );
                  }).filter(function(el) {
                    return el != null;
                  })}
                </TabHeaders>
                <TabPanels>
                  {Notifications.NOTIFICATION_TYPES.map((type) => {
                    // TODO: (BUILDY-1197) Remove slack subscriptions feature flag and no need to filter on non-null values
                    if (type === 'slack' && !this.props.isSlackEnabled)
                      return null;

                    if (type === 'teams' && !this.props.isMsTeamsEnabled)
                      return null;

                    const enabled = this.props.enabledNotificationTypes[type];
                    const requiresAdvancedNotifications = this.props
                      .requiresAdvancedNotifications[type];
                    const anyExistingSubscribers =
                      allTimeHistogram[type] &&
                      allTimeHistogram[type].total > 0;
                    const hideSubscriberList =
                      requiresAdvancedNotifications && !anyExistingSubscribers;

                    return (
                      <TabPanel for={type} key={type}>
                        <DisabledFeatureMessage
                          enabledForPlan={!requiresAdvancedNotifications}
                          anyExistingSubscribers={anyExistingSubscribers}
                          enabled={enabled}
                          type={type}
                          pageCode={this.props.pageCode}
                          allowablePlans={this.props.allowablePlans}
                        />
                        {!hideSubscriberList && (
                          <NotificationsSubscriberTypeManager
                            allTimeHistogram={allTimeHistogram}
                            thisMonthHistogram={thisMonthHistogram}
                            pageCode={this.props.pageCode}
                            limit={this.props.limit}
                            type={type}
                            enabledType={enabled}
                            updatedAt={this.state.updatedAt}
                            touch={this._touch}
                          />
                        )}
                      </TabPanel>
                    );
                  }).filter(function(el) {
                    return el != null;
                  })}
                </TabPanels>
              </TabGroup>
            )}
          </SubscriberCounts>
        )}
      </SubscriberCounts>
    );
  }
}
