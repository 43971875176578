import _ from 'lodash';
import classnames from 'classnames';
import Pill from 'components/pill';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * A group of pill controls. See PropTypes below class definition for documentation on accepted props.
 */
export default class PillGroup extends Component {
  static propTypes = {
    /** @callback {PillChangedCallback} onChange - called when a pill has been clicked with the clicked value. */
    onChange: PropTypes.func.isRequired,
    /** The value of the pill */
    value: PropTypes.string.isRequired,
    /** The pill definitions themselves, and any other dom element. */
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const children = _.map(this.props.children, (child, index) =>
      child && child.type === Pill
        ? React.cloneElement(child, {
            onClick: value => this.props.onChange(value),
            active: child.props.value === this.props.value,
            key: index,
          })
        : child,
    );
    return (
      <div className={classnames('cpt-pill-group', this.props.className)}>
        {children}
      </div>
    );
  }
}
