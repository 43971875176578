import React from 'react';
import types from 'prop-types';

import Label from 'components/label';
import ItemSelector from 'components/items_selector/items_selector';

import Header from './Header';

const IndividualComponents = ({
  data: { hiddenFieldId, hiddenFieldName, items, preselectedItemIds },
  isVisible,
  onToggle: handleToggleIndividual,
}) => (
  <div className={`associated-groups${isVisible ? '' : ' hidden'}`}>
    <Header
      description="Choose what individual components and metrics to associate this user with. You can only select individual components when the user is not associated with any groups."
      title="Individual components and metrics"
    />
    <Label
      className="associated-components-label"
      text="Associated components"
    />
    <ItemSelector
      className="inline no-separator"
      hiddenFieldId={hiddenFieldId}
      hiddenFieldName={hiddenFieldName}
      items={items}
      onToggle={handleToggleIndividual}
      preselectedItemIds={preselectedItemIds}
    />
  </div>
);

IndividualComponents.propTypes = {
  data: types.shape({
    hiddenFieldId: types.string,
    hiddenFieldName: types.string,
    items: types.array,
    preselectedItemIds: types.arrayOf(types.string),
  }).isRequired,
  isVisible: types.bool.isRequired,
  onToggle: types.func.isRequired,
};

export default IndividualComponents;
