import React from 'react';
import inflection from 'inflection';
import SelectInput from 'components/select_input';
import Input from 'components/input';
import Form from 'components/form';
import TextArea from 'components/textarea';

import TypeSelector from './type_selector';
import CouponSelector from './coupon_selector';
import PlanSelector from './plan_selector';

class UpdateBillingSubscriptionModal extends React.Component {
  /**
   * A billing subscription tuning dialog.  Takes a billing subscription and hits the Manage::Admin::BillingSubscriptionController#create endpoint.
   *
   * @param props
   * @param props.coupons {Array}
   * @param props.plans {Array}
   * @param props.types
   * @param props.page
   * @param props.page.id
   * @param props.page.billing_subscription
   * @param props.page.billing_subscription.type
   * @param props.page.billing_subscription.effective_plan
   * @param props.page.billing_subscription.coupon_enabled
   * @param props.page.billing_subscription.plan_enabled
   * @param props.page.billing_subscription.quantity
   * @param props.page.billing_subscription.note
   * @param props.onClose {Function} Function to call when the modal is closed
   */

  constructor(props) {
    super(props);
    this.state = this.props.page.billing_subscription;
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps.page.billing_subscription);
  }

  disableSubmitButton() {
    let $button = $(this.refs.submitButton);
    $button.text('Updating...');
    $button.addClass('disabled').attr('disabled', true);
  }

  onChange(event) {
    if (event.target.name === 'billing_subscription[type]') {
      let newState = { type: event.target.value };
      // If the the new, selected type is NOT plan enabled,
      // we need to set the plan to the default plan for this type.
      let selectedType = this.props.types[event.target.value];
      if (!selectedType.plan_enabled) {
        newState.plan = selectedType.default_plan;
      }
      // set the new state
      this.setState(newState);
    } else if (event.target.name === 'billing_subscription[coupon]') {
      this.setState({ coupon: event.target.value });
    } else if (event.target.name === 'billing_subscription[plan_id]') {
      // Lookup the plan from the plans array
      const new_plan = _.find(this.props.plans, { id: event.target.value });
      // If we didn't find it, it's probably a default plan so don't perform any updates.
      // If we did, update the state.
      if (new_plan) this.setState({ plan: new_plan });
    }
  }

  subscriptionIdInput() {
    if (this.state.type !== 'StripeBillingSubscription') return;
    return (
      <Input
        name="billing_subscription[subscription_id]"
        label="Subscription ID"
        helpBlockText="If you can't find an appropriate plan and discount, you can create a subscription in Stripe and apply it here."
      />
    );
  }

  quantityOrPacksInput() {
    if (this.state.plan.requires_quantity) {
      const inputLabel = this.state.plan.custom ? 'Quantity' : 'Packs';
      return (
        <Input
          name="billing_subscription[quantity]"
          label={inputLabel}
          defaultValue={this.state.quantity}
        />
      );
    }
  }

  render() {
    return (
      <div className="modal hide fade" id={this.props.id}>
        <Form
          className="modal-content"
          ref="form"
          for="billing_subscription"
          action={Routes.page_billing_admin_billing_subscriptions_path(
            this.props.page,
          )}
          method="POST"
          className="form-horizontal"
          skipPrepend={true}
          onSubmit={this.disableSubmitButton.bind(this)}
        >
          <div className="modal-header">
            <a href="#" className="close close-adg3-hide" data-dismiss="modal">
              x
            </a>
            <h4>Update billing subscription</h4>
          </div>

          <div className="modal-body" style={{ maxHeight: 470 }}>
            <TypeSelector
              types={this.props.types}
              type={this.state.type}
              plan={this.state.plan}
              onChange={this.onChange.bind(this)}
            />

            <PlanSelector
              types={this.props.types}
              type={this.state.type}
              plan={this.state.plan}
              plans={this.props.plans}
              onChange={this.onChange.bind(this)}
            />

            {this.quantityOrPacksInput()}

            <CouponSelector
              types={this.props.types}
              type={this.state.type}
              coupons={this.props.coupons}
              coupon={this.state.coupon}
              onChange={this.onChange.bind(this)}
            />

            {this.subscriptionIdInput()}

            <TextArea
              name="billing_subscription[note]"
              label="Note"
              required={true}
              defaultValue={this.state.note}
            />
          </div>

          <div className="modal-footer modal-footer-reverse">
            <button className="cpt-button style-primary" ref="submitButton">
              Update subscription
            </button>
            <a
              href="#"
              id={this.props.id}
              data-dismiss="modal"
              className="close"
            >
              Cancel
            </a>
          </div>
        </Form>
      </div>
    );
  }
}

export default UpdateBillingSubscriptionModal;
