import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Tab content area.
/* Cannot desctructure the for keyword here, unfortunately. */
export default function TabPanel(props) {
  const { activeName, className, children } = props;
  return (
    <div
      style={{ display: props.for === activeName ? 'block' : 'none' }}
      className={classnames('tab-panel', className)}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  activeName: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  for: PropTypes.string.isRequired,
};

TabPanel.defaultProps = {
  className: '',
  activeName: null,
};
