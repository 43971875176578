import React, { useState, ChangeEvent } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import { IFilepicker } from 'app/manage/filepicker';

declare global {
  interface Window {
    FileReader: any;
  }
}

declare const filepicker: IFilepicker;

interface ImportUsersFormProps {
  processImportUrl: string;
  downloadTemplateUrl: string;
  setImportStatus: Function;
  setImportFailure: () => void;
  trialPage: boolean;
}

export function ImportUsersForm({
  processImportUrl,
  downloadTemplateUrl,
  setImportStatus,
  setImportFailure,
  trialPage,
}: ImportUsersFormProps) {
  const noFileMessage = 'No file chosen';

  const [filename, setFilename] = useState(noFileMessage);
  const [fileError, setFileError] = useState<string | null>();

  function clearState() {
    setFilename(noFileMessage);
    setFileError(null);
  }

  // add on-close callback to make sure we clear out the state
  $('#modal-import-users').on('hidden', function() {
    clearState();
  });

  function trackTemplateClick() {
    window.analyticsClient.then((analyticsClient: any) => {
      analyticsClient.sendUIEvent({
        actionSubject: 'button',
        actionSubjectId: 'downloadUserImportTemplate',
        action: 'clicked',
        source: 'audience',
      });
    });
  }

  function trackImportClick() {
    window.analyticsClient.then((analyticsClient: any) => {
      analyticsClient.sendUIEvent({
        actionSubject: 'button',
        actionSubjectId: 'importUsers',
        action: 'clicked',
        source: 'audience',
      });
    });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || !event.target.files[0]) {
      // User cancelled
      return;
    } else {
      setFileError(null);
      setFilename(event.target.files[0].name);
    }
  }

  function uploadFileAndSubmit(data: any, file: File) {
    var filepickerId = data['data']['preSignedUrlData']['filename'];
    var preSignedUrl = data['data']['preSignedUrlData']['url'];

    $.ajax({
      url: preSignedUrl,
      type: 'PUT',
      contentType: file.type,
      processData: false,
      data: file,
      success: function() {
        processImportFile(filepickerId);
      },
      error: function(xhr, textStatus, errorThrown) {
        setImportFailure();
      },
    });
  }

  function onSubmit() {
    if (trialPage) return;

    trackImportClick();

    const fileInput = ReactDOM.findDOMNode(
      document.querySelector('#csv_file'),
    ) as HTMLFormElement;

    if (!fileInput.files || fileInput.files.length == 0) {
      setFileError('Please provide a CSV file to import.');
      return;
    }

    // Check the size of the file to make sure it's not too large if the browser supports the check
    const maxSize = 5 * 1024 * 1024; // 5 MB
    if (window.FileReader && fileInput.files[0].size > maxSize) {
      setFileError("You can't upload files larger than 5MB.");
      return;
    }

    // Good to upload! Let's close the modal and kick off file processing.
    clearState();
    $('#close-import-users-btn').trigger('click');

    const file = fileInput.files[0];
    const [filename, filetype] = ((i) => [
      file.name.slice(0, i),
      file.name.slice(i + 1),
    ])(file.name.lastIndexOf('.'));

    var preSignedUrlPayload = {
      operationName: 'PreSignedUrlData',
      variables: {
        filename: filename,
        filetype: filetype,
      },
      query:
        'query PreSignedUrlData($filename: String!, $filetype: String!) {\n  preSignedUrlData(filename: $filename, filetype: $filetype) {\n    filename\n    url\n    __typename\n  }\n}\n',
    };

    $.ajax({
      type: 'POST',
      url: '/graphql',
      data: preSignedUrlPayload,
      dataType: 'json',
      success: (data) => {
        uploadFileAndSubmit(data, file);
      },
      error: function(xhr, textStatus, errorThrown) {
        setImportFailure();
      },
    });
  }

  function processImportFile(filepickerId: string) {
    if (!filepickerId) {
      setImportFailure();
      return;
    }

    $.ajax({
      type: 'POST',
      url: processImportUrl,
      dataType: 'json',
      data: {
        filepicker_id: filepickerId,
      },
      success: (data) => setImportStatus(data),
      error: () => setImportFailure(),
    });
  }

  function maybeRenderDisabledMessage() {
    if (trialPage) {
      return (
        <div className="cpt-notification warning in-page disabled-warning">
          Importing users is disabled on trial pages. Upgrade to a paid plan to
          enable this feature.
        </div>
      );
    }
  }

  function maybeRenderFileError() {
    if (fileError) {
      return <div className="cpt-notification error in-page">{fileError}</div>;
    }
  }

  function renderFileInput() {
    if (trialPage) {
      return (
        <div className="file-upload-container">
          <button className="cpt-button style-primary disabled">
            Choose file
          </button>
          {filename}
        </div>
      );
    } else {
      return (
        <div className="file-upload-container">
          <label htmlFor="csv_file">Choose file</label>
          <input
            type="file"
            accept="text/csv"
            id="csv_file"
            name="csv_file"
            onChange={handleChange}
            onKeyPress={(e) => {
              // default causes browser to open file explorer when enter is pressed
              e.preventDefault();
            }}
          />
          {filename}
        </div>
      );
    }
  }

  return (
    <div
      className="modal hide fade modal-import-users"
      id="modal-import-users"
      style={{ display: 'none' }}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onSubmit();
        }
      }}
      role="dialog"
      aria-labelledby="import-user-modal-title"
      aria-modal="true"
    >
      <div className="content">
        <div className="header">
          <h3 className="modal-title" id="import-user-modal-title">
            Import users
          </h3>
          <p>Add new users to your audience-specific page in bulk.</p>
        </div>
        {maybeRenderDisabledMessage()}
        <div className="body form-horizontal">
          <ol className="styled">
            <li>
              Download the{' '}
              <a
                href={downloadTemplateUrl}
                target="_blank"
                onClick={trackTemplateClick}
              >
                CSV template
              </a>
              .
            </li>
            <li>
              Add user details and access to the template.
              <small className="footnote">
                If you include existing users, we won’t make changes to their
                data.
              </small>
            </li>
            <li>
              Upload CSV file.
              <small className="footnote">
                5MB file size limit, 500 user limit, xlsx not supported.
              </small>
            </li>
          </ol>
        </div>
        {maybeRenderFileError()}
        {renderFileInput()}
        <div className="modal-footer">
          <div className="learn-more">
            <a
              target="_blank"
              href="https://help.statuspage.io/help/import-audience-specific-users"
            >
              Learn more
            </a>
          </div>
          <div>
            <a
              href="#"
              data-dismiss="modal"
              className="close hide"
              id="close-import-users-btn"
            >
              Cancel
            </a>
            <button
              className={`cpt-button style-primary ${
                trialPage ? 'disabled' : ''
              }`}
              id="btn-import-users"
              onClick={onSubmit}
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportUsersForm;
