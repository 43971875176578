'use strict';

import React from 'react';
import cx from 'classnames';
import { label, helpBlock } from './input_commons';

class ControlGroup extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = 'ControlGroup';
  }

  render() {
    return (
      <div
        className={cx(['control-group', this.props.className])}
        style={this.props.style}
      >
        {label.call(this)}
        <div className="controls">
          {this.props.children}
          {helpBlock.call(this)}
        </div>
      </div>
    );
  }
}

export default ControlGroup;
