import * as React from 'react';
import ComponentForm from './component_form';
import { NewSubscriptionProps, Subscriber } from './types';

export default class NewSubscription extends React.Component<
  NewSubscriptionProps
> {
  constructor(props: NewSubscriptionProps) {
    super(props);
  }

  render() {
    return (
      <div className="new-subscription" data-js-hook="subscription-form">
        <div className="heading">
          <h5 className="title">
            Choose which components to get status updates for.
          </h5>
          {this.renderSubscriberIdentification()}
          <div className="instructions">
            You can select the individual components to get notifications about
            when they are affected in an incident or maintenance.
          </div>
        </div>

        <ComponentForm
          page={this.props.page}
          components={this.props.components}
          selectedComponentIds={this.props.components.map((c) => c.id)}
          allowComponentSubscribers={true}
          subscriber={this.props.subscriber}
          formAction={this.props.formAction}
          formMethod="POST"
          allowUnsubscribe={false}
        />
      </div>
    );
  }

  renderSubscriberIdentification() {
    const { subscriber } = this.props;

    if (subscriber.mode === 'slack') {
      const channel_name = subscriber.channel_name.startsWith('#')
        ? subscriber.channel_name
        : `#${subscriber.channel_name}`;
      return (
        <div className="subscriber">
          Workspace: {subscriber.workspace_name}
          <br />
          Subscribed channel: {channel_name}
        </div>
      );
    }

    return (
      <div className="subscriber">
        Subscriber: {Subscriber.identifier(subscriber)}
      </div>
    );
  }
}
