import * as React from 'react';
import PreviousPageLink from './previous_page_link';
import Input from 'components/input';
import notify, { Color } from 'utils/notify';
import './styles.scss';

export interface GoogleConfigProps {
  savedDomains: string;
  actionPath: string;
  prevPagePath: string;
  organizationTeamMemberPath: string;
}

const GoogleConfigForm = ({
  savedDomains,
  actionPath,
  prevPagePath,
  organizationTeamMemberPath,
}: GoogleConfigProps) => {
  /** STATE VARS */
  const [domains, setDomains] = React.useState(savedDomains);
  const [enabled, setEnabled] = React.useState(!!savedDomains);

  /** UTILITY FUNCTIONS */
  const updateDomains = async (
    domainsToSave: string,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    try {
      await $.ajax({
        url: actionPath,
        method: 'PUT',
        dataType: 'json',
        data: {
          google_domains_list: domainsToSave,
        },
      });

      // if we saved a domain of any length, set to enabled. If it's empty, then it's not enabled.
      setEnabled(domainsToSave.length > 0);
      setDomains(domainsToSave);
      if (onSuccess) onSuccess();
    } catch (e) {
      if (onFail) onFail();
    }
  };

  const onSave = () => {
    updateDomains(
      domains,
      () => {
        notify('Google domain settings updated!', {
          color: Color.SUCCESS,
        });
      },
      () => {
        notify('Settings failed to update, please try again.', {
          color: Color.ERROR,
        });
      },
    );
  };

  const onDisable = () => {
    updateDomains(
      '',
      () => {
        notify('Google domain settings disabled.', {
          color: Color.SUCCESS,
        });
      },
      () => {
        notify(
          'Google domain settings failed to be disabled, please try again.',
          {
            color: Color.ERROR,
          },
        );
      },
    );
  };

  /** RENDER FUNCTIONS */
  const maybeRenderDisableButton = () => {
    if (enabled) {
      return (
        <a onClick={onDisable} className="btn-delete-config">
          Disable
        </a>
      );
    }
  };

  /** FINAL RESULT */
  return (
    <div className="google-config">
      <PreviousPageLink path={prevPagePath} />

      <h3>Google Auth</h3>
      <div className="heading">
        <div className="title">Single sign-on</div>
        <div className="description">
          <p>
            Use this form to require page viewers to authenticate with Google
            Authenticator before viewing your status page.
          </p>

          <p>
            If you want to set up authentication for team members to log in to
            the admin portal via Google Authenticator, use{' '}
            <a href={organizationTeamMemberPath}>this form</a> instead.
          </p>
        </div>
      </div>

      <div className="configure-step">
        <Input
          type="text"
          placeholder="Statuspage.io"
          label="Google Apps domain"
          value={domains}
          onChange={e => setDomains(e.target.value)}
          id="google-domains"
          inputClass="full-width google-domains"
          helpBlockText="Require page viewers to authenticate with Google on a specific domain. Need to enter multiple domains? Just separate them by commas."
        />
      </div>

      <div className="footer">
        <div className="form-actions-container">
          {maybeRenderDisableButton()}
          <button
            onClick={onSave}
            className="cpt-button style-primary btn-save-config"
          >
            {enabled ? 'Update' : 'Activate'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleConfigForm;
