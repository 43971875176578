import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ESSearchField from 'components/manage/es_search_field';
import { inflect } from 'inflection';

import PageAccess from 'resources/page_access';

class PageAccessSearchField extends React.Component {
  // Get HTML string for how a typeahead item should be rendered
  _template(suggestion) {
    const view = <PageAccessTypeaheadView suggestion={suggestion} />;
    return ReactDOMServer.renderToString(view);
  }

  render() {
    const searchRoute = PageAccess.searchRoute({ page_id: this.props.pageId });
    function mapResultToUiRoute(suggestion) {
      const url = suggestion.isUser()
        ? Routes.edit_page_page_access_user_url
        : Routes.edit_page_page_access_group_url;
      return new URL(
        url(this.props.pageId, suggestion.id, {
          domain: null,
          subdomain: null,
        }),
      );
    }
    return (
      <ESSearchField
        queryUrl={`${searchRoute}?limit=10&q=%QUERY`}
        pluralizedResultName={'incidents'}
        loadResults={PageAccess.search}
        mapResultToUiUrl={mapResultToUiRoute.bind(this)}
        template={this._template.bind(this)}
      />
    );
  }
}

class PageAccessTypeaheadView extends React.Component {
  _determineType() {
    const suggestion = this.props.suggestion;

    if (suggestion.isUser()) {
      return {
        iconClass: 'fa-user',
        matchValue: suggestion.email,
      };
    } else {
      return {
        iconClass: 'fa-users',
        matchValue: suggestion.name,
      };
    }
  }

  _titleText(displayWords) {
    if (this._isNameHighlighted()) {
      const { highlightedWords, tagClasses } = this._extractData('name');
      displayWords = this.props.suggestion.name
        .split(' ')
        .map((word, index) => {
          if (
            highlightedWords.indexOf(
              word.toLowerCase().replace(/[!?\.,]/, ''),
            ) >= 0
          ) {
            return (
              <strong className={tagClasses} key={index}>
                {' '}
                {word}{' '}
              </strong>
            );
          }
          return <span key={index}> {word} </span>;
        });
    } else if (this.props.suggestion.isUser()) {
      return (
        <span className="name">
          <strong className="es-highlight">
            {this.props.suggestion.email}
          </strong>
        </span>
      );
    }
    return <span className="name">{displayWords}</span>;
  }

  _extractData(highlightedField) {
    const text = this.props.suggestion.highlight[highlightedField];
    const highlightItems = $('<span>' + text + '</span>').find('strong');
    const tagClasses = $(highlightItems[0]).attr('class');
    const highlightedWords = _.map(highlightItems, (t) => {
      return $(t)
        .text()
        .toLowerCase();
    });
    return { highlightedWords: highlightedWords, tagClasses: tagClasses };
  }

  _isNameHighlighted() {
    return this._checkFieldHighlight('name');
  }

  _checkFieldHighlight(property) {
    return (
      this.props.suggestion.highlight &&
      this.props.suggestion.highlight.hasOwnProperty(property)
    );
  }

  _humanizedMetadata(suggestion) {
    if (suggestion.group) {
      return `Belongs to ${suggestion.group}`;
    }
    // If data is sourced from PG-backed search, only a count of accessible components and metrics are provided.
    if (
      suggestion.number_of_linked_components !== undefined &&
      suggestion.number_of_linked_metrics !== undefined
    ) {
      var linked_component_count = suggestion.number_of_linked_components ?? 0;
      var linked_metric_count = suggestion.number_of_linked_metrics ?? 0;
      if (linked_component_count || linked_metric_count) {
        return `Has access to ${linked_component_count} ${inflect(
          'component',
          linked_component_count,
        )} and ${linked_metric_count} ${inflect(
          'metric',
          linked_metric_count,
        )}`;
      } else {
        return 'Has no access to any components or metrics';
      }
    }
    // If data is sourced from ES-backed search, the returned data contains lists of accessible components and metrics;
    // count up each type to return a human-readable summary.
    var list = [];
    _.each(['component', 'metric'], function(field) {
      if (suggestion[field + 's_list'] === undefined) return;
      var count = suggestion[field + 's_list'].length;
      if (count > 0) {
        list.push(`${count} ${inflect(field, count)}`);
      }
    });
    if (list.length > 0) {
      return `Has access to ${list.join(' and ')}`;
    } else {
      return 'Has no access to any components or metrics';
    }
  }

  render() {
    const suggestion = this.props.suggestion;
    const suggestionData = this._determineType();

    return (
      <div className="item search-result es-results">
        <div className="icon-container">
          <i className={`fa ${suggestionData.iconClass}`} />
        </div>
        <div className="result-text-container">
          {this._titleText(suggestionData.matchValue)}
        </div>
        <div className="context result-context">
          {this._humanizedMetadata(suggestion)}
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default PageAccessSearchField;
