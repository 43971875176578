import * as React from 'react';
import { observer } from 'mobx-react';

import ColorInput from './color_input';
import { StatusEmbedChildProps } from './types';
import StatusEmbedStore from './store';
import { formatColor } from './utils';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { EventEmitter } from 'events';

@observer
export default class ColorsSetup extends React.Component<
  StatusEmbedChildProps,
  {}
> {
  store: StatusEmbedStore;

  constructor(props: StatusEmbedChildProps) {
    super(props);
    this.store = props.store; // makes store quicker to reference
  }

  render() {
    return (
      <div className="color-setup">
        <div className="title">Colors</div>
        <div className="color-group config-display-columns">
          <div className="color-choices config-column">
            <div className="control-group">
              <label>Incident background</label>
              <ColorInput
                onChange={inputColor =>
                  (this.store.draftColorSettings.incidentBackground = formatColor(
                    inputColor,
                  ))
                }
                colorConfigItem={
                  this.store.draftColorSettings.incidentBackground
                }
                inputClassName="incident-background"
              />
            </div>

            <div className="control-group">
              <label>Incident text color</label>
              <ColorInput
                onChange={inputColor =>
                  (this.store.draftColorSettings.incidentText = formatColor(
                    inputColor,
                  ))
                }
                colorConfigItem={this.store.draftColorSettings.incidentText}
                inputClassName="incident-text-color"
              />
            </div>
          </div>

          {/* Render an iframe of the actual implementation as an example */}
          <div className="color-examples display-column">
            <iframe src={this.store.incidentIframeSrc} />
          </div>
        </div>

        <div className="maintenance color-group config-display-columns">
          <div className="color-choices config-column">
            <div className="control-group">
              <label>Maintenance background</label>
              <ColorInput
                onChange={inputColor =>
                  (this.store.draftColorSettings.maintenanceBackground = formatColor(
                    inputColor,
                  ))
                }
                colorConfigItem={
                  this.store.draftColorSettings.maintenanceBackground
                }
                inputClassName="maintenance-background"
              />
            </div>

            <div className="control-group">
              <label>Maintenance text color</label>
              <ColorInput
                onChange={inputColor =>
                  (this.store.draftColorSettings.maintenanceText = formatColor(
                    inputColor,
                  ))
                }
                colorConfigItem={this.store.draftColorSettings.maintenanceText}
                inputClassName="maintenance-text-color"
              />
            </div>
          </div>

          {/* Render an iframe of the actual implementation as an example */}
          <div className="color-examples display-column">
            <iframe src={this.store.maintenanceIframeSrc} />
          </div>
        </div>
      </div>
    );
  }
}
