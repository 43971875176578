import CptAlertBox from 'components/cpt_alert_box';
import React from 'react';
import PropTypes from 'prop-types';
import Subscriber from 'resources/subscriber';
import { reportError } from 'utils/sentry_reporter';

export default class SubscriberCounts extends React.Component {
  static propTypes = {
    pageCode: PropTypes.string.isRequired,
    since: PropTypes.string,
    children: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    // the id used to update an external html element with any changes to the count
    externalCountId: PropTypes.string,
  };

  static defaultProps = {
    since: null,
  };

  state = {
    request: null, // expected type of PropTypes.shape({pageCode: PropTypes.string, since: PropTypes.string })
    error: null, // expected type of PropTypes.string
    histogram: {},
  };

  componentWillMount() {
    this.mounted = true;
    this.fetchCounts(this.props);
  }

  componentWillReceiveProps(props) {
    if (
      this.props.pageCode !== props.pageCode ||
      this.props.since !== props.since ||
      this.props.updatedAt !== props.updatedAt
    ) {
      this.fetchCounts(props);
    }
  }

  componentWillUnmount() {
    // make sure we don't try to call setState on an unmounted component
    this.mounted = false;
  }

  // Performs an ajax request to the back end to get an updated total count.
  // We can't use the histogram because it doesn't include deactivated subs
  // (which are part of the count for trial pages)
  updateExternalCount() {
    let element;
    if (
      !this.props.externalCountId ||
      this.state.error ||
      !(element = document.getElementById(this.props.externalCountId))
    ) {
      // in any of these cases we don't want to perform an update
      return;
    }

    $.ajax({
      type: 'POST',
      url: `/pages/${this.props.pageCode}/subscribers/count`,
      success: data => {
        if (element.textContent != data) {
          element.textContent = data;
        }
      },
    });
  }

  fetchCounts = props => {
    const request =
      this.props.since === null
        ? { pageCode: props.pageCode }
        : { pageCode: props.pageCode, since: props.since };
    Subscriber.getHistogramByState(request)
      .then(histogram => {
        if (this.mounted) {
          this.setState(prevState => {
            // Only update the state if the request in `state` is the request on which this function closes.
            if (prevState.request === request) {
              this.updateExternalCount();
              return { histogram };
            }
            return {};
          });
        }
      })
      .catch(e => {
        reportError(e, {});
        if (this.mounted) {
          this.setState(prevState => {
            // Same here: only update state if this request was the last request requested.
            if (prevState.request === request) {
              return {
                error:
                  'Failed to load subscriber counts. Please try again in a few minutes.',
                request: null,
              };
            }
            return {};
          });
        }
      });

    // Set the error and request here to mark which request was last requested by the user.
    this.setState({
      error: null,
      request,
    });
  };

  render() {
    if (this.state.error) {
      return (
        <CptAlertBox
          type="error"
          header="An error occurred while trying to fulfill your request. Please refresh the page and try again in a few minutes."
          body={this.state.error}
        />
      );
    }
    return this.props.children(this.state.histogram);
  }
}
