import PropTypes from 'prop-types';

const Histogram = PropTypes.shape({
  active: PropTypes.number.isRequired,
  unconfirmed: PropTypes.number.isRequired,
  quarantined: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

const NOTIFICATION_TYPES = ['email', 'sms', 'slack', 'teams', 'webhook'];

export { Histogram }; // eslint-disable-line import/prefer-default-export
