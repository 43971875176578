import React from 'react';
import inflection from 'inflection';
import _ from 'lodash';

import SelectInput from '../../../select_input';

class PlanSelector extends React.Component {
  planOption(plan) {
    return { name: inflection.titleize(plan.name), value: plan.id };
  }

  plans() {
    return _.sortBy(this.props.plans.map(this.planOption), 'name');
  }

  render() {
    const type = this.props.types[this.props.type];
    const options = type.plan_enabled
      ? this.plans()
      : [this.planOption(type.default_plan)];

    return (
      <SelectInput
        name="billing_subscription[plan_id]"
        id="plan_id"
        options={options}
        defaultValue={this.props.plan.id}
        label="Plan"
        onChange={this.props.onChange}
        modal={true}
      />
    );
  }
}

export default PlanSelector;
