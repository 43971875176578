import React from 'react';
import PropTypes from 'prop-types';
import TabPanel from './tab_panel';

// Virtual container for the tab content area.
// Exactly one of the child tab content areas is rendered.
export default function TabPanels({ children, activeName }) {
  const clonedChildren = React.Children.map(children, child =>
    React.cloneElement(child, { activeName }),
  );
  return <div>{clonedChildren}</div>;
}

TabPanels.propTypes = {
  activeName: PropTypes.string,
  children: PropTypes.arrayOf(TabPanel).isRequired,
};

TabPanels.defaultProps = {
  activeName: null,
};
