import React, { useState, ReactNode, useEffect } from 'react';
import AudienceCsvStatusContainer from './csv_status';
import PageAccessSearchField from '../../components/manage/page_access_search_field';
import { Status } from './csv_status';
import { Urls } from './types';
import notify, { Color } from 'utils/notify';

interface Props {
  pageId: string;
  userId: string;
  organizationId: string;
  initialStatus: Status | null;
  urls: Urls;
  trialPage: boolean;
  showCallout: boolean;
}

export default function AudienceSpecificHeader({
  pageId,
  initialStatus,
  urls,
  trialPage,
}: Props) {
  const [status, setStatus] = useState(initialStatus);
  // Tracks if we experience a generalized error that causes an import or export to fail.
  // If an import fails, set to "import". If an export fails, set to "export".
  const [failedAction, setFailedAction] = useState<string | null>(null);

  function csvActionsDisabled() {
    return status && !status.csv_actions_enabled;
  }

  function onImportClick() {
    // remove a failed action state if there is one
    setFailedAction(null);

    if (csvActionsDisabled()) {
      notify(
        `You are unable to add new users while an ${
          status!.csv_display_mode
        } is in progress. Try again when the ${
          status!.csv_display_mode
        } is finished.`,
        {
          color: Color.ERROR,
        },
      );
    }
  }

  function onExportClick() {
    // remove a failed action state if there is one
    setFailedAction(null);

    if (csvActionsDisabled()) {
      notify(
        `You are unable to export while an ${
          status!.csv_display_mode
        } is in progress. Try again when the ${
          status!.csv_display_mode
        } is finished.`,
        {
          color: Color.ERROR,
        },
      );
      return;
    }

    $.ajax({
      type: 'POST',
      url: urls.processExport,
      success: function(data) {
        if (data.errors) {
          notify(data.errors.base[0], { color: Color.ERROR });
        } else {
          setStatus(data);
        }
      },
      error: function() {
        setFailedAction('export');
      },
    });
  }

  const options = (
    <span className="dropdown pau-actions right-of-title">
      <button
        className="dropdown-toggle"
        data-toggle="dropdown"
        id="audience-options-dropdown"
      >
        <span className="dropdown-toggle-text">Options</span>
        <span className="dropdown-toggle-icon fa fa-angle-down"></span>
      </button>
      <ul className="dropdown-menu right-align">
        <li>
          <a
            href={csvActionsDisabled() ? '#' : '#modal-import-users'}
            data-toggle="modal"
            onClick={onImportClick}
          >
            Import users
          </a>
        </li>
        <li>
          <a id="export-users" href="#" onClick={onExportClick}>
            Export all users
          </a>
        </li>
      </ul>
    </span>
  );

  return (
    <>
      <div className="pull-right audience-header-right">
        <div className="search-box">
          <PageAccessSearchField pageId={pageId} />
        </div>
        {options}
      </div>

      {
        // Since the above div is floating content, this will clear the floats so that
        // the elements that come next will actually render below.
      }
      <div className="clearfix"></div>

      <AudienceCsvStatusContainer
        status={status}
        setStatus={setStatus}
        failedAction={failedAction}
        setFailedAction={setFailedAction}
        urls={urls}
        trialPage={trialPage}
      />
    </>
  );
}
