import React, { useReducer } from 'react';
import types from 'prop-types';

import IndividualComponents from './IndividualComponents';
import Metrics from './Metrics';
import PageAccessGroups from './Groups';
import Subscribe from './Subscribe';
import visibilityReducer, { ACTION_TYPES } from './visibilityReducer';
/**
 * This is a composition form that displays the ItemSelectors and subscriber checkbox for PageAccessUser creation
 * and update. It controls the logic around enabling and disabling the different ItemSelectors and the subscribe
 * checkbox.
 *
 * @param {object} props
 * @param {string} props.group.items - A collection of pag items to display.  Minimum required field is `name`
 * @param {string} props.group.preselectedItemIds - A collection of preselected group item ids
 * @param {string} props.group.hiddenFieldName
 * @param {string} props.group.hiddenFieldId
 * @param {string} props.component.items - A collection of component items to display.  Minimum required field is `name`
 * @param {string} props.component.preselectedItemIds - A collection of preselected component item ids
 * @param {string} props.component.hiddenFieldName
 * @param {string} props.component.hiddenFieldId
 * @param {string} props.metric.items - A collection of metric items to display.  Minimum required field is `name`
 * @param {string} props.metric.preselectedItemIds - A collection of preselected metric item ids
 * @param {string} props.metric.hiddenFieldName
 * @param {string} props.metric.hiddenFieldId
 * @param {string} props.subscribe.include - will show the subscribe checkbox if true. We only show that checkbox
 *                                           when a new PageAccessUser is being created
 */

const Form = ({ component, group, metric, subscribe }) => {
  const INITIAL_STATE = {
    showIndividualComponents: group.preselectedItemIds.length === 0,
    showSubscribe: subscribe.initialSubscribeShow,
    showMetrics: !group.preselectedItemIds.length,
  };

  const [visibilityState, dispatch] = useReducer(
    visibilityReducer,
    INITIAL_STATE,
  );

  const handleToggleGroup = (items) => {
    const { groupToggled, groupsRemoved } = ACTION_TYPES;
    const selectedGroups = items.filter((item) => item.selected);
    const hasPageAccessGroup = selectedGroups.some(
      (accessGroup) => accessGroup.component_ids.length > 0,
    );

    return dispatch({
      type: selectedGroups.length > 0 ? groupToggled : groupsRemoved,
      payload: {
        hasPageAccessGroup,
      },
    });
  };

  const handleToggleIndividual = (items) => {
    const {
      individualComponentToggled,
      individualComponentsRemoved,
    } = ACTION_TYPES;
    const hasSelectedComponents = items.some((item) => item.selected);

    return dispatch({
      type: hasSelectedComponents
        ? individualComponentToggled
        : individualComponentsRemoved,
    });
  };

  const {
    showIndividualComponents,
    showMetrics,
    showSubscribe,
  } = visibilityState;

  return (
    <div>
      <PageAccessGroups
        data={group}
        hasSeparator={showIndividualComponents || showMetrics}
        isVisible={group.items.length > 0}
        onToggle={handleToggleGroup}
      />
      <IndividualComponents
        data={component}
        isVisible={component.items.length > 0 && showIndividualComponents}
        onToggle={handleToggleIndividual}
      />
      <Metrics
        data={metric}
        hasSeparator={subscribe.include && showSubscribe}
        isVisible={metric.items.length > 0 && showMetrics}
      />
      <Subscribe
        data={subscribe}
        isVisible={subscribe.include && showSubscribe}
      />
    </div>
  );
};

Form.propTypes = {
  component: types.shape({
    hiddenFieldId: types.string,
    hiddenFieldName: types.string,
    items: types.array,
    preselectedItemIds: types.arrayOf(types.string),
  }).isRequired,
  group: types.shape({
    component_ids: types.arrayOf(types.string),
    hiddenFieldName: types.string,
    hiddenFieldId: types.string,
    items: types.array,
    display_name: types.string,
    preselectedItemIds: types.arrayOf(types.string),
  }).isRequired,
  metric: types.shape({
    items: types.array,
    preselectedItemIds: types.arrayOf(types.string),
    hiddenFieldName: types.string,
    hiddenFieldId: types.string,
  }).isRequired,
  subscribe: types.shape({
    initialSubscribeShow: types.bool,
    include: types.bool,
  }).isRequired,
};

export default Form;
