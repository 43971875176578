import React from 'react';
import types from 'prop-types';

import Label from 'components/label';
import ItemSelector from 'components/items_selector/items_selector';

const Metrics = ({
  data: { items, preselectedItemIds, hiddenFieldName, hiddenFieldId },
  hasSeparator,
  isVisible,
}) => (
  <div className={`associated-metrics${isVisible ? '' : ' hidden'}`}>
    <Label text="Associated metrics" className="associated-metrics-label" />
    <ItemSelector
      className={`inline ${hasSeparator ? '' : 'no-separator'}`}
      items={items}
      preselectedItemIds={preselectedItemIds}
      hiddenFieldName={hiddenFieldName}
      hiddenFieldId={hiddenFieldId}
    />
  </div>
);

Metrics.propTypes = {
  data: types.shape({
    items: types.array,
    preselectedItemIds: types.arrayOf(types.string),
    hiddenFieldName: types.string,
    hiddenFieldId: types.string,
  }).isRequired,
  hasSeparator: types.bool.isRequired,
  isVisible: types.bool.isRequired,
};

export default Metrics;
