import * as React from 'react';
import 'url-polyfill';

interface Props {
  action: string;
  method: string;
  className?: string;
  skipPrepend?: boolean;
  id?: string;
  for?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

export default class FormNoMutations extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    method: 'POST',
    onSubmit: () => {},
  };

  form?: HTMLFormElement;

  get action(): string {
    const currentUrl = new URL(window.location.toString());
    if (currentUrl.searchParams.get('isIframe') !== 'true') {
      return this.props.action;
    }

    const newUrl = new URL(this.props.action, window.location.toString());
    newUrl.searchParams.set('isIframe', 'true');
    return newUrl.toString();
  }

  get method(): string {
    if (['put', 'patch'].includes(this.props.method.toLowerCase())) {
      return 'post';
    }
    return this.props.method;
  }

  static get authenticityToken(): string | undefined {
    const tags = Array.from(document.getElementsByTagName('meta'));
    for (const tag of tags) {
      if (tag.name === 'csrf-token') {
        return tag.content;
      }
    }
    return;
  }

  render() {
    return (
      <form
        ref={ref => ref && (this.form = ref)}
        action={this.action}
        method={this.method}
        onSubmit={this.props.onSubmit}
        acceptCharset="UTF-8"
        className={this.props.className}
        id={this.props.id}
      >
        <input type="hidden" name="utf8" value="&#x213;" />
        <input type="hidden" name="_method" value={this.props.method} />
        <input
          type="hidden"
          name="authenticity_token"
          value={FormNoMutations.authenticityToken}
        />
        {this.props.children}
      </form>
    );
  }
}
