import { Component } from 'react';
import PropTypes from 'prop-types';
import Subscriber from 'resources/subscriber';
import { reportError } from 'utils/sentry_reporter';

export default class SubscriberQuery extends Component {
  static propTypes = {
    pageCode: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,

    page: PropTypes.number,
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
    sortField: PropTypes.oneOf(['primary', 'created_at', 'quarantined_at']),
    subscriberType: PropTypes.oneOf([
      'email',
      'sms',
      'slack',
      'webhook',
      'teams',
    ]),
    subscriberState: PropTypes.oneOf(['active', 'unconfirmed', 'quarantined']),
  };

  static defaultProps = {
    page: 0,
    sortDirection: 'asc',
    sortField: 'primary',
    subscriberType: 'email',
    subscriberState: 'active',
  };

  state = {
    subscribers: [],
    request: null,
    error: null,
  };

  componentWillMount() {
    this.mounted = true;
    this._fetchSubscribers(this.props);
  }

  componentWillReceiveProps(props) {
    if (
      props.pageCode !== this.props.pageCode ||
      props.limit !== this.props.limit ||
      props.updatedAt !== this.props.updatedAt ||
      props.page !== this.props.page ||
      props.sortDirection !== this.props.sortDirection ||
      props.sortField !== this.props.sortField ||
      props.subscriberType !== this.props.subscriberType ||
      props.subscriberState !== this.props.subscriberState
    ) {
      this._fetchSubscribers(props);
    }
  }

  componentWillUnmount() {
    // make sure we don't try to call setState on an unmounted component
    this.mounted = false;
  }

  _fetchSubscribers(propsAtFetchTime) {
    // Store the request promise to identify THIS instance of fetching subscribers.
    const request = Subscriber.getPage({
      pageCode: propsAtFetchTime.pageCode,
      page: propsAtFetchTime.page,
      limit: propsAtFetchTime.limit,
      sort_direction: propsAtFetchTime.sortDirection,
      sort_field: propsAtFetchTime.sortField,
      type: propsAtFetchTime.subscriberType,
      state: propsAtFetchTime.subscriberState,
    })
      .then((subscribers) => {
        if (this.mounted) {
          this.setState((prevState) => {
            const newState = {
              subscribers,
              request: null,
              error: null,
            };

            // Only update the state if the request in `state` is the request on which this function closes.
            if (prevState.request === request) {
              return newState;
            }

            // Otherwise update nothing.
            return {};
          });
        }
      })
      .catch((e) => {
        reportError(e, {});
        if (this.mounted) {
          this.setState((prevState) => {
            // Same here: only update state if this request was the last request requested.
            if (prevState.request === request) {
              return {
                error: 'Please try again in a few minutes.',
                request: null,
              };
            }
            return {};
          });
        }
      });

    // Set the error and request here to mark which request was last requested by the user.
    this.setState({
      error: null,
      request,
    });
  }

  render() {
    return this.props.children(
      this.state.subscribers,
      this.state.request !== null,
      this.state.error,
    );
  }
}
