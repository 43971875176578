import React from 'react';
import { ExportStatus } from './types';

import SectionMessageContainer from './section_message_container';
import { AudienceCsvProgressBar } from './audience_csv_progress_bar';

interface AudienceExportStatusContainerProps {
  status: ExportStatus;
  hideCsvStatusUrl: string;
}

export default function AudienceExportStatusContainer({
  status,
  hideCsvStatusUrl,
}: AudienceExportStatusContainerProps) {
  function onDownload() {
    $.ajax({
      method: 'POST',
      url: hideCsvStatusUrl,
    });
  }

  if (status.completed_at) {
    return (
      <SectionMessageContainer
        appearance="success"
        message="Export completed"
        link={status.urls.download_csv}
        link_text="Download CSV"
        onClick={onDownload}
      />
    );
  } else {
    return (
      <AudienceCsvProgressBar
        message="Exporting users..."
        isIndeterminate={false}
        proportionComplete={status.proportion_complete}
      />
    );
  }
}
