import { observable, action, computed } from 'mobx';
import { StatusEmbedProps, ColorSettings } from './types';
import { isValidColor, formatColor } from './utils';

// possible position selection options for embedded widget
export const positions = {
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  topRight: 'topRight',
};

export default class StatusEmbedStore {
  @observable onlineStatus = false;
  @observable positionDraft: string;
  @observable draftColorSettings: ColorSettings;

  statusUrl: string;
  pageCode: string;
  trialPage: boolean;

  // These will get loaded in the constructor, and are equal to the saved values
  // for the page on initialization
  @observable position = positions.bottomLeft;
  @observable savedColorSettings: ColorSettings;

  // Initialize values based on the props that were passed into the root StatusEmbed component
  constructor(props: StatusEmbedProps) {
    this.checkOnlineStatus(props.lastLoadedAt);

    this.statusUrl = props.statusUrl;
    this.pageCode = props.pageCode;
    this.trialPage = props.trialPage;
    this.position = props.position;
    this.positionDraft = props.position || positions.bottomLeft;
    this.draftColorSettings = colorSettingsFromProps(props);
    this.savedColorSettings = colorSettingsFromProps(props);
  }

  // Gets the src for the iframe for the actual embed implementation, providing override parameters
  // of the current draft values for incidents to influence its appearance
  @computed get incidentIframeSrc(): string {
    let background = this.draftColorSettings.incidentBackground;
    if (!isValidColor(background))
      background = this.savedColorSettings.incidentBackground;

    let color = this.draftColorSettings.incidentText;
    if (!isValidColor(color)) color = this.savedColorSettings.incidentText;

    return iframeSrc(background, color, 'realtime', this.statusUrl);
  }

  // Gets the src for the iframe for the actual embed implementation, providing override parameters
  // of the current draft values for maintenance to influence its appearance
  @computed get maintenanceIframeSrc(): string {
    let background = this.draftColorSettings.maintenanceBackground;
    if (!isValidColor(background))
      background = this.savedColorSettings.maintenanceBackground;

    let color = this.draftColorSettings.maintenanceText;
    if (!isValidColor(color)) color = this.savedColorSettings.maintenanceText;

    return iframeSrc(background, color, 'scheduled', this.statusUrl);
  }

  // Determines if all colors are valid, and changes have actually been made to the saved values
  @computed get readyToUpdate(): boolean {
    return this.allColorsValid && this.anyFieldsChanged;
  }

  @computed get allColorsValid() {
    return colorSettingValues(this.draftColorSettings).every(isValidColor);
  }

  @computed get anyFieldsChanged() {
    return (
      this.positionUpdated ||
      colorSettingValues(this.draftColorSettings).toString() !==
        colorSettingValues(this.savedColorSettings).toString()
    );
  }

  @computed get positionUpdated() {
    if (this.position == null) {
      // If we have no position saved previously, the default is bottom left,
      // so unless they select something different a change hasn't been made
      return this.positionDraft !== positions.bottomLeft;
    } else {
      return this.position !== this.positionDraft;
    }
  }

  @action checkOnlineStatus(lastLoadedAt: string) {
    const now: any = new Date();
    const lastLoadedDate: any = new Date(lastLoadedAt);
    if (lastLoadedAt == null || now - lastLoadedDate > 24 * 60 * 60 * 1000) {
      this.onlineStatus = false;
    } else {
      this.onlineStatus = true;
    }
  }

  // Performs an update of the status embed settings for a page.
  // Returns true (successful update) or false (unsuccessful update)
  @action async performUpdate() {
    const data = {
      status_embed_config: {
        position: this.positionDraft,
        incident_background_color: formatColor(
          this.draftColorSettings.incidentBackground,
        ),
        incident_text_color: formatColor(this.draftColorSettings.incidentText),
        maintenance_background_color: formatColor(
          this.draftColorSettings.maintenanceBackground,
        ),
        maintenance_text_color: formatColor(
          this.draftColorSettings.maintenanceText,
        ),
      },
    };

    try {
      const resp = await $.ajax({
        url: window.Routes.update_status_embed_config_page_url(this.pageCode),
        method: 'PATCH',
        dataType: 'json',
        data: data,
      });
      this.position = resp.position;
      this.savedColorSettings = colorSettingsFromConfig(resp);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Copies the script text to the clipboard using a temp element
  // Called from outside and inside the modal
  copyCode() {
    const snippet = document.getElementById('embed-code-snippet') as Element;
    const copyCat = document.createElement('textarea');
    copyCat.value = snippet.textContent as string;
    document.body.appendChild(copyCat);
    copyCat.select();
    document.execCommand('copy');
    document.body.removeChild(copyCat);

    // show a notification that fades after 3s
    const note = document.getElementById('code-copied') as Element;
    note.className = 'visible';
    setTimeout(() => (note.className = 'faded'), 3000);
  }
}

/** UTILITY FUNCTIONS */

// Converts the passed in props into a color settings object
function colorSettingsFromProps(props: StatusEmbedProps): ColorSettings {
  return {
    incidentBackground: formatColor(props.incidentBackground) || '',
    incidentText: formatColor(props.incidentTextColor) || '',
    maintenanceBackground: formatColor(props.maintenanceBackground) || '',
    maintenanceText: formatColor(props.maintenanceTextColor) || '',
  };
}

// Converts the fields from a status embed config object into a color settings object
function colorSettingsFromConfig(config: any): ColorSettings {
  return {
    incidentBackground: config.incident_background_color,
    incidentText: config.incident_text_color,
    maintenanceBackground: config.maintenance_background_color,
    maintenanceText: config.maintenance_text_color,
  };
}

// Creates an array of all color settings values
function colorSettingValues(settings: ColorSettings) {
  return [
    settings.incidentBackground,
    settings.incidentText,
    settings.maintenanceBackground,
    settings.maintenanceText,
  ].map(c => formatColor(c));
}

// Generates the correct src for the embed preview iframe
function iframeSrc(
  background: string,
  color: string,
  type: string,
  baseUrl: string,
) {
  // Strip out the # for sending in url
  background = background.substring(1);
  color = color.substring(1);
  return `${baseUrl}/embed/frame?background=${background}&color=${color}&type=${type}`;
}
