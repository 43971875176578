import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import Spinner from '@atlaskit/spinner';
import componentLoader from 'utils/component_loader';

import ConfirmModal from 'components/confirm';
import ComponentToggler from 'components/component_groups';
import ImportSubscriberForm from 'components/manage/pages/notifications/import_subscriber_form';
import IncidentTemplateForm from 'components/manage/incident_templates/incident_template_form';
import ItemsSelector from 'components/items_selector/items_selector';
import OrganizationPages from 'components/manage/admin/organizations/pages';
import PageAccessUsersForm from 'components/manage/page_access_users/form';
import PageSubscription from 'components/manage/admin/pages/subscription';
import NotificationsSubscriberManager from 'components/manage/notifications/notifications_subscriber_manager';
import ResendConfirmationLink from 'components/resend_confirmation_link';
import SingleSignOnForm from 'components/manage/pages/single_sign_on_form';
import SsoMetrics from 'components/manage/shared/sso_metrics';
import SubscribersSearchField from 'components/manage/subscribers_search_field';
import UnsubscribeButton from 'components/manage/unsubscriber';
import NewSubscription from 'containers/status/subscriptions/new_subscription';
import EditSubscription from 'containers/status/subscriptions/edit_subscription';
import SelectInput from 'components/select_input';
import StatusEmbed from 'containers/status_embed';
import ColorInput from 'components/color_input';
import ActivateAudienceSpecific from 'containers/activate_audience_specific';
import PageAuthentication from 'containers/page_authentication';
import GoogleConfigForm from 'containers/page_authentication/google_config_form';
import SsoConfigForm from 'containers/page_authentication/sso_config_form';
import { AudienceSpecificHeader } from 'containers/audience_index';
import { ClearableDatePicker as DatePicker } from 'components/clearable_date_picker';
import HermesDateTimePicker from 'components/manage/admin/hermes/hermes_date_time_picker';

componentLoader({
  ConfirmModal,
  ComponentToggler,
  ImportSubscriberForm,
  IncidentTemplateForm,
  ItemsSelector,
  OrganizationPages,
  PageAccessUsersForm,
  PageSubscription,
  NotificationsSubscriberManager,
  ResendConfirmationLink,
  SingleSignOnForm,
  SsoMetrics,
  SubscribersSearchField,
  UnsubscribeButton,
  NewSubscription,
  EditSubscription,
  SelectInput,
  StatusEmbed,
  ColorInput,
  Spinner,
  ActivateAudienceSpecific,
  UserAvatarCircleIcon,
  PageAuthentication,
  GoogleConfigForm,
  SsoConfigForm,
  AudienceSpecificHeader,
  DatePicker,
  HermesDateTimePicker,
});
