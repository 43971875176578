import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// One clickable header for a tab group.
export default function TabHeader({
  name,
  activeName,
  onClick,
  className,
  children,
}) {
  return (
    <div
      onClick={onClick}
      className={classnames(
        'tab-header',
        name === activeName ? 'active' : 'inactive',
        className,
      )}
    >
      {children}
    </div>
  );
}

TabHeader.propTypes = {
  activeName: PropTypes.string,
  name: PropTypes.string.isRequired,
  // On click, calls this callback.
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

TabHeader.defaultProps = {
  activeName: null,
  onClick: () => {},
  className: '',
};
