import * as React from 'react';
import UnsubscribeConfirmationModal from './unsubscribe_confirmation_modal';
import Heading from './heading';
import ComponentForm from './component_form';
import { EditSubscriptionProps } from './types';

const EditSubscription: React.FunctionComponent<EditSubscriptionProps> = ({
  page,
  subscriber,
  components,
  selectedComponentIds,
  allowComponentSubscribers,
}) => {
  return (
    <div>
      <Heading subscriber={subscriber} page={page} />

      <ComponentForm
        page={page}
        components={components}
        selectedComponentIds={selectedComponentIds}
        allowComponentSubscribers={allowComponentSubscribers}
        subscriber={subscriber}
        formAction={window.Routes.subscription_path(subscriber.id)}
        formMethod="PATCH"
        allowUnsubscribe={true}
      />

      <UnsubscribeConfirmationModal page={page} subscriber={subscriber} />
    </div>
  );
};

export default EditSubscription;
