import PropTypes from 'prop-types';
import React from 'react';
import TriStateCheckbox from 'components/tri_state_checkbox';
import Spinner from '@atlaskit/spinner';
import classNames from 'classnames';

export class SelectionState {
  constructor(allSelected, items) {
    this.selectedSet = new Set(items);
    this.allSelected = allSelected;
  }

  static clear() {
    return new SelectionState(false, []);
  }

  static selectAll() {
    return new SelectionState(true, []);
  }

  static selectExactly(items) {
    return new SelectionState(false, items);
  }

  isChecked = (item) => this.allSelected || this.selectedSet.has(item);
  isClear = () => !this.allSelected && this.selectedSet.size === 0;
  toJSON = () => (this.allSelected ? 'all' : [...this.selectedSet]);
  getCount = () => (this.allSelected ? 'all' : this.selectedSet.size);
}

/**
 * This component displays a list: headers, content, and a checkbox next to every row. It also
 * implements the logic necessary to select either a subset of the current page, or "all", assuming
 * there are more pages.
 */
export default class CheckedList extends React.Component {
  static propTypes = {
    // Total number of items, including those on this page.
    // Used to figure out when selecting subscriber not on the page is possible, i.e. "select all"
    // functionality.
    total: PropTypes.number.isRequired,

    // The list content.
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.element,
        ]),
      ),
    ]).isRequired,

    // Whether or not to display the content, or whether to show a "loading" icon. TODO: Move out of this component.
    loading: PropTypes.bool,

    // The header row.
    headers: PropTypes.element.isRequired,

    // An error to display instead of the list.
    // Populate this field if you e.g. have a connection error while populating the list children.
    error: PropTypes.element,

    // What to display when the list is empty.
    empty: PropTypes.element,

    // A callback when the selection state changes.
    // The sole argument is a SelectionState instance, indicating the new selection state as a
    // result of user input.
    onSelect: PropTypes.func,
    enabledType: PropTypes.bool,

    selection: PropTypes.instanceOf(SelectionState).isRequired,
    limit: PropTypes.number,
  };

  static defaultProps = {
    loading: false,
    error: null,
    empty: null,
    enabledType: true,
    onSelect: () => {},
    limit: null,
  };

  state = {
    keySet: new Set(),
  };

  componentWillReceiveProps(props) {
    if (props.children !== this.props.children) {
      this.setState({
        keySet: new Set(props.children.map((child) => child.key)),
      });
    }
  }

  // Callback for clicking the batch checkbox.
  _handleBatchCheckbox = () => {
    let selectedKeySet;
    if (
      this.props.selection.selectedSet.size === 0 &&
      !this.props.selection.allSelected
    ) {
      selectedKeySet = new Set([...this.state.keySet]);
    } else {
      selectedKeySet = new Set([]);
    }
    this.props.onSelect(SelectionState.selectExactly(selectedKeySet));
  };

  // Callback for clicking an individual row.
  _handleAnchorClick = (e) => {
    if (e.target.tagName == 'INPUT') {
      // User actually clicked on a checkbox.
      // This is idiosyncratic Firefox behavior.
      // See: https://bugzilla.mozilla.org/show_bug.cgi?id=62151
      e.preventDefault();
    }
  };

  // Callback for clicking an individual row checkbox.
  _handleRowCheckbox = (key) => {
    // The setTimeout is a workaround for the Firefox checkbox workaround,
    // which causes React to not render the checkbox that was just clicked.
    // See: https://github.com/facebook/react/issues/3005
    window.setTimeout(
      function() {
        let selectedKeySet; // The working set of codes identifying selected rows.
        if (this.props.selection.allSelected) {
          // Visually, the "all" state is identical to all the visible rows selected.
          selectedKeySet = new Set([...this.state.keySet]);
        } else {
          selectedKeySet = new Set([...this.props.selection.selectedSet]);
        }

        // Update the row state itself from the computed visual display.
        if (selectedKeySet.has(key)) {
          selectedKeySet.delete(key);
        } else {
          selectedKeySet.add(key);
        }

        this.props.onSelect(SelectionState.selectExactly(selectedKeySet));
      }.bind(this),
      0,
    );
  };

  /**
   * Helper function to determine visual state of batch checkbox:
   * @returns "unchecked" if no subscribers are selected, "indeterminate" if only some of the visible subscribers are
   *            checked, "checked" if either all the displayed subscribers are checked or "all" subscribers are checked,
   *            and "disabled" if the checkbox does not relate to the contents of the list.
   */
  _batchCheckboxVisualState() {
    if (this.state.error || this.props.children.length === 0) {
      return 'disabled';
    }
    if (
      this.props.selection.allSelected ||
      this.props.selection.selectedSet.size === this.props.children.length
    ) {
      return 'checked';
    }
    if (this.props.selection.selectedSet.size > 0) {
      return 'indeterminate';
    }
    return 'unchecked';
  }

  _countSelectionSize = () =>
    this.props.selection.allSelected
      ? Number(this.props.total)
      : this.children.length;

  // Render everything below the header of the list--loading state, error state, empty state, or row display state.
  _renderListContent() {
    const listContentContainerClassName = classNames('list-content', {
      'subscribers-list-content-adg3':
        this.props.total === !undefined || this.props.total > this.props.limit,
    });

    let content;
    if (this.props.error) {
      content = this.props.error;
    } else if (this.props.empty) {
      content = this.props.empty;
    } else {
      const subscriberElements = React.Children.map(
        this.props.children,
        (child) => (
          <a
            className="list-content-row"
            key={child.key}
            href={child.props.href ? child.props.href : ''}
            onClick={(e) => this._handleAnchorClick(e)}
            role="row"
          >
            <label className="checkbox-wrapper" role="gridcell">
              <input
                type="checkbox"
                className="checked-list-checkbox list-content-row-checkbox"
                data-list-item-code={child.key}
                onChange={() => this._handleRowCheckbox(child.key)}
                checked={this.props.selection.isChecked(child.key)}
              />
            </label>
            {child}
          </a>
        ),
      );
      content = (
        <div>
          <div className="table-rows-container">{subscriberElements}</div>
        </div>
      );
    }
    return (
      <div className={listContentContainerClassName} role="rowgroup">
        <div className="spinner">
          {this.props.loading ? <Spinner size="medium" /> : null}
        </div>
        {content}
      </div>
    );
  }

  _renderHeaderContent = () =>
    this.props.empty ? null : (
      <div role="rowgroup">
        <div className="list-header" role="row">
          <label className="checkbox-wrapper" role="columnheader">
            <TriStateCheckbox
              className="checked-list-checkbox list-header-checkbox"
              onChange={this._handleBatchCheckbox}
              mode={this._batchCheckboxVisualState()}
            />
          </label>
          {this.props.headers}
        </div>
      </div>
    );

  render() {
    return (
      <div>
        <div role="table" className="checked-table">
          {this.props.enabledType ? this._renderHeaderContent() : null}
          {this._renderListContent()}
        </div>
      </div>
    );
  }
}
