import React from 'react';
import ReactDOM from 'react-dom';
import Form from 'components/form';

class ImportSubscriberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filepickerId: '', filename: '' };
  }

  handleCancel = () => {
    const notificationsDropdown = document.getElementById(
      'notifications-dropdown',
    );
    if (notificationsDropdown) notificationsDropdown.focus();
  };

  render() {
    return (
      <div
        className="modal hide fade modal-import-subscribers"
        id="modal-import-subscribers"
        style={{ display: 'none' }}
      >
        <Form
          className="modal-conten"
          method="POST"
          action={this.props.import_subscribers_page_url}
          onSubmit={this.onSubmit.bind(this)}
          ref="input_subscribers_form"
        >
          <div className="modal-header">
            <a href="#" data-dismiss="modal" className="close close-adg3-hide">
              ×
            </a>

            <h3>Import subscribers</h3>
          </div>

          <div className="modal-body form-horizontal">
            <p>
              Import many subscribers at once by uploading a <em>.csv</em> file.
              Before you get started, keep the following in mind:
            </p>
            <ul className="styled">
              <li>
                The only allowed file type is a .csv (not a .numbers or .xlsx)
              </li>
              <li>The file must be under 20Mb in size</li>
              <li>
                The upload might take a while; we'll email you when it's done
              </li>
              <li>
                <a href={this.props.download_template_page_url} target="_blank">
                  Download this import template
                </a>{' '}
                to see how to organize your .csv
              </li>
              <li>
                <a href="http://help.statuspage.io/knowledge_base/topics/importing-subscribers-via-csv">
                  Check out this article
                </a>{' '}
                for more details on specifics for each field
              </li>
              <li>
                You can also{' '}
                <a
                  href={this.props.download_components_csv_page_url}
                  target="_blank"
                >
                  download a list of component identifiers
                </a>{' '}
                for your page
              </li>
            </ul>
            {this.error()}
            <div className="file-upload-container">
              <input
                type="file"
                ref="csv_file"
                accept="text/csv"
                id="csv_file"
              />
              <input
                type="hidden"
                name="filepicker_id"
                value={this.state.filepickerId}
              />
              <input
                type="hidden"
                name="filename"
                value={this.state.filename}
              />
            </div>
            {this.progress()}
          </div>

          <div className="modal-footer">
            <a
              href="#"
              id={this.props.id}
              data-dismiss="modal"
              className="close hide close-by-esc"
              onClick={this.handleCancel}
            >
              Cancel
            </a>
            <button
              className="cpt-button style-primary"
              id="btn-import-subscribers"
            >
              Import subscribers
            </button>
          </div>
        </Form>
      </div>
    );
  }

  error() {
    if (!this.state.error) {
      return null;
    }

    return (
      <div
        className="cpt-notification error in-page no-delivery-types-error"
        style={{ marginBottom: '1rem' }}
      >
        {this.state.error}
      </div>
    );
  }

  progress() {
    if (!this.state.progress) {
      return null;
    }
    return (
      <div className="cpt-progress" style={{ marginTop: '1rem' }}>
        <div className="bar" style={{ width: this.state.progress + '%' }}></div>
      </div>
    );
  }

  uploadFileAndSubmit(data, file, self) {
    // Update the progress bar
    this.setState({ progress: 100 });
    // Remove the progress bar shortly after completion
    setTimeout(() => {
      this.setState({ progress: null });
    }, 150);

    const filepickerId = data.data.preSignedUrlData.filename;
    const preSignedUrl = data.data.preSignedUrlData.url;

    $.ajax({
      url: preSignedUrl,
      type: 'PUT',
      contentType: file.type,
      processData: false,
      data: file,
      success() {
        self.setState(
          { filepickerId: filepickerId, filename: filepickerId },
          () => {
            ReactDOM.findDOMNode(self.refs.input_subscribers_form).submit();
          },
        );
      },
      error(xhr, textStatus, errorThrown) {
        HRB.utils.notify(
          'We are unable to upload the file. Please try again.',
          { cssClass: 'error' },
        );
      },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    var fileInput = ReactDOM.findDOMNode(this.refs.csv_file);
    const self = this;

    if (fileInput.files.length == 0) {
      this.setErrorMessage('Please provide a CSV file to import.');
      return;
    }

    var maxSize = 20 * 1024 * 1024;
    // Check the size of the file to make sure it's not too large if the browser supports the check
    if (window.FileReader && fileInput.files) {
      if (fileInput.files[0].size > maxSize) {
        // Add an error message and return
        this.setErrorMessage("You can't upload files larger than 20Mb.");
        return;
      }
    }

    const file = fileInput.files[0];
    const [filename, filetype] = ((i) => [
      file.name.slice(0, i),
      file.name.slice(i + 1),
    ])(file.name.lastIndexOf('.'));

    const preSignedUrlPayload = {
      operationName: 'PreSignedUrlData',
      variables: {
        filename,
        filetype,
      },
      query:
        'query PreSignedUrlData($filename: String!, $filetype: String!) {\n  preSignedUrlData(filename: $filename, filetype: $filetype) {\n    filename\n    url\n    __typename\n  }\n}\n',
    };

    if (file.size && file.size / 1000000 > maxSize) {
      this.setErrorMessage("You can't upload files larger than 20Mb.");
      return;
    }

    $.ajax({
      type: 'POST',
      url: '/graphql',
      data: preSignedUrlPayload,
      dataType: 'json',
      success: (data) => {
        this.uploadFileAndSubmit(data, file, self);
      },
      error() {
        HRB.utils.notify(
          'We are unable to upload the file. Please try again.',
          { cssClass: 'error' },
        );
      },
    });
  }

  setErrorMessage(errorMessage) {
    this.setState({ error: errorMessage });
    // Only show the error message for 4 seconds
    setTimeout(() => {
      this.setState({ error: null });
    }, 4000);
  }
}

export default ImportSubscriberForm;
