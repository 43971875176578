import React from 'react';

import FromToDateTimePicker from '../../../from_to_datetime_picker';
import moment from 'moment-timezone';

class HermesDateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fromDate: props.fromDate, toDate: props.toDate };
  }

  setFromAndToDate = (fromDate, toDate) => {
    this.setState(() => {
      return { fromDate: fromDate.toISOString(), toDate: toDate.toISOString() };
    });
  };

  render() {
    const { fromDate, toDate } = this.state;
    return (
      <div className="content-area">
        <FromToDateTimePicker
          fromDateName="from_date"
          toDateName="to_date"
          fromDisabled={false}
          toDisabled={false}
          fromDate={moment(fromDate).tz('UTC')}
          toDate={moment(toDate).tz('UTC')}
          onDateChange={this.setFromAndToDate}
        />
      </div>
    );
  }
}

export default HermesDateTimePicker;
