// validates string against a regexp to check if it's a valid color hex code
export function isValidColor(s: string) {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(s);
}

// adds # to colors if necessary
export function formatColor(color: string) {
  const c = color.toUpperCase();
  return c[0] != '#' ? `#${c}` : c;
}
