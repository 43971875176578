export function underScore(input: string): string {
  let result = input.replace(/([A-Z])/g, $1 => `_${$1}`.toLowerCase());
  if (result.startsWith('_')) {
    result = result.substr(1);
  }
  return result;
}

export function sentenceCasing(sentence: string): string {
  return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
}
