import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const CARET_TYPE_BY_DIRECTION = {
  none: 'fa-unsorted',
  asc: 'fa-sort-asc',
  desc: 'fa-sort-desc',
};

const ARIA_SORT_DIRECTION = {
  none: 'none',
  asc: 'ascending',
  desc: 'descending',
};

// This is a single column header.
// It is sortable/clickable if you specify a 'field' parameter on the header.
// If not, it's just text.
// If `field` is specified, `direction` will specify the icon to the right of the header.
export class SortableListHeader extends Component {
  componentDidMount() {
    if (this.wrapperDiv && this.props.tooltip) {
      $(this.wrapperDiv).tooltip();
    }
  }

  componentDidUpdate() {
    if (this.wrapperDiv && this.props.tooltip) {
      $(this.wrapperDiv).tooltip();
    }
  }

  render() {
    const sortCaretFaClass = this.props.field
      ? CARET_TYPE_BY_DIRECTION[this.props.direction]
      : null;
    const sortCaret = sortCaretFaClass ? (
      <span
        className={classnames('sort-icon', 'fa', sortCaretFaClass)}
        aria-hidden="true"
      />
    ) : null;
    const headerColumnContent = this.props.field ? (
      <button
        onClick={() => this.props.onClick(this.props.field)}
        aria-roledescription="Column sort button"
      >
        {this.props.children}
        {sortCaret}
      </button>
    ) : (
      <div>
        {this.props.children}
        {sortCaret}
      </div>
    );
    const tooltipProps = this.props.tooltip
      ? {
          'data-toggle': 'tooltip',
          'data-placement': 'bottom',
          'data-js-hook': 'tooltip',
          'data-original-title': this.props.tooltip,
        }
      : {};

    return (
      <div
        ref={(div) => {
          this.wrapperDiv = div;
        }}
        className={classnames(
          'sortable-list-header',
          this.props.className,
          this.props.field ? 'sortable' : 'unsortable',
          this.props.field && this.props.direction !== 'none'
            ? 'sorting'
            : 'unsorted',
        )}
        role="columnheader"
        aria-sort={ARIA_SORT_DIRECTION[this.props.direction]}
        {...tooltipProps}
      >
        {headerColumnContent}
      </div>
    );
  }
}

SortableListHeader.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['none', 'asc', 'desc']),
  field: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ]).isRequired,
  tooltip: PropTypes.string,
};

SortableListHeader.defaultProps = {
  className: '',
  direction: 'none',
  onClick: () => {},
  field: null,
  tooltip: null,
};

const REVERSE_SORT_DIRECTION = {
  asc: 'desc',
  desc: 'asc',
};

export class SortableListHeaders extends Component {
  render() {
    // We want to add on callbacks to propagate the click callback up.
    // It also tells the header in question what its visual state is.
    const children = React.Children.map(this.props.children, (element) => {
      if (
        element &&
        element.type &&
        element.type === SortableListHeader &&
        element.props &&
        element.props.field
      ) {
        if (element.props.field === this.props.field) {
          return React.cloneElement(element, {
            direction: this.props.direction,
            onClick: () =>
              this.props.onClick(
                this.props.field,
                REVERSE_SORT_DIRECTION[this.props.direction],
              ),
          });
        }
        return React.cloneElement(element, {
          direction: 'none',
          onClick: () =>
            this.props.onClick(
              element.props.field,
              SortableListHeaders.defaultProps.direction,
            ),
        });
      }
      return element;
    });
    return (
      <div
        className={classnames('sortable-list-headers', this.props.className)}
      >
        {children}
      </div>
    );
  }
}

SortableListHeaders.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
  ),
  className: PropTypes.string,
  onClick: PropTypes.func, // passed with (sortField, sortDirection)
  field: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['none', 'asc', 'desc']).isRequired,
};

SortableListHeaders.defaultProps = {
  children: [],
  className: '',
  onClick: () => {},
  direction: 'asc',
};
