import React from 'react';
import PropTypes from 'prop-types';
import Color from 'components/color';
import _ from 'lodash';

export class ColorInputADG3 extends React.Component {
  state = {
    isInitialized: false,
  };

  componentDidMount() {
    const { position, opacity, defaultValue, theme, letterCase } = this.props;

    this.$input = $(this.refs.input);
    this.$input.minicolors({
      opacity,
      position,
      theme,
      letterCase,
      change: this.onChange,
    });

    let color = new Color(defaultValue);
    this.$input.minicolors('opacity', color.a);
    this.$input.minicolors('value', color.toHex());

    this.setState({
      isInitialized: true,
    });
  }

  onChange = () => {
    if (this.state.isInitialized) {
      let color = new Color(this.$input.minicolors('rgbObject'));

      if (color) {
        this.props.onChange({
          target: { value: color.toRgba() },
          preventDefault() {},
        });
      }
    }
  };

  componentWillUnmount() {
    this.$input.minicolors('destroy');
  }

  manualEntry = (event) => {
    this.lazyUpdate(event.target.value);
    event.preventDefault();
  };

  lazyUpdate = () => {
    _.debounce(function(value) {
      const color = new Color(value);
      if (color) {
        this.$input.minicolors('opacity', color.a);
        this.$input.minicolors('value', color.toHex());
      }
    }, 600);
  };

  render() {
    const {
      id,
      name,
      defaultValue,
      style,
      label,
      className,
      jsHook,
      description,
    } = this.props;
    const classes = ['control-group color-control'];

    if (className) {
      classes.push(className);
    }

    return (
      <div className={classes.join(' ')} style={style}>
        <label htmlFor={id}>{label}</label>
        <div className="controls full-width">
          <input
            id={id}
            data-js-hook={jsHook}
            ref="input"
            type="text"
            name={name}
            onChange={this.manualEntry}
            defaultValue={defaultValue}
          />
          <div className="help-block">{description}</div>
        </div>
      </div>
    );
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    position: PropTypes.string,
    opacity: PropTypes.bool,
    theme: PropTypes.string,
    letterCase: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    position: 'left',
    opacity: false,
    theme: 'bootstrap',
    letterCase: 'uppercase',
    onChange: () => ({}),
  };
}

export default ColorInputADG3;
