const INITIAL_STATE = {
  showIndividualComponents: false,
  showMetrics: false,
  showSubscribe: false,
};

export const ACTION_TYPES = {
  groupsRemoved: 'groupsRemoved',
  groupToggled: 'groupToggled',
  individualComponentsRemoved: 'individualComponentsRemoved',
  individualComponentToggled: 'individualComponentToggled',
};

const visibilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'groupsRemoved':
      return {
        showIndividualComponents: true,
        showMetrics: true,
        showSubscribe: false,
      };
    case 'groupToggled':
      return {
        showIndividualComponents: false,
        showMetrics: false,
        showSubscribe: action.payload.hasPageAccessGroup,
      };
    case 'individualComponentsRemoved':
      return {
        ...state,
        showSubscribe: false,
      };
    case 'individualComponentToggled':
      return {
        ...state,
        showSubscribe: true,
      };
    default:
      throw new Error(
        `* Invalid action ${action.type} performed on form. Expected one of ${ACTION_TYPES}`,
      );
  }
};

export default visibilityReducer;
