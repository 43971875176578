import React from 'react';
import PropTypes from 'prop-types';

// Displays an animated SVG as a loading icon.
function Loading(props) {
  return (
    <div
      className={props.className}
      style={{ display: props.loading ? 'block' : 'none' }}
    >
      <div className="loading-circle">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="10"
              fill="none"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  className: '',
  loading: false,
};

export default Loading;
