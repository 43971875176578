import * as React from 'react';
import { underScore } from 'utils/string_utils';

interface Props {
  action: string;
  method: string;
  className?: string;
  skipPrepend?: boolean;
  id?: string;
  for?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

type ChangeableElements =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

export default class Form extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    method: 'POST',
    onSubmit: () => {},
  };

  form?: HTMLFormElement;

  get method(): string {
    if (['put', 'patch'].includes(this.props.method.toLowerCase())) {
      return 'post';
    }
    return this.props.method;
  }

  static get authenticityToken(): string | undefined {
    const tags = document.getElementsByTagName('meta');
    for (let i = 0; i < tags.length; i += 1) {
      const tag = tags[i];
      if (tag.name === 'csrf-token') {
        return tag.content;
      }
    }
    return;
  }

  appendFor(
    inputs:
      | NodeListOf<ChangeableElements>
      | HTMLCollectionOf<ChangeableElements>,
  ) {
    const form_for = underScore(this.props.for!);
    Array.from(inputs).forEach(input => {
      if (
        !['utf8', '_method', 'authenticity_token'].includes(input.name) &&
        input.type !== 'submit'
      ) {
        input.name = `${form_for}[${input.name}]`;
      }
    });
  }

  updateInputNames() {
    if (this.props.skipPrepend || !this.props.for || !this.form) {
      return;
    }
    this.appendFor(this.form!.getElementsByTagName('input'));
    this.appendFor(this.form!.getElementsByTagName('select'));
    this.appendFor(this.form!.getElementsByTagName('textarea'));
  }

  componentDidMount() {
    this.updateInputNames();
  }

  render() {
    return (
      <form
        ref={ref => ref && (this.form = ref)}
        action={this.props.action}
        method={this.method}
        onSubmit={this.props.onSubmit}
        acceptCharset="UTF-8"
        className={this.props.className}
        id={this.props.id}
      >
        <input type="hidden" name="utf8" value="&#x213;" />
        <input type="hidden" name="_method" value={this.props.method} />
        <input
          type="hidden"
          name="authenticity_token"
          value={Form.authenticityToken}
        />
        {this.props.children}
      </form>
    );
  }
}
