import moment from 'moment-timezone';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { DatePicker as BaseDatePicker } from '@atlaskit/datetime-picker';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';

type Props = React.ComponentProps<typeof BaseDatePicker> & {
  // This is a pretty crappy property. The intention is provide direct, external
  // control over whether the warning message should be displayed. This pattern
  // is only needed because of how we're mounting this component:
  //
  //   1. Ad-hoc
  //   2. No visibility into application state
  //   3. No way to supply validation callbacks without doing `new Function`
  //      (basically `eval`)
  //
  // Don't emulate this. It's not a good example.
  shouldValidate?: boolean;
  timeZone?: string;
};

const Input = styled('div')`
  position: relative;
`;

const ClearLink = styled('a')`
  position: absolute;
  right: 0;
  top: -1.65em;
  user-select: none;
`;

const WarningMessage = styled('div')`
  display: flex;
  align-items: center;

  font-size: 11px;
  line-height: 16px;

  color: #091e42;

  margin-top: 8px;
`;

const WarningText = styled('div')`
  margin-left: 8px;
`;

const CalendarIconWrapper = styled('div')`
  padding-right: 6px;
`;

// A wrapper around the `@atlaskit/icon/glyph/calendar` that adds a right padding to be consistent with other icons.
export const CalendarIconWithPadding: React.FC = () => {
  return (
    <CalendarIconWrapper>
      <CalendarIcon label="calendar" size="medium" />
    </CalendarIconWrapper>
  );
};

/**
 * A wrapper around the `@atlaskit/datetime-picker` that adds additional value
 * resetting functionality and a warning message.
 *
 * @remarks
 * This should ONLY BE USED with the component editor. There is warning
 * messaging built into the component right now that makes it inherently
 * non-reusuable. Realistically this would be abstracted to some sort of
 * validation API, but as this is an ad-hoc legacy component we didn't spend
 * much time perfecting its interface. All (most == 99%) of new components
 * should be in the SPA-related directories anyway.
 *
 * There is also some strange behavior with passing in `null` vs `''` as the
 * `defaultValue` property. If `null` is provided the calendar defaults to the
 * epoch date when opened. If `''` is provided it defaults to the current date.
 * This irregularity is rectified with a `defaultValue` coercion to an empty
 * string in all cases.
 */
export const ClearableDatePicker: React.FC<Props> = (props) => {
  const {
    name,
    defaultValue,
    onChange,
    testId,
    shouldValidate = true,
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...passthroughProps
  } = props;
  const originalDate = useMemo(() => {
    return defaultValue ? moment.tz(defaultValue, timeZone) : null;
  }, [defaultValue, timeZone]);

  const formattedOriginalDate = useMemo(() => {
    return originalDate ? originalDate.format() : '';
  }, [originalDate]);

  const [value, setValue] = useState(formattedOriginalDate);
  const [displayWarning, setDisplayWarning] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const clearDate = useCallback(
    (e) => {
      e.preventDefault();
      setValue(formattedOriginalDate);
      setDisplayWarning(false);
    },
    [formattedOriginalDate, setDisplayWarning, setValue],
  );

  const wrappedOnChange = useCallback(
    (value) => {
      const now = moment().tz(timeZone);
      const selectedDate = moment.tz(value, timeZone).startOf('day');
      const originalDiffInDays = originalDate
        ? now.diff(originalDate, 'days')
        : Infinity;
      const selectedDiffInDays = now.diff(selectedDate, 'days');
      const formattedSelectedDate = selectedDate.format();

      setValue(formattedSelectedDate);
      setDisplayWarning(
        formattedOriginalDate !== formattedSelectedDate &&
          (originalDiffInDays < 90 || selectedDiffInDays < 90),
      );

      if (onChange) {
        onChange(value);
      }
    },
    [
      onChange,
      formattedOriginalDate,
      originalDate,
      timeZone,
      setDisplayWarning,
      setValue,
    ],
  );

  // This should be safe/consistent with:
  //
  //   1. How we're storing the state variable `value` internally, which is
  //      going to take the format e.g. '2020-08-18T00:00:00-04:00'
  //   2. Our nullability assumptions where we coerce null/undefined values to
  //      an empty string
  const dateOnlyValue = useMemo(() => value.split('T')[0], [value]);

  const clearLink = useMemo(() => {
    const clearLinkTestId =
      testId == null ? testId : `${String(testId)}-clear-link`;

    return (
      <ClearLink onClick={clearDate} data-testId={clearLinkTestId}>
        Clear date
      </ClearLink>
    );
  }, [clearDate, testId]);

  const warningText = useMemo(() => {
    return (
      <WarningMessage>
        <WarningIcon label="" size="medium" />
        <WarningText>
          Changing your start date will change your uptime percentage.
        </WarningText>
      </WarningMessage>
    );
  }, []);

  /**
   * We need to clear the start date picker when the "Display uptime" checkbox
   * is disabled to prevent the user from encountering validation errors when
   * they do not want to showcase a component. That checkbox exists outside of
   * this component in Rails land, so we listen for an event that we know will
   * be fired. This approach is not ideal but it allows us to achieve the
   * desired behavior without specifically tying the component to the form
   * within which it resides.
   */
  useEffect(() => {
    const datePickerNode = datePickerRef.current;
    if (!datePickerNode) return;

    datePickerNode.addEventListener('cleardate', (event) => {
      clearDate(event);
    });

    // Clean up
    return () =>
      datePickerNode.removeEventListener('cleardate', (event) => {
        clearDate(event);
      });
  }, [clearDate, datePickerRef.current]);

  return (
    <div id="date_picker_wrapper" ref={datePickerRef}>
      <Input data-testid={testId}>
        {value !== formattedOriginalDate && clearLink}

        {
          // We're cheating here a bit. This version of the Atlaskit component
          // completely janks out when we started calling `.startOf('day')` and
          // decides to select the day previous to the one actually clicked.
          //
          // So, we're tracking our own named hidden input here that doesn't do
          // any funny formatting business with the actual `value` and `name`.
          // We're correspondingly always passing an empty name to the Atlaskit
          // component and a formatted value that strips off the time.
          //
          // In short, the Atlaskit component will be only using the display
          // date and we track our fully qualified timestamp (complete with TZ
          // offset data) independently to circumvent the unexpected behavior.
        }
        <input type="hidden" name={name} value={value} />

        <BaseDatePicker
          {...passthroughProps}
          name={''}
          value={dateOnlyValue}
          onChange={wrappedOnChange}
          icon={CalendarIconWithPadding}
        />
        {shouldValidate && displayWarning && warningText}
      </Input>
    </div>
  );
};
