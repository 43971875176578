import React from 'react';

import SelectInput from '../../../select_input';

class CouponSelector extends React.Component {
  render() {
    if (!this.props.types[this.props.type].coupon_enabled) return null;

    let noCoupon = [{ name: 'No discount', value: 'nil' }];

    let couponOptions = this.props.coupons.map(coupon => {
      return { name: `${coupon.display} (${coupon.id})`, value: coupon.id };
    });

    let coupons = noCoupon.concat(_.sortBy(couponOptions, 'name'));

    return (
      <SelectInput
        name="billing_subscription[coupon]"
        options={coupons}
        id="coupon_id"
        label="Coupon"
        defaultValue={this.props.coupon ? this.props.coupon.id : 'nil'}
        modal={true}
      />
    );
  }
}

export default CouponSelector;
