import React from 'react';
import { ImportStatus } from './types';
import { MessageWithProgressBar } from './styles';

import SectionMessageContainer from './section_message_container';
import { AudienceCsvProgressBar } from './audience_csv_progress_bar';

interface AudienceImportStatusContainerProps {
  status: ImportStatus;
  downloadTemplateUrl: string;
}

export default function AudienceImportStatusContainer({
  status,
  downloadTemplateUrl,
}: AudienceImportStatusContainerProps) {
  function validationErrorMessage() {
    const errCount = status.validation_error_count;
    const errorOrErrors = errCount > 1 ? 'errors' : 'error';
    return `Unable to import users because of ${errCount} ${errorOrErrors}`;
  }

  function templateErrorMessage() {
    const genericMessage = (
      <>
        Invalid import template, use a valid{' '}
        <a href={downloadTemplateUrl} target="_blank">
          template
        </a>{' '}
        and upload again
      </>
    );

    return status && status.error_message
      ? status.error_message
      : genericMessage;
  }

  const longRunningMessage = (
    <MessageWithProgressBar>
      <SectionMessageContainer
        appearance="information"
        message="This import may take a while. You'll receive an email when it's finished. Return to this page if you want to check on the progress."
      />
    </MessageWithProgressBar>
  );

  switch (status.import_status) {
    case 'template_error':
      return (
        <SectionMessageContainer
          appearance="error"
          message={templateErrorMessage()}
        />
      );
    case 'validation_error':
      return (
        <SectionMessageContainer
          appearance="error"
          message={validationErrorMessage()}
          link={status.urls.download_csv}
          link_text="See error column in CSV"
        />
      );
    case 'import_timeout':
      return (
        <SectionMessageContainer
          appearance="error"
          message="There was an error processing your import. Re-upload your file and try to import again."
        />
      );
    case 'complete':
      return (
        <AudienceCsvProgressBar
          message={`${status.users_imported} user${
            status.users_imported > 1 ? 's' : ''
          } added`}
          isIndeterminate={false}
          proportionComplete={1}
        />
      );
    case 'import_error':
      let errorMessage = 'Unable to import some users.';
      return (
        <>
          <MessageWithProgressBar>
            <SectionMessageContainer
              appearance="error"
              message={errorMessage}
            />
          </MessageWithProgressBar>
          <AudienceCsvProgressBar
            message={`${status.users_imported} user${
              status.users_imported > 1 ? 's' : ''
            } added`}
            isIndeterminate={false}
            proportionComplete={1}
          />
        </>
      );
    case 'importing':
      return (
        <>
          {status.long_running && longRunningMessage}
          <AudienceCsvProgressBar
            message="Importing users..."
            isIndeterminate={false}
            proportionComplete={status.proportion_complete}
          />
        </>
      );
    default:
      return (
        <>
          {status.long_running && longRunningMessage}
          <AudienceCsvProgressBar
            message="Preparing import..."
            isIndeterminate={true}
            proportionComplete={status.proportion_complete}
          />
        </>
      );
  }
}
